import { defaults } from 'underscore';

function runOnDOMIntersection(config = {}) {
  const {
    element,
    selector,
    proximity,
  } = config;
  const el = element instanceof HTMLElement ? element : document.querySelector(selector);

  if (!(el instanceof HTMLElement)) {
    return Promise.reject();
  }

  return new Promise((resolve) => {
    observerPolyfillCheck()
      .then(() => {
        function evaluateIntersection(entries, observerRef) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              observerRef.disconnect();
              resolve(entry.target);
            }
          });
        }
        const observerConfig = {
          root: null,
          rootMargin: `${proximity}px`,
          threshold: 0,
        };
        const observer = new IntersectionObserver(evaluateIntersection, observerConfig);

        observer.observe(el);
      });
  });
}

function runOnDOMIntersectionAndKeepRunning(config, intersectionHandler) {
  return observerPolyfillCheck()
    .then(() => {
      const observer = new IntersectionObserver(intersectionHandler(), config);

      observer.observe(document.querySelector(config.element));
    });
}

// Returning a promise for compatibility with actual usages
// Ideally all usages should be refactored in the future to use the new methods below
function observerPolyfillCheck() {
  return Promise.resolve();
}

const defaultOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};
const instanceMap = new Map();
const observerMap = new Map();
const rootIds = new Map();
let consecutiveRootId = 0;

function getRootId(root) {
  if (!root) return '';
  if (rootIds.has(root)) return rootIds.get(root);
  consecutiveRootId += 1;
  rootIds.set(root, consecutiveRootId);

  return rootIds.get(root);
}

function observe(element, callback, opts) {
  const options = defaults(opts, defaultOptions);
  const { root, rootMargin, threshold } = options;

  if (instanceMap.has(element)) {
    $.error(`Trying to observe ${element} which is already being observed`);
  }

  if (!element) return;

  const observerId = `${getRootId(root)}_${rootMargin}_${threshold}`;
  let observerInstance = observerMap.get(observerId);

  if (!observerInstance) {
    observerInstance = new IntersectionObserver(onChange, options);
    observerMap.set(observerId, observerInstance);
  }

  const instance = {
    callback,
    element,
    inView: false,
    observerId,
    observer: observerInstance,
    thresholds:
      observerInstance.thresholds ||
      (Array.isArray(threshold) ? threshold : [threshold]),
  };

  instanceMap.set(element, instance);

  observerInstance.observe(element);

  return instance;
}

function observeWithCallback(element, callback, opts) {
  const { triggerOnce, ...options } = opts;

  return observe(element, (inView, intersection) => {
    if (inView) {
      callback(inView, intersection);

      if (triggerOnce) {
        unobserve(element);
      }
    }
  }, options);
}

function unobserve(element) {
  if (!element) return;

  const instance = instanceMap.get(element);

  if (instance) {
    const { observerId, observer } = instance;
    const { root } = observer;

    observer.unobserve(element);

    let itemsLeft = false;
    let rootObserved = false;

    if (observerId) {
      instanceMap.forEach((item, key) => {
        if (key !== element) {
          if (item.observerId === observerId) {
            itemsLeft = true;
            rootObserved = true;
          }
          if (item.observer.root === root) {
            rootObserved = true;
          }
        }
      });
    }
    if (!rootObserved && root) rootIds.delete(root);
    if (observer && !itemsLeft) {
      observer.disconnect();
    }

    instanceMap.delete(element);
  }
}

function onChange(changes) {
  changes.forEach((intersection) => {
    const { isIntersecting, intersectionRatio, target } = intersection;
    const instance = instanceMap.get(target);

    if (instance && intersectionRatio >= 0) {
      let inView = instance.thresholds.some(threshold => (instance.inView ?
        intersectionRatio > threshold :
        intersectionRatio >= threshold));

      if (isIntersecting !== undefined) {
        inView = inView && isIntersecting;
      }

      instance.inView = inView;
      instance.callback(inView, intersection);
    }
  });
}

function clear() {
  instanceMap.forEach(({ observer }) => {
    observer.disconnect();
  });

  observerMap.clear();
  instanceMap.clear();
  rootIds.clear();
  consecutiveRootId = 0;
}

export {
  runOnDOMIntersection,
  runOnDOMIntersectionAndKeepRunning,
  observe,
  observeWithCallback,
  unobserve,
  clear,
};
