define(function(require, exports, module) {
  var track = function(queryParams, referer) {
    referer = referer || document.referrer;
    var tracking = (function() {
      var qcollect = function(n) {
        return n = n.split('='), this[n[0].toLowerCase()] = n[1], this;
      };
      var q = queryParams.replace(/(^\?)/, '').split('&')
        .map(qcollect.bind({}))[0];
      var not_empty = function(v) {
        return (v || '').trim() !== '';
      };
      var qfind = function(a) {
        return q[a.toLowerCase()] && a + '=' + q[a.toLowerCase()];
      };
      var collect = function(p) {
        return p.map(qfind).filter(not_empty)
          .join(':');
      };

      if (q.mer) {
        $.cookie('MER', q.mer, { expires: 7, path: '/' });
      }
      if (q.avad) {
        return { channel: 'aff', partner: 'avantlink', campaign: collect(['avad']) };
      }
      if (q.pid || q.sid || q.aid || q.vista || q.aff || q.ganid || q.jftid) {
        return {
          channel: 'aff',
          partner: 'cj',
          campaign: collect([
            'AID',
            'PID',
            'SID',
            'JFTID',
          ]),
        };
      }
      if (q.ircid) {
        return { channel: 'aff', partner: 'ir', campaign: collect(['ircid']) };
      }
      if (/^.*(facebook|twitter|reddit|pinterest|youtube)\.com$/i.test(referer) && !q.cmp_id) {
        return { channel: 'sm', partner: referer, campaign: 'none' };
      }
      if (/^.*(google|yahoo|bing)\.com$/i.test(referer) && !q.cmp_id) {
        return { channel: 'org', partner: referer, campaign: 'none' };
      }
      if (/^PD_.*TM.*$/i.test(q.cmp_id)) {
        return { channel: 'bps', partner: referer, campaign: 'bps=' + q.cmp_id };
      }
      if (/^PD_((?!(TM)).)*$/i.test(q.cmp_id)) {
        return { channel: 'nbps', partner: referer, campaign: 'nbps=' + q.cmp_id };
      }
      if (/^(PLA|GAN)_.*$/i.test(q.cmp_id)) {
        return { channel: 'pla', partner: referer, campaign: 'pla=' + q.cmp_id };
      }
      if (/^SM_.*$/i.test(q.cmp_id)) {
        return { channel: 'sm', partner: referer, campaign: 'sm=' + q.cmp_id };
      }
      if (/^EM_.*$/i.test(q.cmp_id)) {
        return { channel: 'em', partner: referer, campaign: 'em=' + q.cmp_id };
      }
      if (/^(dm_crt|dm_nan|dm_sa|DM_OUT|DM_NANT_|DM_NANI_|DM_DIS).*$/i.test(q.cmp_id)) {
        return { channel: 'dis', partner: referer, campaign: 'dis=' + q.cmp_id };
      }
      if (/^(PM_VL|PM_VM).*$/i.test(q.cmp_id)) {
        return { channel: 'vnd', partner: referer, campaign: 'vnd=' + q.cmp_id };
      }
      if (/^(dm_sm|dm_se|dm_fs|dm_isd).*$/i.test(q.cmp_id)) {
        return { channel: 'dom', partner: referer, campaign: 'dom=' + q.cmp_id };
      }
      if (/^SH_FRO.*$/i.test(q.cmp_id)) {
        return { channel: 'gse', partner: referer, campaign: 'gse=' + q.cmp_id };
      }
      if (/^SH_((?!(FRO)).)*$/i.test(q.cmp_id)) {
        return { channel: 'sch', partner: referer, campaign: 'sch=' + q.cmp_id };
      }
      if (/^APP_.*$/i.test(q.cmp_id)) {
        return { channel: 'app', partner: referer, campaign: 'app=' + q.cmp_id };
      }
      if (/^odat_.*$/i.test(q.cmp_id)) {
        return { channel: 'odat', partner: referer, campaign: 'odat=' + q.cmp_id };
      }
      if (/^(cm_|cat_|alli|nm_).*$/i.test(q.cmp_id)) {
        return { channel: 'oth', partner: referer, campaign: 'oth=' + q.cmp_id };
      }
      if (/^R.*$/i.test(q.mv_pc)) {
        return { channel: 'ref', partner: referer, campaign: q.mv_pc.substring(0, 1).toUpperCase() };
      }

      return { channel: 'dir', partner: document.location.hostname, campaign: 'none' };
    }());

    switch (tracking.channel) {
      case 'dir':
        break;
      case 'ref':
        $.cookie('ref-id', tracking.campaign, { expires: 90, path: '/' });
        $.cookie('primary-ref', tracking.channel, { expires: 90, path: '/' });
        break;
      case 'aff':
        $.cookie('aff-id', tracking.campaign, { expires: 120, path: '/' });
        $.cookie('primary-ref', tracking.channel, { expires: 120, path: '/' });
        break;
      default:
        $.cookie('mkt-ch', tracking.campaign, { expires: 120, path: '/' });
        $.cookie('primary-ref', tracking.channel, { expires: 120, path: '/' });
        break;
    }

    return tracking;
  };

  module.exports = {
    track,
  };
});
