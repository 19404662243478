define(function(require, exports, module) {
  var _ = require('underscore');
  var groupByThousandsRegex = /,([^.]+)\./;

  function translate(key) {
    var args = [].slice.call(arguments, 1);
    var str = (BC.i18n && BC.i18n.translations[key]) || (BC.page && BC.page.messages && BC.page.messages[key]) || '';

    return str.replace(/\{(\d+)\}/g, function(match, i) {
      return args[i] || '';
    });
  }

  function toCurrency(number, options = {}) {
    _.defaults(
      options,
      _.pick(BC.l10n || {}, 'precision', 'unit'),
      { format: '\xA4#,##0.00', precision: 2, unit: '$' },
    );

    number = toNumber(number, options);
    number = options.format.replace(/[^\xA4]+/, number).replace(/\xA4+/, options.unit);

    return number;
  }

  function toNumber(number, options = {}) {
    var isNegative;
    var parts;
    var format;
    var groupingSize;
    var groupingRE;

    _.defaults(
      options,
      _.pick(BC.l10n || {}, 'delimiter', 'separator'),
      {
        delimiter: ',', format: '\xA4#,##0.00', precision: 0, separator: '.',
      },
    );

    isNegative = number < 0;
    parts = Math.abs(number).toFixed(options.precision)
      .split('.');
    format = options.format.split(';');
    format = isNegative ? format[1] || format[0] : format[0];
    groupingSize = groupByThousandsRegex.test(format) ? RegExp.$1.length : 0;
    groupingRE = new RegExp('\\B(?=(\\d{' + groupingSize + '})+(?!\\d))', 'g');
    number = parts[0].replace(groupingRE, options.delimiter);

    if (options.precision > 0) {
      number += options.separator + parts[1];
    }

    if (isNegative) {
      number = '-' + number;
    }

    return number;
  }

  function toPercentage(number, options = {}) {
    _.defaults(
      options,
      { delimiter: '', format: '#,##0.0%' },
    );

    number = toNumber(number, options);
    number = options.format.replace(/[^%]+/, number);

    return number;
  }

  function toKilograms(grams, options = {}) {
    _.defaults(
      options,
      { delimiter: ',', format: '#,##kg' },
    );

    const kilograms = toNumber(grams / 1000, options);

    return options.format.replace(/[^kg]+/, kilograms);
  }

  function toPounds(grams, options = {}) {
    _.defaults(
      options,
      { delimiter: ',', format: '#,##lbs' },
    );

    const pounds = toNumber(grams * 0.0022, options);

    return options.format.replace(/[^lbs]+/, pounds);
  }

  module.exports = {
    toCurrency,
    toKilograms,
    toNumber,
    toPercentage,
    toPounds,
    translate,
  };
});
