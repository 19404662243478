import ProactiveChat from 'common/components/proactive-chat/proactive-chat';
import ReactUtils from 'common/components/utils';

function mountProactiveChatMobileComponent() {
  if (has('persist_chat')) {
    const proactiveChatElementMobile = document.querySelector(
      '.js-proactive-chat-wrap-mobile'
    );

    ReactUtils.renderIntoElement(ProactiveChat, proactiveChatElementMobile, {});
  }
}

function mountProactiveChatDesktopComponent() {
  if (has('persist_chat')) {
    const proactiveChatElement = document.querySelector(
      'div.js-proactive-chat-wrap:not(.hidden)'
    );

    ReactUtils.renderIntoElement(ProactiveChat, proactiveChatElement, {});
  }
}

function unmountProactiveChatMobileComponent() {
  if (has('persist_chat')) {
    const proactiveChatElementMobile = document.querySelector(
      '.js-proactive-chat-wrap-mobile'
    );

    ReactUtils.unmountElementsAt($(document), proactiveChatElementMobile);
  }
}

function unmountProactiveChatDesktopComponent() {
  if (has('persist_chat')) {
    const proactiveChatElement = document.querySelector(
      '.js-proactive-chat-wrap'
    );

    ReactUtils.unmountElementsAt($(document), proactiveChatElement);
  }
}

export {
  mountProactiveChatMobileComponent,
  mountProactiveChatDesktopComponent,
  unmountProactiveChatMobileComponent,
  unmountProactiveChatDesktopComponent,
};
