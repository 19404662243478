define(function(require) {
  require('underscore');

  // Similar to `_.defaults` but iterates over the complete hierarchy chain
  // constructorDefaults(self, 'events');
  return function constructorDefaults(obj, attr) {
    var chain = [];

    while (obj) {
      chain.push(obj[attr]);
      obj = obj.constructor && obj.constructor.__super__;
    }

    _.defaults.apply(this, chain);
  };
});
