define(function(require, exports, module) {
  var selector = '.js-session';

  function doRequest() {
    return $.ajax({
      url: '/Store/services/session',
      dataType: 'json',
      method: 'POST',
    }).fail(function(error) {
      $.error('Failed, status=' + error.status + ' ' + error.statusText, 'session-helper.js');
    });
  }

  function getSession() {
    if (has('sessionconfirmation')) {
      return doRequest().then(function(data) {
        return data.session;
      });
    } else {
      return $.Deferred().resolve();
    }
  }

  function setSession() {
    var element = $(selector);

    if (has('sessionconfirmation') && _.isEmpty(element.val())) {
      return doRequest().then(function(data) {
        element.val(data.session);
      });
    } else {
      return $.Deferred().resolve();
    }
  }

  module.exports = {
    getSession,
    setSession,
  };
});
