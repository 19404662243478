import { monthsArray } from './loyalty-constants';
import { translate } from 'common/localization';

const getBirthDate = () => {
  const month = $('.js-loyalty-signup-birthday-month').val();
  const monthFormatted = month.length === 1 ? `0${month}` : month;
  const day = $('.js-loyalty-signup-birthday-day').val();
  const dayFormatted = day.length === 1 ? `0${day}` : day;

  return month && day ? `${monthFormatted}-${dayFormatted}` : undefined;
};

const setLoyaltyEvents = () => {
  const $loyaltySignUp = $('.js-loyalty-signup');

  if ($loyaltySignUp.length) {
    $loyaltySignUp.change(() => $('.js-loyalty-signup-content').slideToggle());

    $('.js-loyalty-signup-birthday-month').change(({ currentTarget: { value } }) => {
      const { dates } = monthsArray[Number(value) - 1];
      const daySelect = $('.js-loyalty-signup-birthday-day');

      if (daySelect.children().length !== dates.length + 1) {
        const prevValue = daySelect.val();

        daySelect.empty();
        daySelect.append(`<option value="">${translate('loyalty.signup.day')}</option>`);
        dates.forEach((date) => {
          daySelect.append(`<option value=${date}>${date}</option>`);
        });
        daySelect.val(prevValue);
      }
    });
  }
};

const employeeEmailRegex = /^\w+([-+.']\w+)*@?(backcountry.com|competitivecyclist.com|motosport.com|steepandcheap.com)$/;

const isEmployeeEmail = value => employeeEmailRegex.test(value);

// eslint-disable-next-line import/prefer-default-export
export { isEmployeeEmail, getBirthDate, setLoyaltyEvents };
