import 'jquery-ui/widget';
import { isEmpty } from 'underscore';
import { login } from 'common/util/forms/atgAjaxHelper';
import {
  isValidEmail,
  cleanupFormErrors,
  disableForm,
  populateRecaptchaResponse,
  enableForm,
  handleFormResponse,
  updateUserInformation,
  initRecaptcha,
  showInputError,
} from 'common/util/auth-modal-helper';
import { getOmsUserData } from 'common/services/oms-service';

var { isValid } = require('common/util/bc.validation.helper');

$.widget('bc.authModalLogin', {
  options: {
    authWrap: '#authentication',
    userLoginContainer: '.js-auth-login',
    guestLoginContainer: '.js-guest-login',
  },
  _create() {
    this.forceRecaptcha = has('forcerecaptchaonlogin');
    this.authSuccessCallback = () => document.location.reload();
    this.recaptchaResponse = null;
    this.recaptchaId = undefined;
    this.$form = this.element.find('form');
    this.$authWrap = $(this.options.authWrap);
    this.$userLoginContainer = this.$authWrap.find(this.options.userLoginContainer);
    this.$guestLoginContainer = this.$authWrap.find(this.options.guestLoginContainer);
    this._bindEvents();
  },
  setAuthSuccessCallback(callback = null) {
    if (callback) {
      this.authSuccessCallback = callback;
    }
  },
  _bindEvents() {
    // used by fb-login
    BC.subscribe('show.loginModal.message', this._handleGeneralError, this);

    this._on(this.element, {
      'click .js-login-modal-general-error-close': () => {
        this._hideGeneralError();
        BC.publish('bc.authmodal.refocus', '.js-login-user-input');
      },
    });

    this._on(this.element, {
      'blur .js-login-user-input': '_validateGuestUser',
    });

    this._on(this.element, {
      'click .auth-modal-guest-close-btn': (event) => {
        event.preventDefault();
        this._closeGuestActivity();
      },
    });

    this._on(this.$form, {
      submit: '_handleSubmit',
    });
  },

  _validateGuestUser(event) {
    const emailValue = event.target.value;
    const isVisible = $(event.target).is(':visible');

    if (has('userguesttype') && has('validate_userguest_status') && isVisible) {
      const validEmail = isValidEmail(emailValue);

      if (validEmail) {
        getOmsUserData(emailValue).then((isGuest) => {
          if (isGuest) {
            this._openGuestActivity();
          }
        });
        cleanupFormErrors(this.$form);
      }
    }
  },
  _closeGuestActivity() {
    this.$guestLoginContainer.hide();
    this.$userLoginContainer.show();
  },
  _openGuestActivity() {
    this.$userLoginContainer.hide();
    this.$guestLoginContainer.show();
  },

  _hideGeneralError() {
    $('.js-login-modal-general-error').hide();
  },
  _resetRecaptchaValue() {
    this.recaptchaResponse = '';
  },
  _handleSubmit(event) {
    const $emailInput = this.$form.find('.js-login-user-input');
    const $passInput = this.$form.find('.js-login-pass-input');

    event.preventDefault();
    this._hideGeneralError();
    cleanupFormErrors(this.$form);
    const isClientSideValidForm = this._validateForm($emailInput, $passInput);

    if (isClientSideValidForm) {
      const formInfo = login(
        $emailInput.val(),
        $passInput.val(),
        this.$form.find('.js-login-keep-checkbox').prop('checked') === true,
      );

      BC.publish('ScSignIn.sitecatalystutil', 'E');
      this._toggleSubmitBtnLoading();

      cleanupFormErrors(this.$form);
      disableForm(this.$form);
      populateRecaptchaResponse(formInfo, this.recaptchaResponse);
      handleFormResponse(
        formInfo,
        this._handleFormSuccess.bind(this),
        this._handleFormErrors.bind(this),
        () => enableForm(this.$form),
      );
    }
  },
  _validateForm($emailInput, $passInput) {
    let isFormValid = true;
    const validEmail = isValidEmail($emailInput.val());
    const validPass = isValid({ test: 'required' }, $passInput.val());

    if (!validEmail) {
      showInputError($emailInput);
      isFormValid = false;
    }

    if (!validPass) {
      showInputError($passInput);
      isFormValid = false;
    }

    BC.publish('bc.authmodal.refocus', '[aria-invalid=true]');

    return isFormValid;
  },
  _toggleSubmitBtnLoading() {
    this.element.find('.js-signing-in-msg').toggleClass('hidden');
  },
  _handleFormSuccess(response) {
    this._toggleSubmitBtnLoading();
    updateUserInformation();

    BC.publish('bc.authmodal.close');
    BC.publish('account:just-loggedIn');
    // This is used by the checkout iframe
    BC.pub('login-modal.close', response.success);
    $(document).trigger('userAuthenticated');
    // defaults to reloading page
    this.authSuccessCallback();
  },
  _handleFormErrors(response) {
    this._toggleSubmitBtnLoading();
    BC.publish('ScLoginModalUserError.sitecatalystutil');
    initRecaptcha({
      selector: '#recaptcha-modal-login-wrap',
      forced: this.forceRecaptcha,
      $form: this.$form,
      recaptchaId: this.recaptchaId,
      recaptchaResponse: this.recaptchaResponse,
      recaptchaIdCallback: recaptchaId => this.recaptchaId = recaptchaId,
      recaptchaCallback: response => this.recaptchaResponse = response,
      response,
    });

    this._resetRecaptchaValue();

    this.$form.attr('aria-invalid', true);

    if (response.formExceptions) {
      response.formExceptions.forEach((error) => {
        this._handleGeneralError(error.message);
      });
    }
  },
  _handleGeneralError(customMessage) {
    if (!isEmpty(customMessage)) {
      $('.js-login-modal-general-error-text').text(customMessage);
      $('.js-login-modal-general-error').show();
      this.$form.attr('aria-describedby', 'login-modal-general-error');
      BC.publish('bc.authmodal.refocus', '.js-login-modal-general-error-close');
    }
  },
  _destroy() {
    BC.unsubscribe('show.loginModal.message', this._handleGeneralError);
  },
});
