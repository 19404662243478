import { isUndefined, isNull, isArray } from 'underscore';
import { init, reset } from 'common/includes/recaptcha';
import { isValid } from 'common/util/bc.validation.helper';
import { translate } from 'common/localization';

function togglePasswordInput(event) {
  // toggle btn and input should be wrapped in a single div
  const $toggle = $(event.target);
  const $toggleInput = $toggle.parent().find('input');
  const $toggleSpan = $toggle.find('.js-password-show-hide-text');

  if ($toggleInput.attr('type') === 'password') {
    $toggleInput.attr('type', 'text');
    $toggleInput.removeClass('auth-modal-pass-hidden');
    $toggle.removeClass('ui-auth-modal-pass-toggle--hidden');
    $toggle.addClass('ui-auth-modal-pass-toggle--visible');
    $toggleSpan.text(translate('label.hide.password'));
  } else {
    $toggleInput.attr('type', 'password');
    $toggleInput.addClass('auth-modal-pass-hidden');
    $toggle.removeClass('ui-auth-modal-pass-toggle--visible');
    $toggle.addClass('ui-auth-modal-pass-toggle--hidden');
    $toggleSpan.text(translate('label.show.password'));
  }
}

function isValidEmail(value) {
  return isValid({ test: 'required' }, value) && isValid({ test: 'email' }, value);
}

function isValidPassword(value) {
  const minLen = 8;

  return value.length >= minLen && isValid({ test: 'required' }, value);
}

function isValidPasswordConfirmation(original, retype) {
  return isValidPassword(original) && isValidPassword(retype) && (original === retype);
}

function cleanupFormErrors($form) {
  $form.attr('aria-invalid', false);
  $form.find('.js-auth-modal-error').hide();
  $form.find('.js-auth-modal-input-wrap').removeClass('has-invalid-fields');
  $form.find('[aria-invalid]').removeClass('is-invalid');
  $form.find('[aria-invalid]').attr({
    'aria-invalid': false,
    'aria-describedby': '',
  });
}

function showInputError($input, customMessage) {
  const $inputParent = $input.closest('.js-auth-modal-input-wrap');
  const $errorMessage = $inputParent.find('.js-auth-modal-error');

  const errorId = $errorMessage.attr('id');

  if (customMessage) {
    $errorMessage.text(customMessage);
  }

  $input.attr({
    'aria-invalid': true,
    'aria-describedby': errorId,
  });

  $inputParent.addClass('has-invalid-fields');
  $input.addClass('is-invalid');
  $errorMessage.show();
}

function disableForm($form) {
  $form.attr('aria-disabled', true);
  $form.find('input:submit').attr({
    disabled: true,
    'aria-disabled': true,
  });
}

function enableForm($form) {
  $form.attr('aria-disabled', false);
  $form.find('input:submit').attr({
    disabled: false,
    'aria-disabled': false,
  });
}

function populateRecaptchaResponse(formInfo, recaptchaResponse) {
  if (formInfo && isArray(formInfo.data)) {
    formInfo.data.push({
      name: 'g-recaptcha-response',
      value: recaptchaResponse,
    });
  }
}

function handleFormResponse(formInfo, successCallback, errorCallback, alwaysCallback) {
  $.post(formInfo.url, formInfo.data)
    .done((response) => {
      if (response.success) {
        successCallback(response);
      } else {
        errorCallback(response);
      }
    })
    .fail((response) => {
      errorCallback(response);
    })
    .always(alwaysCallback());
}

function updateUserInformation() {
  const updatedUserProfile = JSON.parse($.cookie('BC_USR'));

  // Set user object, to allow user to perform logged-in tasks without page refresh
  BC.user.isExplicitOrAutoLoggedIn = updatedUserProfile ? updatedUserProfile.explicitOrAutoLoggedIn : false;
  BC.user.isLoggedIn = updatedUserProfile ? updatedUserProfile.loggedIn : false;
  BC.user.isRegistered = updatedUserProfile ? updatedUserProfile.registered : false;

  // Set profile information of user logging in, so that it is available without page refresh
  BC.profile.id = updatedUserProfile ? updatedUserProfile.id : 0;
  BC.profile.displayName = updatedUserProfile ? updatedUserProfile.displayName : '';
  BC.profile.imagePath = updatedUserProfile ? updatedUserProfile.imagePath : '/images/common/community/profile-default-big.png';
}

function initRecaptcha(options) {
  // TODO mcambronero: change this to invisible recaptcha
  const {
    $form,
    forced,
    selector,
    response,
    recaptchaId,
    recaptchaCallback,
    recaptchaIdCallback,
  } = options;
  const recaptchaWrap = $form.find(selector)[0];
  const shouldShow = forced || (response && response.shouldDisplayCaptcha);

  if (!shouldShow || isUndefined(recaptchaWrap)) {
    return;
  }

  if (isUndefined(recaptchaId) || isNull(recaptchaId)) {
    init(recaptchaWrap, {
      recaptchaCallback,
    }).then(recaptchaIdCallback);
  } else {
    reset(recaptchaId);
  }
}

function initAuthenticationModal() {
  $('#authentication').authenticationModal();

  // Used for backwards compatibility only. Do a publish of 'bc.authmodal.open' instead of using 'js-login-modal' class.
  $(document).on('click', '.js-login-modal', (event) => {
    BC.publish('ScSignInClicked.sitecatalystutil');

    const $elem = $(event.currentTarget);
    const forceShowModal = $elem.data('validate');
    const redirectUrl = $elem.data('redirectUrl');
    const summaryModal = $elem.data('summaryModal');

    if (summaryModal && !forceShowModal && BC.user.isExplicitOrAutoLoggedIn) {
      BC.publish('bc.account-summary-modal.open');
    }

    const navigateAction = () => {
      if (!forceShowModal && (BC.site.code !== 'steepcheap' || (BC.site.code === 'steepcheap' && has('medium')))) {
        localStorage.setItem('shouldOpenAccountSummaryOnLoad', 'true');
      }

      // Should never take the customer out of the cart page, however, use the redirect URL if present
      if (BC.page.id !== 'cart' && redirectUrl) {
        window.location.assign(redirectUrl);
      } else {
        window.location.reload();
      }
    };

    BC.publish('bc.authmodal.open', {
      authSuccessCallback: navigateAction,
      force: forceShowModal,
      showModalCallback: () => {
        event.preventDefault();
      },
    });
  });
}

function resetForm($form) {
  $form.trigger('reset');
}

export {
  togglePasswordInput,
  isValidEmail,
  isValidPassword,
  isValidPasswordConfirmation,
  showInputError,
  cleanupFormErrors,
  disableForm,
  enableForm,
  handleFormResponse,
  updateUserInformation,
  initRecaptcha,
  populateRecaptchaResponse,
  initAuthenticationModal,
  resetForm,
};
