import {
  getEventData,
  setPromotionEvents,
  sendPromoEvent,
  sendEvent,
} from 'common/util/gtm-helper';
import { globalEvents as commonGlobalEvents } from 'common/util/gtm-events';

const trackChatImpression = () => sendEvent({
  ...getEventData('customEvent', 'chat', 'pop-up window impression'),
});
const trackChatStart = method => method && sendEvent({
  ...getEventData('customEvent', 'chat', 'initiate chat', `${window.location.pathname}|${method}`),
});
const trackChatDismissed = () => sendEvent({
  ...getEventData('customEvent', 'chat', 'dismiss chat window'),
});
const trackPersistentChatStart = () => sendEvent({
  ...getEventData('customEvent', 'chat', 'initiate chat', `${window.location.pathname}|persistent-chat`),
});
const trackProactiveChatStart = () => sendEvent({
  ...getEventData('customEvent', 'chat', 'initiate chat', `${window.location.pathname}|popup`),
});
const trackJacadaStart = (event) => {
  const labels = {
    liveChatFooter: 'footer nav',
    liveChatHeader: 'top nav',
    liveChatCheckout: 'checkout',
  };
  const { tracking = '' } = (event && event.detail) || {};
  const label = labels[tracking];

  trackChatStart(label);
};
const onPopUpClicked = () => trackChatStart('pop-up');

const trackLivePersonEvents = () => {
  const liveChatLinks = [
    ['#lpBCSAccountAlt', () => trackChatStart('account')],
    ['#lpBCSHeaderAlt', () => trackChatStart('top nav')],
    ['#lpBCSFooterAlt', () => trackChatStart('footer nav')],
    ['#lpBCSMobile', () => trackChatStart('mobile footer nav')],
    ['#lpBCSMobileHeader', () => trackChatStart('mobile top nav')],
  ];
  const addLiveChatTracker = (id, onClick) => {
    const headerLink = document.querySelector(id);

    if (headerLink) {
      headerLink.removeEventListener('click', onClick);
      headerLink.addEventListener('click', onClick);
    }
  };

  liveChatLinks.forEach(([id, onClick]) => addLiveChatTracker(id, onClick));

  BC.subscribe('gtm.track.live.chat.impression', () => {
    addLiveChatTracker('#lpInviteReject', onPopUpClicked);
    trackChatImpression();
  });

  BC.subscribe('gtm.track.live.chat.dismissed', () => trackChatDismissed());
};

const trackJacadaEvents = () => {
  document.addEventListener('bc_chat:track:live_chat', trackJacadaStart);
  document.addEventListener('bc_chat:track:proactive_offered', trackChatImpression);
  document.addEventListener('bc_chat:track:proactive_declined', trackChatDismissed);
  document.addEventListener('bc_chat:track:proactive_accepted', onPopUpClicked);

  BC.subscribe('gtm.track.persisted.chat.click', () => trackPersistentChatStart());
  BC.subscribe('gtm.track.proactive.chat.click', () => trackProactiveChatStart());
};

const purgeJacadaEvents = () => {
  document.removeEventListener('bc_chat:track:live_chat', trackJacadaStart);
  document.removeEventListener('bc_chat:track:proactive_offered', trackChatImpression);
  document.removeEventListener('bc_chat:track:proactive_declined', trackChatDismissed);
  document.removeEventListener('bc_chat:track:proactive_accepted', onPopUpClicked);
};

function globalEvents() {
  commonGlobalEvents();

  // Internal Promo Tracking
  setPromotionEvents('INT_ID', 'LOCATION_ID');

  BC.subscribe('gtm.track.promo.impression', (promoId, position, creative, name) => {
    const eventData = 'bucket_view_promotion_list';
    const promoData = {
      ...(name && { promotion_name: name }),
      ...(promoId && { promotion_id: promoId }),
      ...(creative && { creative_name: creative }),
      ...(position && { creative_slot: `${position}` }),
    };

    sendPromoEvent(eventData, [promoData]);
  });

  if (has('livepersonsolicit')) {
    trackLivePersonEvents();
  }

  if (has('jacadalivechat')) {
    trackJacadaEvents();
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  globalEvents,
  purgeJacadaEvents,
};
