import React from 'react';
import PropTypes from 'prop-types';

import 'commonStyles/components/_persistent-chat.scss';

function PersistentButton({ overlaidInfo, onClick }) {
  const buttonStyle = {
    offcanvas: { zIndex: BC.site.code === 'bcs' ? -1 : 1 },
    'ntf-mobile': { bottom: '84px' },
  };

  const getButtonStyle = () => {
    if (overlaidInfo.isOverlaid) {
      return buttonStyle[overlaidInfo.element];
    }
  };

  return (
    <button
      style={getButtonStyle()}
      className="persistentChat"
      onClick={onClick}
      data-id="persistentChatButton"
    >
      <img src="/images/common/icons/persist_chat_icon.svg" alt="Chat icon" />
    </button>
  );
}

PersistentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PersistentButton;
