define(function(require) {
  var enquire = require('enquire');
  var affiliates = require('common/includes/affiliates');
  var { default: breakpoints } = require('common/config/breakpoints');
  var urlUtils = require('common/util/bc.url.utils');
  var ATGFormHandler = require('common/atg-form-handler');
  var featureHelper = require('common/util/feature-helper');
  var { default: fixObjectFit } = require('common/util/object-fit-images');
  var { runOnDOMIntersection } = require('common/util/intersection-observer-helper');
  var { hasReducedMotion } = require('common/util/accessibility-helper');
  const { setDataLayer } = require('common/util/gtm-events');

  require('common/shims/base64');
  require('common/util/translations-handler');
  require('common/util/formatting-handler');
  require('common/widgets/lazyloader/bc.lazyloader');
  require('backbone');
  require('vendors/has');
  require('vendors/jquery.cookie');
  // TODO not needed anymore? https://caniuse.com/#feat=json
  require('vendors/stringify');
  require('underscore');
  // TODO Move to local usages to avoid relying on global
  require('jquery-ui/widget');

  const doc = document;
  const win = window;
  const $document = $(doc);
  const $html = $('html');

  const dispatchCustomEvent = (source) => {
    document.dispatchEvent(new CustomEvent('bc_chat::open', { detail: { tracking: source } }));
  };

  const cleanLoad = urlUtils.inUrl('cleanLoad');
  const isApp = window.ReactNativeWebView || window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;

  has.add('cleanload', cleanLoad === 'true');
  has.add('is-native-app', isApp);

  if (has('cleanload')) {
    const style = $('<style>.criteo_header { display: none !important; }</style>');

    $('html > head').append(style);
  }

  if (has('is-native-app')) {
    $html.addClass('is-native-app');
  }

  // Check for support for focus-whithin css selector
  try {
    document.querySelector(':focus-within');
    $html.addClass('focuswhithin');
  } catch (e) {
    $html.addClass('no-focuswhithin');
  }

  window.addEventListener('load', () => {
    import(
      /* webpackChunkName: "remote.dialog" */
      'common/widgets/bc.dialogremote'
    ).then(() => {
      $('.popup-link').remoteDialog();
      BC.publish('remoteDialog.init');
    });

    import(
      /* webpackChunkName: "remote.modal.a11y" */
      'common/widgets/modal/bc.modal'
    ).then(() => {
      $('.js-remote-modal').a11ymodal({
        isRemote: true,
        useDefaultStyles: true,
      });

      BC.subscribe('page.remotemodal.reload', () => {
        $('.js-remote-modal').a11ymodal({
          isRemote: true,
          useDefaultStyles: true,
        });
      });
    });

    $('.js-jacada-chat').on('click', (event) => {
      const source = event.currentTarget.getAttribute('data-tracking');
      const persistChatHidePublish = 'persist.chat.hide';

      if (has('persist_chat')) {
        BC.publish(persistChatHidePublish);
      }

      dispatchCustomEvent(source);
    });

    $('.js-gladly-chat').on('click', () => {
      window.Gladly.show();
    });
  });

  $html.removeClass('no-js');

  featureHelper.initializeFeatures($html);

  if (has('gtm')) {
    setDataLayer();
  }

  $document.on('click', '.js-remove-errors', function() {
    $(this).closest('.js-alert-box').slideUp();
  });

  has.add('dev', $.cookie('dev') === 'on');
  if (has('dev')) {
    requirejs.config({
      paths: {
        react: 'vendors/react-with-addons',
      },
    });
  }

  // Detect touch based on the Mordernizr tests
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
  has.add('touch', function() {
    return ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
  });

  has.add('tablet', function() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    return has('touch') && w > 600;
  });

  // ToDo @jdleiva remove has('sessionstorage')
  // because is widely supported.
  // SessionStorage Support
  has.add('sessionstorage', function() {
    try {
      sessionStorage.BCStest = 'test';
      sessionStorage.removeItem('BCStest');

      return true;
    } catch (err) {
      return false;
    }
  });

  // Sync the javascript with the CSS media-queries.
  if (has('touch')) {
    $html.addClass('has-touch');

    enquire
      .register('(max-width: 40em)', {
        match() {
          has.add('mobile', true);
        },
        unmatch() {
          has.add('mobile', false);
        },
      })
      .register('(min-width: 40em) and (max-width: 64em)', {
        match() {
          has.add('tablet', true);
        },
        unmatch() {
          has.add('tablet', false);
        },
      })
      .register('(orientation: portrait)', {
        match() {
          has.add('portrait', true);
        },
        unmatch() {
          has.add('portrait', false);
        },
      })
      .register('(orientation: landscape)', {
        match() {
          has.add('landscape', true);
        },
        unmatch() {
          has.add('landscape', false);
        },
      });
  }

  // FetchAPI polyfill
  if (!('fetch' in window)) {
    import(
      /* webpackChunkName: "polyfill.whatwg-fetch" */
      'whatwg-fetch'
    ).then(() => {
      performance.mark('fetch polyfill ready');
      BC.publish('window.fetch.ready');
    });
  }

  // Picture polyfill
  if (!('HTMLPictureElement' in window)) {
    import(
      /* webpackChunkName: "polyfill.picturefill" */
      'picturefill'
    );
  }

  // Smooth Scrolling polyfill
  if (!('scrollBehavior' in document.documentElement.style) && !hasReducedMotion()) {
    import(
      /* webpackChunkName: "polyfill.smoothscroll" */
      'smoothscroll-polyfill'
    ).then(smoothscroll => smoothscroll.polyfill());
  }

  // Intersection Observer polyfill
  if (!('IntersectionObserver' in window)) {
    import(
      /* webpackChunkName: "intersection-observer-polyfill" */
      'intersection-observer'
    );
  }

  _.each(breakpoints, function(query) {
    enquire.register(query.breakpoint, {
      match() {
        has.add(query.size, true);
        $.publish('breakpoint.' + query.size + '.match');
      },
      unmatch() {
        has.add(query.size, false);
        $.publish('breakpoint.' + query.size + '.unmatch');
      },
    });
  });

  // Global variable for shared custom functionality.
  win.BC || (win.BC = {});
  $.extend(win.BC, {
    sync: ATGFormHandler,

    // Template caching for a page.
    templates: {},

    // Controller caching for a page. Makes for easier debugging.
    controllers: [],
  });

  // Set underscore templates to use {{expression}} syntax.
  _.templateSettings = {
    evaluate: /\{%([\s\S]+?)%\}/g,
    interpolate: /\{\{([\s\S]+?)\}\}/g,
    escape: /\{\{\{([\s\S]+?)\}\}\}/g,
  };

  // Force no caching for ajax interactions.
  $.ajaxSetup({
    cache: false,
  });

  _.mixin({
    // Utility function to turn `[ {name: "some_name", value: "some_value" },  ... ]`
    // into `{ some_name: some_value }`.
    // The iterator parameter is a function which should return an array where the first
    // element is the key and the second the value.
    // e.g. `function(x) { return [x.name, x.value] }`
    toMap(array, iterator) {
      return _(array).reduce(function(map, x, k) {
        var keyVal = iterator(x, k);

        map[keyVal[0]] = keyVal[1];

        return map;
      }, {});
    },
  });

  $(function() {
    fixObjectFit('img');

    _(BC.optimizelyDelayed).each(function(fn) {
      try {
        fn($);
      } catch (e) {
        $.error(e);
      }
    });

    // Reuse this widget for lazyload content
    $('.js-targeter-lazyload').lazyload({
      inner: true,
      proximity: 100,
    });

    var csId = urlUtils.inUrl('CS_ID');

    if (csId) {
      var paramValue = 'DG_BC0001';

      if (BC.site.code === 'dogfunk') {
        paramValue = 'DG_DF0001';
      } else if (BC.site.code === 'competitivecyclist') {
        paramValue = 'DG_CC0001';
      }
      if (csId === paramValue) {
        $('<span />')
          .addClass('popup-link')
          .attr('data-ui', '{"remoteDialog": { "href" : "/Store/content/contentDescription.jsp?id=' + BC.site.code + '-dogClosure", "width" : "525px"}}')
          .appendTo('.foot')
          .click();
      }
    }
    const siteFooterElement = document.querySelector('#site-footer');

    runOnDOMIntersection({
      element: siteFooterElement,
      proximity: 0,
    }).then(() => import(
      /* webpackChunkName: "common.footer" */
      'common/widgets/bc.slidetoggle'
    )).then(() => {
      const slideToggleOpts = {
        animate: 0,
        bodySel: 'ul',
        headSel: BC.site.code === 'bcs' ? 'h2' : 'h4',
        startHidden: true,
      };
      const resourcesElement = document.querySelector('.js-footer-links-faq-resources');
      const aboutusElement = document.querySelector('.js-footer-links-faq-aboutus');

      if (!has('large')) {
        $(resourcesElement).slidetoggle(slideToggleOpts);
        $(aboutusElement).slidetoggle(slideToggleOpts);
      }

      BC.subscribe('breakpoint.large.match', () => {
        if ($(resourcesElement).data('bc-slidetoggle') && $(aboutusElement).data('bc-slidetoggle')) {
          $(resourcesElement).slidetoggle('destroy');
          $(aboutusElement).slidetoggle('destroy');
        }
      });

      BC.subscribe('breakpoint.large.unmatch', () => {
        if (!$(resourcesElement).data('bc-slidetoggle') && !$(aboutusElement).data('bc-slidetoggle')) {
          $(resourcesElement).slidetoggle(slideToggleOpts);
          $(aboutusElement).slidetoggle(slideToggleOpts);
        }
      });
    }).catch(() => {});
  });

  affiliates.track(window.location.search);

  if (has('omnituretrackresize')) {
    var trackedFirstResize = false;
    var omnitureTrackResize = function() {
      if (!trackedFirstResize) {
        trackedFirstResize = true;
        $.publish('ScTrackFirstResize.sitecatalystutil');
      }
    };

    _.each(breakpoints, function(query) {
      $.subscribe('breakpoint.' + query.size + '.unmatch', omnitureTrackResize);
    });
  }

  BC.subscribe('temporaryMessage.open', (opts) => {
    import(
      /* webpackChunkName: "shared.temp.banner" */
      'common/util/global-temp-banner/index'
    ).then(({ mountTemporaryBannerComponent }) => {
      mountTemporaryBannerComponent(opts);
    });
  });

  if (BC.user) {
    BC.user.loyalty = {
      isUserLoyaltyRegistered: false,
      member: {},
      points_summary: {},
      coupons: [],
      rewards: [],
    };
  }

  if (has('loyalty') && BC.user && BC.user.isExplicitOrAutoLoggedIn && BC.profile.id) {
    import(
      /* webpackChunkName: "loyalty.membership.service" */
      'common/services/loyalty-membership-service'
    ).then(({ getLoyaltyUser }) => getLoyaltyUserstatus(getLoyaltyUser));
  }

  function getLoyaltyUserstatus(getStatus) {
    getStatus(BC.profile.id, 'rewards,coupons,points_summary').then((response) => {
      if (response.member !== undefined) {
        response.isUserLoyaltyRegistered = true;
        BC.user.loyalty = response;
        BC.publish('loyalty.user.updated');
      }
    });
  }
});
