const keyboardCodes = {
  back: 8, // delete key on mac
  tab: 9,
  enter: 13,
  shift: 16, // shiftKey = true
  ctrl: 17, // ctrlKey = true
  alt: 18, // (a.k.a. option on Mac) altKey = true
  esc: 27,
  space: 32,
  pageUp: 33, // fn + up on mac
  pageDown: 34, // fn + down on mac
  end: 35, // fn + right on mac
  home: 36, // fn + left on mac
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  del: 46, // fn + delete on mac
  command: 91, // metaKey = true (mac and sun machines)
};

function setErrorMessageForInput(inputSelector, errorMessageId) {
  const inputField = document.querySelector(inputSelector);

  if (!inputField || !errorMessageId) return;

  inputField.setAttribute('aria-invalid', 'true');
  inputField.setAttribute('aria-describedby', errorMessageId);
}

function clearFormErrors(formSelector) {
  const errorFields = document.querySelectorAll(`${formSelector} [aria-invalid="true"]`);

  if (!errorFields || errorFields.length <= 0) return;

  Array.from(errorFields).forEach((input) => {
    input.removeAttribute('aria-invalid');
    input.setAttribute('aria-describedby', '');
  });
}

function hasReducedMotion() {
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

function setCheckboxEventListener(checkboxSelector) {
  Array.from(document.querySelectorAll(checkboxSelector)).forEach((checkbox) => {
    checkbox.addEventListener('keypress', checkboxesEnterHandler, false);
  });
}

function checkboxesEnterHandler(event) {
  if (event.keyCode === keyboardCodes.enter) {
    event.preventDefault();
    const element = event.target;

    element.checked = !element.checked;
    element.dispatchEvent(new Event('change'));

    event.stopPropagation();
  }
}

function getFocusableElements(container, exceptions) {
  if (!container) return;

  let focusablesSel = 'button:not([disabled]), [href], input:not([disabled]):not([type=radio]), input[type=radio]:checked, select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';

  if (exceptions && exceptions.length) {
    const focusables = focusablesSel.split(',').map((sel) => {
      let exceptionSel = '';

      exceptions.forEach((exception) => {
        exceptionSel += `:not(${exception})`;
      });

      return `${sel}${exceptionSel}`;
    });

    focusablesSel = focusables.join(',');
  }

  return container.querySelectorAll(focusablesSel);
}

export {
  clearFormErrors,
  keyboardCodes,
  setErrorMessageForInput,
  hasReducedMotion,
  setCheckboxEventListener,
  getFocusableElements,
};
