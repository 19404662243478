import React, { useEffect, useReducer, useRef } from 'react';

import useCallCenterOpenPoll from 'common/components/hooks/useCallCenterOpenPoll';
import useFeatures from 'common/components/hooks/use-features';
import PersistentButton from './persistent-button';

const PROACTIVE_CHAT_MINUTE_THRESHOLD = 10;

const proactiveReducer = (state, action) => {
  switch (action.type) {
    case 'open-chat':
      if (action.payload) {
        window.Gladly.show();
      } else {
        BC.publish(
          `gtm.track.${state.isBannerVisible ? 'proactive' : 'persisted'
          }.chat.click`
        );

        document.dispatchEvent(
          new CustomEvent('bc_chat::open', {
            detail: {
              tracking: state.isBannerVisible ? '' : 'liveChatPersistent',
            },
          })
        );
      }

      return { ...state, shouldShow: false };

    case 'show-proactive-banner':
      return { ...state, isBannerVisible: true };

    case 'show-persistent':
      return { ...state, shouldShow: action.payload, isBannerVisible: false };

    case 'set-overlay':
      return {
        ...state,
        isOverlaid: action.payload.isOverlay,
        overlayElement: action.payload.source,
      };

    case 'component-load':
      return { ...state, shouldShow: true };
  }
};

const canOpenProactiveChat = () => {
  const localStorageKey = 'proactiveChatTimestamp';
  const currentTime = new Date();
  const lastTimestamp = window.localStorage.getItem(localStorageKey);

  if (!lastTimestamp) {
    window.localStorage.setItem(
      localStorageKey,
      currentTime.getTime().toString()
    );

    return true;
  }

  const currentTimeMinusThreshold = new Date();

  currentTimeMinusThreshold.setMinutes(
    currentTimeMinusThreshold.getMinutes() - PROACTIVE_CHAT_MINUTE_THRESHOLD
  );

  return false;
};

export default function ProactiveChat() {
  const {
    persist_chat: persistChatFS,
    proactive_chat: proactiveChatFS,
    proactive_chat_checkout: proactiveChatCheckoutFS,
    gladly: gladlyFS,
  } = useFeatures([
    'persist_chat',
    'proactive_chat',
    'proactive_chat_checkout',
    'gladly',
  ]);

  const mutationObserver = useRef();

  const [
    {
      shouldShow,
      isOverlaid,
      overlayElement,
    },
    dispatch,
  ] = useReducer(proactiveReducer, {
    shouldShow: false,
    isOverlaid: false,
    overlayElement: null,
  });

  const isCheckout = window.location.pathname.includes('/checkout');

  const isCallCenterOpen = useCallCenterOpenPoll(shouldShow || isCheckout);

  useEffect(() => {
    mutationObserver.current = new MutationObserver(
      // MutationObserver is not defined on Jest so it needs to be mocked
      /* istanbul ignore next */
      (mutationList) => {
        const mutationRecord = mutationList[0];

        const isClass = mutationRecord.type === 'attributes' && mutationRecord.attributeName === 'class';

        if (isClass) {
          const isChatActive = mutationRecord.target.classList.contains('active');

          if (!isChatActive) {
            dispatch({ type: 'show-persistent', payload: !isCheckout });
          } else {
            dispatch({ type: 'show-persistent', payload: false });
          }
        }
      }
    );

    // We need to wait until the jacada chat is added to the DOM
    const intervalId = setInterval(function() {
      if (document.getElementById('jacada-chatbot')) {
        clearInterval(intervalId);
        mutationObserver.current.observe(document.getElementById('jacada-chatbot'), {
          attributeFilter: ['class'],
        });
      }
    }, 100);

    return () => mutationObserver.current.disconnect();
  }, [isCheckout]);

  useEffect(() => {
    const canShowOnCheckout = () => proactiveChatCheckoutFS || !isCheckout;

    const eventListener = (event) => {
      if (
        proactiveChatFS &&
        event.detail &&
        event.detail.showProactiveChatBubble &&
        canOpenProactiveChat() &&
        canShowOnCheckout()
      ) {
        dispatch({
          type: 'show-proactive-banner',
        });
      } else if (!event.detail) {
        dispatch({
          type: 'show-persistent',
          payload: false,
        });
      }
    };

    document.addEventListener('bc_chat::open', eventListener);

    return () => {
      document.removeEventListener('bc_chat::open', eventListener);
    };
  }, [
    isCheckout,
    proactiveChatCheckoutFS,
    proactiveChatFS,
  ]);

  useEffect(() => {
    if (persistChatFS && !isCheckout) {
      dispatch({ type: 'component-load', payload: true });
    }
  }, [isCheckout, persistChatFS]);

  useEffect(() => {
    const eventListener = (event) => {
      dispatch({ type: 'set-overlay', payload: event.detail });
    };

    document.addEventListener('bc_chat::overlay', eventListener);

    return () => {
      document.removeEventListener('bc_chat::overlay', eventListener);
    };
  }, []);

  useEffect(() => {
    const chatElement = document
      .querySelector('#jacada-chatbot');

    if (chatElement) {
      const isChatStillActive = chatElement.classList.contains('active');

      if (isChatStillActive) {
        dispatch({
          type: 'show-persistent',
          payload: false,
        });
      }
    }
  }, []);

  return (
    isCallCenterOpen &&
    shouldShow && (
      <>
        <PersistentButton
          overlaidInfo={{
            isOverlaid,
            element: overlayElement,
          }}
          onClick={() => dispatch({ type: 'open-chat', payload: gladlyFS })}
        />
      </>
    )
  );
}
