define(function(require) {
  require('vendors/jquery.iframe-transport');

  return function(method, model, options = {}, $form) {
    var $formSubmit = $form.find('input[type=submit]');
    var data = $form.serializeArray();
    var formHandler = $formSubmit.attr('name').split('.')[0];

    // Add submit button as parameter (required for ATG to delegate handler methods)
    data.push({
      name: $formSubmit.prop('name'),
      value: $formSubmit.val(),
    });

    // Populate the form data with the model attributes
    for (var i in data) {
      if (data[i].name == null) {
        continue;
      }
      var splitDataName = data[i].name.split('.');
      var derivedFormHandlerName = splitDataName[0];
      var derivedAttrName = splitDataName[splitDataName.length - 1];
      var isRedirectUrl = (/URL$/).test(derivedAttrName) && (/^\/atg/).test(derivedFormHandlerName);

      // Only add a model attrib as a param if the form contains a corresponding field
      if (derivedFormHandlerName === formHandler) {
        if (options.data && options.data[derivedAttrName] !== undefined) {
          // Populate with provided data, if applicable
          data[i].value = options.data[derivedAttrName];
        } else if (model.has(derivedAttrName)) {
          // Otherwise populate with existing model data
          data[i].value = model.get(derivedAttrName);
        }

        // During the https migration period we'll have instances of
        // protocol mismatch with the handling of ATG forms. For now, this
        // block will replace http -> https when needed and warn when
        // submitting https from an http page. This should disappear after
        // the migration is done.
        if (isRedirectUrl) {
          var redirectUrl = data[i].value;
          var isRelativeUrl = redirectUrl.indexOf('http') !== 0;
          var needsSSL = location.protocol === 'https:';

          if (isRelativeUrl && needsSSL) {
            data[i].value = 'https://' + location.host + redirectUrl;
          } else if (redirectUrl.indexOf(location.protocol !== 0)) {
            if (needsSSL) {
              data[i].value = redirectUrl.replace(/^http:/, 'https:');
            }
          }
          // CSR form success/error urls for some reason use www instead of csr
          if (location.host.indexOf('csr') === 0) {
            data[i].value = data[i].value.replace('//www.', '//csr.');
          }
        }
      }
    }

    // Preserve original success/error handlers
    var success = options.success;
    var error = options.error;

    // Extend options with some defaults
    $.extend(options, {
      url: $form.attr('action'),
      success(resp, status, xhr) {
        var attributesFromResp;

        if (resp.success) {
          attributesFromResp = _.clone(resp.attributes);
          attributesFromResp && model.set(
            model.parse(attributesFromResp),
          );
          success && success(resp, status, xhr);
        } else {
          // Error is expected be similar to
          // {"success":false,"errorMessage":"Please enter a valid email address.","inputName":"email","errorCode":"email"}
          model.trigger(`${method}Error`, resp);
          error && error(resp, status, xhr);
        }
      },
      error(resp, status, xhr) {
        model.trigger(method + 'Error');
        error && error(resp, status, xhr);
      },
    });

    // Handle non-asynchronous sync
    if (options.sync) {
      delete options.sync;

      // Create a new form for storing all of the method data
      var $syncForm = $('<form method=\'post\'></form>').attr('action', $form.attr('action'))
        .addClass('hidden');

      // Append all of the data as hidden inputs to the form
      $.each(data, function() {
        var $input = $('<input type=\'hidden\'>');

        $input.attr('name', this.name)
          .attr('value', this.value);
        $syncForm.append($input);
      });

      // Add the file input and setup the form accordingly, if applicable
      if (options.files != null) {
        // Set the input name to correspond with the ATG input conventions
        options.files.attr('name', formHandler + '.' + options.files.attr('name'));
        $syncForm.append(options.files)
          .attr('enctype', 'multipart/form-data');
      }

      // Submit the form
      $syncForm.appendTo($('body'))
        .submit();

      return;
    }

    // Handle sync for files
    if (options.files != null) {
      var files = options.files;
      // List of community form handlers that need text dataType for their responses.
      var textTypeForms = [
        '#ajaxform-createPhoto',
        '#ajaxform-createQuestion',
        '#ajaxform-createReview',
        '#ajaxform-updatePhoto',
        '#ajaxform-updateQuestion',
        '#ajaxform-updateReview',
      ];
      var name;

      // Remember the original name attr so if the form is submitting again
      // it doesn't keep appending the ATG input conventions.
      if (!files.data('name.sync')) {
        files.data('name.sync', files.prop('name'));
      }
      name = files.data('name.sync');

      // Set the input name to correspond with the ATG input conventions
      files.prop('name', formHandler + '.' + name);

      // Add params for iframe.transport
      $.extend(options, {
        processData: false,
        data,
        dataType: 'json',
        iframe: true,
        form: null,
        type: 'POST',
      });

      // Add support for text dataType on community wall responses.
      // This fixes a bug where the browser mis-interprets json responses from file uploads.
      if (_.contains(textTypeForms, $form.selector)) {
        options.dataType = 'text';
      }

      // Submit the form (options force use of iframe)
      return $.ajax(options).complete(options.success)
        .fail(options.error);
    } else {
      // Stringify the data for standard (non-file) sync requests
      $.extend(options, {
        data: $.param(data),
      });
    }

    return Backbone.sync('create', model, options);
  };
});
