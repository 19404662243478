/* This helper provides a centralized and easy to maintain way to submit atg forms directly with ajax.
 *  Before using:
 *  Include your form jsp into the modules/Store/config/backcountry/util/FormInitializer.properties
 *  So they are initialized by the healthCheck.jsp and don't need to be rendered on the HTML
 *  Common usage:
 *  var formInfo = atgAjaxHelper.formMethod(param1, param2, ..., paramN);
 *  $.post(formInfo.url, formInfo.data).then(callback);
 */
define(function(require, exports, module) {
  var sessionHelper = require('common/util/session-helper');

  // Generic builder, `meta` takes the handler name and action id, second parameter is the actual data
  // and the third parameter is data that doesn't need the meta.handler prefix now _D: arg
  function makeForm(meta, data, rawData) {
    rawData = rawData || {};

    var form = [
      {
        name: '_dyncharset',
        value: 'UTF-8',
      },
      {
        name: '_dynSessConf',
        value: meta.session,
      },
      {
        name: '_DARGS',
        value: 'ajaxform-' + meta.handlerId,
      },
    ];

    Object.keys(data).forEach(function(key) {
      form.push({
        name: meta.handler + key,
        value: data[key],
      },
      {
        name: '_D:' + meta.handler + key,
        value: '',
      });
    });

    Object.keys(rawData).forEach(function(key) {
      form.push({
        name: key,
        value: rawData[key],
      });
    });

    return form.concat(ajaxFormRedirects(meta));
  }

  function ajaxFormRedirects(meta) {
    // TODO change this to use window.location.origin when it's fully supported by IE11
    // TODO create default response values to avoid repetition
    var windowOrigin = window.location.protocol + '//' + window.location.hostname;
    var handlers = {
      add: [
        {
          name: meta.handler + 'addItemToOrderSuccessURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'addItemToOrderErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
      ],
      addJson: [
        {
          name: meta.handler + 'addItemToOrderSuccessURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'addItemToOrderErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
      ],
      checkout: [
        {
          name: meta.handler + 'moveToPurchaseInfoErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'moveToPurchaseInfoSuccessURL',
          value: windowOrigin + '/Store/common/success.json.jsp',
        },
      ],
      login: [
        {
          name: meta.handler + 'loginSuccessURL',
          value: windowOrigin + '/Store/authModal/authSuccess.json.jsp',
        },
        {
          name: meta.handler + 'loginErrorURL',
          value: windowOrigin + '/Store/authModal/authError.json.jsp',
        },
      ],

      create: [
        {
          name: meta.handler + 'createSuccessURL',
          value: windowOrigin + '/Store/authModal/authSuccess.json.jsp',
        },
        {
          name: meta.handler + 'createErrorURL',
          value: windowOrigin + '/Store/authModal/authError.json.jsp',
        },
      ],
      remove: [
        {
          name: meta.handler + 'removeItemFromOrderSuccessURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'removeItemFromOrderErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
      ],
      subscribeAll: [
        {
          name: meta.handler + 'updateSuccessURL',
          value: windowOrigin + '/Store/catalog/profileSubscribeAllSuccess.json.jsp',
        },
        {
          name: meta.handler + 'updateErrorURL',
          value: windowOrigin + '/Store/catalog/profileSubscribeAllError.json.jsp',
        },
      ],
      updateQuantity: [
        {
          name: meta.handler + 'updateItemSuccessURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'updateItemErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
      ],
      createReview: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authError.json.jsp`,
        },
      ],
      updateReview: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      createPhoto: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      updatePhoto: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      createVideo: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      updateVideo: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      createQuestion: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      updateQuestion: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      createAnswer: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      updateAnswer: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      createComment: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      updateComment: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      deleteContent: [],
      undeleteContent: [],
      flagContent: [],
      unflagContent: [],
      sendBikeBuilderEmail: [],
      serviceRequestAjax: [
        {
          name: `${meta.handler}successUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
        {
          name: `${meta.handler}errorUrl`,
          value: `${windowOrigin}/Store/authModal/authSuccess.json.jsp`,
        },
      ],
      profileInfo: [],
      profilePicture: [],
      addWithWarranty: [
        {
          name: meta.handler + 'addItemToOrderSuccessURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
        {
          name: meta.handler + 'addItemToOrderErrorURL',
          value: windowOrigin + '/Store/cart/currentOrderDetails.json.jsp',
        },
      ],
    };
    var result = handlers[meta.handlerId];

    handlers[meta.handlerId].forEach(function(field) {
      result.push({
        name: '_D:' + field.name,
        value: '',
      });
    });

    return result;
  }

  // TODO should we use current page and just append "?_DARGS" instead of using the explicit page?
  function ajaxFormUrl(meta) {
    var locations = {
      checkout: '/Store/cart/cart.jsp?_DARGS=/Store/cart/includes/cartAjaxForms.jsp.ajaxform-checkout',
      login: '/Store/authModal/includes/authAjaxForms.jsp?_DARGS=/Store/authModal/includes/authAjaxForms.jsp.ajaxform-login',
      create: '/Store/authModal/includes/authAjaxForms.jsp?_DARGS=/Store/authModal/includes/authAjaxForms.jsp.ajaxform-create',
      remove: '/Store/cart/cart.jsp?_DARGS=/Store/cart/includes/removeFromCartForm.jsp.ajaxform-remove',
      add: '/Store/cart/cart.jsp?_DARGS=/Store/catalog/include/pdpv3/buybox/actions.jsp.ajaxform-add',
      addJson: '/Store/cart/cart.jsp?_DARGS=/Store/cart/includes/cartAjaxForms.jsp.ajaxform-addJson',
      sendBikeBuilderEmail: '/Store/catalog/include/bikeBuilderEmailForm.jsp?_DARGS=/Store/catalog/include/bikeBuilderEmailForm.jsp.ajaxform-sendBikeBuilderEmail',
      subscribeAll: '/Store/home.jsp?_DARGS=/Store/navigation/footerOptIn.jsp.ajaxform-subscribeAll',
      updateQuantity: '/Store/cart/cart.jsp?_DARGS=/Store/cart/includes/cartAjaxForms.jsp.ajaxform-updateQuantity',
      createReview: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createReview',
      updateReview: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updateReview',
      createPhoto: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createPhoto',
      updatePhoto: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updatePhoto',
      createVideo: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createVideo',
      updateVideo: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updateVideo',
      createQuestion: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createQuestion',
      updateQuestion: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updateQuestion',
      createAnswer: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createAnswer',
      updateAnswer: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updateAnswer',
      createComment: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-createComment',
      updateComment: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-updateComment',
      deleteContent: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-delete',
      undeleteContent: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-undelete',
      flagContent: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-flag',
      unflagContent: '/Store/catalog/productLanding.jsp?_DARGS=/Store/community/include/wall/productWallAjaxForms.jsp.ajaxform-unflag',
      serviceRequestAjax: '/Store/home.jsp?_DARGS=/Store/global/include/serviceRequestAjaxForm.jsp.ajaxform-serviceRequestAjax',
      profileInfo: '/Store/community/userProfile.jsp?_DARGS=/Store/community/include/profile/profileAjaxForms.jsp.ajaxform-profileInfo',
      profilePicture: '/Store/community/userProfile.jsp?_DARGS=/Store/community/include/profile/profileAjaxForms.jsp.ajaxform-profilePicture',
      addWithWarranty: '/Store/cart/cart.jsp?_DARGS=/Store/catalog/include/pdpv3/buybox/actions.jsp.ajaxform-add',
    };

    return locations[meta.handlerId];
  }

  function buildFormInfo(meta, data, rawData) {
    return {
      data: makeForm(meta, data, rawData),
      url: ajaxFormUrl(meta),
    };
  }

  module.exports = {
    addItemToCart(item) {
      return sessionHelper.getSession().then(function(session) {
        return buildFormInfo({
          handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
          handlerId: 'add',
          session,
        }, {
          catalogRefIds: item.id,
          groupedItem: item.groupedItem,
          productId: item.productId,
          quantity: item.quantity || 1,
          showBackorderMessage: item.showBackorderMessage,
          skipGiftOrPackageCheck: false,
          addItemToOrder: '',
        });
      });
    },
    addJsonItemToCart(jsonData) {
      return sessionHelper.getSession().then(function(session) {
        return buildFormInfo({
          handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
          handlerId: 'addJson',
          session,
        }, {
          ajaxRequest: true,
          jsonAddItemData: jsonData,
          addItemToOrder: '',
        });
      });
    },
    login(email, password, keepLoggedIn) {
      return buildFormInfo(
        {
          handler: '/atg/userprofiling/ProfileFormHandler.',
          handlerId: 'login',
        },
        {
          'value.login': email,
          'value.password': password,
          keepLoggedIn,
          loginDuringCheckout: BC.page.id === 'checkout',
          login: '',
        }
      );
    },
    createAccount(fullName, firstName, lastName, email, password, confirmPassword, birthdate) {
      return buildFormInfo({
        handler: '/atg/userprofiling/ProfileFormHandler.',
        handlerId: 'create',
      }, {
        source: 'my-account',
        'value.displayName': fullName,
        'value.firstName': firstName,
        'value.lastName': lastName,
        'value.email': email,
        'value.login': email,
        'value.password': password,
        'value.confirmpassword': confirmPassword,
        'value.member': true,
        'value.birthdate': birthdate,
        create: '',
      });
    },
    proceedToCheckout(shippingMethod, commerceItems) {
      return buildFormInfo({
        handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
        handlerId: 'checkout',
      }, {
        userChoosenShippingMethod: shippingMethod,
        moveToPurchaseInfoByCommerceId: '',
      }, commerceItems);
    },
    removeItemFromCart(itemId) {
      return buildFormInfo({
        handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
        handlerId: 'remove',
      }, {
        removalCommerceIds: itemId,
        removeItemFromOrder: '',
      });
    },
    sendBikeBuilderEmail(buildData) {
      return buildFormInfo({
        handler: '/backcountry/catalog/bikebuilder/formhandler/BikeBuilderEmailFormHandler.',
        handlerId: 'sendBikeBuilderEmail',
      }, {
        ...buildData,
        sendBikeBuilderEmail: '',
      });
    },
    subscribeAll(email) {
      return buildFormInfo({
        handler: '/atg/userprofiling/ProfileFormHandler.',
        handlerId: 'subscribeAll',
      }, {
        'value.emailConfirm': email,
        source: 'footer',
        subscribeAll: '',
      });
    },
    updateItemQuantity(itemId, quantity) {
      return buildFormInfo({
        handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
        handlerId: 'updateQuantity',
      }, {
        updateCommerceId: itemId,
        updateCommerceItemQty: quantity,
        updateItem: '',
      });
    },
    createReview(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/ReviewFormHandler.',
        handlerId: 'createReview',
      }, contentData);
    },
    updateReview(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/ReviewFormHandler.',
        handlerId: 'updateReview',
      }, contentData);
    },
    createPhoto(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/ProductImageFormHandler.',
        handlerId: 'createPhoto',
      }, contentData);
    },
    updatePhoto(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/ProductImageFormHandler.',
        handlerId: 'updatePhoto',
      }, contentData);
    },
    createVideo(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/VideoFormHandler.',
        handlerId: 'createVideo',
      }, contentData);
    },
    updateVideo(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/VideoFormHandler.',
        handlerId: 'updateVideo',
      }, contentData);
    },
    createQuestion(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/QuestionFormHandler.',
        handlerId: 'createQuestion',
      }, contentData);
    },
    updateQuestion(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/QuestionFormHandler.',
        handlerId: 'updateQuestion',
      }, contentData);
    },
    createAnswer(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/AnswerFormHandler.',
        handlerId: 'createAnswer',
      }, contentData);
    },
    updateAnswer(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/AnswerFormHandler.',
        handlerId: 'updateAnswer',
      }, contentData);
    },
    createComment(commentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/CommentFormHandler.',
        handlerId: 'createComment',
      }, commentData);
    },
    updateComment(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/CommentFormHandler.',
        handlerId: 'updateComment',
      }, contentData);
    },
    deleteContent(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/InactivateContentFormHandler.',
        handlerId: 'deleteContent',
      }, contentData);
    },
    undeleteContent(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/InactivateContentFormHandler.',
        handlerId: 'undeleteContent',
      }, contentData);
    },
    flagContent(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/FlagContentFormHandler.',
        handlerId: 'flagContent',
      }, contentData);
    },
    unflagContent(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/FlagContentFormHandler.',
        handlerId: 'unflagContent',
      }, contentData);
    },
    serviceRequestAjax(contentData) {
      return buildFormInfo({
        handler: '/backcountry/common/droplet/formhandler/ServiceRequestFormHandler.',
        handlerId: 'serviceRequestAjax',
      }, contentData);
    },
    updateUserProfileInfo(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/CommunityUserProfileFormHandler.',
        handlerId: 'profileInfo',
      }, contentData);
    },
    updateProfilePhoto(contentData) {
      return buildFormInfo({
        handler: '/backcountry/community/formhandler/CommunityUserProfileFormHandler.',
        handlerId: 'profilePicture',
      }, contentData);
    },
    addItemWithWarrantyToCart(jsonData) {
      return sessionHelper.getSession().then(function(session) {
        return buildFormInfo({
          handler: '/atg/commerce/order/purchase/CartModifierFormHandler.',
          handlerId: 'addWithWarranty',
          session,
        }, {
          ajaxRequest: true,
          jsonAddItemData: jsonData,
          skipGiftCheck: '',
          addItemToOrder: '',
        });
      });
    },
  };
});
