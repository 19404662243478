import { debounce } from 'underscore';
import { getParameterByName } from 'common/util/bc.url.utils';
import { removeCurrencyFormat } from 'common/util/string-utils';
import {
  getEcommerceObject,
  getProductListFromOrder,
  getProductListFromProduct,
  getEventData,
  sendEvent,
  sendCheckoutStepEvent,
  sendAddToCartEvent,
  sendRemoveFromCartEvent,
  sendProductImpression,
  sendNullSearchesResult,
  sendSearchesOrPerformedBrowseWithNumResult,
  sendProductClickEvent,
  getOrderMargin,
} from 'common/util/gtm-helper';
import { runOnDOMIntersection } from 'common/util/intersection-observer-helper';

function setDataLayer() {
  window.dataLayer = window.dataLayer || [];
  const pageId = BC.page.id;
  const isLoggedIn = BC.user.isLoggedIn || BC.user.isExplicitOrAutoLoggedIn;
  const isLoggedInOrRegistered = isLoggedIn || BC.user.isRegistered;
  const userId = BC.profile.id;
  const isAnonymous = !isLoggedInOrRegistered && userId;
  const loyaltyStatus = BC.user && BC.user.loyalty && BC.user.loyalty.isUserLoyaltyRegistered;
  let accountStatus = BC.user.isRegistered ? 'recognized' : 'guest';

  if (isLoggedIn) {
    accountStatus = 'registered';
  }

  // Old implementation: UA, this will be removed
  let loadData = {
    userLoginStatus: isLoggedIn,
    pageType: pageId,
    userId,
    platform: 'web',
  };

  const pageViewData = {
    event: 'page_view',
    page_type: pageId,
    ...(isLoggedInOrRegistered && { user_id: userId }),
    ...(isAnonymous && { anonymous_user_id: userId }),
    platform: 'web',
    login_status: isLoggedIn,
    account_status: accountStatus,
    loyalty_status: !!loyaltyStatus,
  };

  if (BC.orderId) {
    loadData.ecommerce = { purchase: { actionField: { id: BC.orderId } } };
  }

  if (has('is-native-app')) {
    loadData.platform = 'app';
    pageViewData.platform = 'app';
  }

  if (pageId === 'checkout') {
    const cartLoyaltyTracking = JSON.parse(sessionStorage.getItem('cartLoyaltyTracking')) || {};

    loadData = { ...loadData, ...cartLoyaltyTracking };
    sessionStorage.removeItem('cartLoyaltyTracking');
  }

  // New  implementation: GA4, please remove loadData when all the migration from UA to GA4 is done
  sendEvent(pageViewData);
  // Old implementation: UA
  sendEvent(loadData);
}

function globalEvents() {
  // Remove item from cart page and BC minicart
  BC.subscribe('commerce_item_view:remove', (item) => {
    sendRemoveFromCartEvent(item);
  });

  // Successful account login
  BC.subscribe('account:just-loggedIn', () => {
    sendEvent({
      ...getEventData('customEvent', 'account', 'login'),
    });
  });

  // Successful account created
  BC.subscribe('account:created', () => {
    sendEvent({
      ...getEventData('customEvent', 'account', 'create success', BC.user.isLoggedIn ? BC.profile.id : undefined),
    });
  });

  // Global footer click
  const trackFooterClick = linkText => linkText && sendEvent({
    ...getEventData('customEvent', 'global footer', 'global footer click', linkText),
  });

  // Global header click
  BC.subscribe('gtm.track.flyout.option.engagment', ([sectionText, linkText]) => {
    if (sectionText && linkText) {
      sendEvent({
        ...getEventData('customEvent', 'global navigation', sectionText, linkText),
      });
    }
  });

  // Email Signup Events
  BC.subscribe('gtm.track.email.signup.success', ({ label, custom }) => sendEvent({
    ...getEventData('customEvent', 'email', 'email signup success', label),
    custom,
  }));

  BC.subscribe('gtm.track.email.signup.failure', reason => sendEvent({
    ...getEventData('customEvent', 'email', 'email signup failure', reason),
  }));

  const footerLinks = Array.from(document.querySelectorAll('.js-footer-link'));

  footerLinks
    .forEach(element => element.addEventListener('click', (event) => {
      event && event.target && event.target.textContent && trackFooterClick(event.target.textContent);
    }));

  // Product widget impressions
  const products = Array.from(document.querySelectorAll('.js-product-listing'));

  products.forEach((element) => {
    runOnDOMIntersection({
      element,
      proximity: 0,
    }).then((target) => {
      const url = target.querySelector('.js-pl-link').href;

      sendProductImpression('search', target, url);
    });
  });

  // Group Sales Tracking
  const groupSalesSubmit = document.querySelector('#request-information-btn');

  if (groupSalesSubmit) {
    groupSalesSubmit.addEventListener('click', () => {
      sendEvent({
        ...getEventData('customEvent', 'corporate & group orders', 'submitted request'),
      });
      sendEvent({
        event: 'submit_form',
        form_name: 'get a gear quote',
      });
    });
  }

  if ($('#recs_zero_results').length) {
    sendNullSearchesResult();
  }

  // Breadcrumb for PDPs and PLPs
  if ($('.js-track-breadcrumb').length) {
    sendEvent({ breadcrumb: $('.js-track-breadcrumb').data('breadcrumb') });
  }

  if ($('.js-results-qty').length) {
    sendSearchesOrPerformedBrowseWithNumResult($('.js-results-qty').data('search-result-count'));
  }
}

function checkoutEvents(billingModel) {
  // Checkout Step 1 - On load
  sendCheckoutStepEvent({ step: 1 }, 'step 1 - review and place your order');

  sessionStorage.removeItem('cartLoyaltyCouponTracking');

  // Checkout Step 2 - Address
  if (billingModel) {
    let checkoutStep2Complete = false;
    const handleAdressChange = () => {
      if (!checkoutStep2Complete && billingModel.isAddressComplete()) {
        checkoutStep2Complete = true;
        sendCheckoutStepEvent({ step: 2, option: 'anonymous' }, 'step 2 - enter your billing address');
      }
    };

    billingModel.bind('change', debounce(handleAdressChange, 1000));
  } else {
    sendCheckoutStepEvent({ step: 2, option: 'express' }, 'step 2 - enter your billing address');
  }

  // Checkout Step 3 - Payment
  let checkoutStep3Complete = false;

  $('.js-submit-order-btn').click(() => {
    if (!checkoutStep3Complete) {
      checkoutStep3Complete = true;
      const paymentInput = $('#card').attr('name');
      let paymentOption;

      $(`input[name="${paymentInput}"]`).each((index, input) => {
        if (input.checked) {
          paymentOption = input.value;
        }
      });

      sendCheckoutStepEvent({
        step: 3,
        option: paymentOption === 'paypal' || paymentOption === 'klarna' ? paymentOption : 'credit card',
      }, 'step 3 - verify your payment information');
    }
  });

  // Coupon applied
  setCouponClaimEvent();

  // Loyalty Join Now CTA
  $(document).on('click', '.js-loyalty-join-cta', (event) => {
    const location = event.currentTarget.dataset.location;

    sendEvent({
      event: 'customEvent',
      eventCategory: 'loyalty program',
      eventAction: `join expedition perks|${location}`,
      eventLabel: 'checkout',
    });
  });
}

function cartEvents() {
  // Add to cart
  const productId = getParameterByName('productId');

  if (productId) {
    const productAdded = BC.order.attributes.commerceItems.find(item => item.productId === productId);

    if (productAdded) {
      sendAddToCartEvent(productAdded);
    }
  }

  // Update quantity
  BC.subscribe('cart.item.quantity.update', (item, quantity) => {
    if (quantity > item.quantity) {
      sendAddToCartEvent({ ...item, quantity: quantity - item.quantity });
    } else {
      sendRemoveFromCartEvent({ ...item, quantity: item.quantity - quantity });
    }
  });

  const customEvents = [
    ['cart move to wishlist', 'move-to-wishlist'],
    ['wishlist move to cart', 'move-to-cart'],
    ['wishlist remove', 'remove-from-wishlist'],
    ['wishlist shop similar', 'shop-similar'],
  ];

  customEvents.forEach(([action, topic]) => {
    setProductIdEvent({
      action, category: 'wishlist', event: 'customEvent', topic,
    });
  });

  // Coupon applied
  setCouponClaimEvent();
}

function setCouponClaimEvent() {
  BC.subscribe('coupon.code.applied', (promoCode) => {
    if (promoCode) {
      sendEvent({
        ...getEventData('customEvent', 'promo codes', 'promo code applied', promoCode),
        promoCode,
      });
    }
  });

  BC.subscribe('coupon.code.failed', (promoCode) => {
    if (promoCode) {
      sendEvent({
        ...getEventData('customEvent', 'promo codes', 'promo code failed', promoCode),
        promoCode,
      });
    }
  });
}

function fitGuideEvents() {
  const productId = getParameterByName('productId');

  if (productId) {
    sendEvent({
      ...getEventData('customEvent', 'product page', 'fit guide start', productId),
    });

    BC.subscribe('fitcal.step.change', (step) => {
      sendEvent({
        ...getEventData('customEvent', 'product page', `fit guide ${step}`, productId),
      });
    });
  }
}

function pdpEvents() {
  const customEvents = [
    ['add to wishlist', 'add-to-wishlist'],
    ['size chart click', 'gtm.size.chart.shown'],
    ['review jumplink', 'gtm.reviews.impression'],
    ['Submited Review', 'gtm.reviews.submit.click'],
  ];

  customEvents.forEach(([action, topic]) => {
    setProductIdEvent({
      action, category: 'product page', event: 'customEvent', topic,
    });
  });

  BC.subscribe('bc.addtocart.success', (productSku) => {
    if (productSku) {
      sendEvent({
        ...getEventData('customEvent', 'product page', 'size/color selection', productSku),
      });
    }
  });

  BC.subscribe('fit.guide.start', (productId) => {
    if (productId) {
      sendEvent({
        ...getEventData('customEvent', 'product page', 'fit guide start', productId),
      });
    }
  });

  BC.subscribe('fit.guide.submit', (productId) => {
    if (productId) {
      sendEvent({
        ...getEventData('customEvent', 'product page', 'fit guide submit', productId),
      });
    }
  });

  // PDP View
  sendEvent({
    event: 'productView',
    ecommerce: getEcommerceObject(getProductListFromProduct(BC.product), 'detail'),
  });
}

function plpEvents() {
  const filters = Array.from(document.querySelectorAll('.facet-list__item'));
  const sortItems = Array.from(document.querySelectorAll('.js-tabdropdown-option, .js-sorting-drawer a'));
  const trackClick = (action, label) => {
    if (label) {
      sendEvent({
        ...getEventData('customEvent', 'category pages', action, label),
      });
    }
  };

  const trackFilter = event => event && event.target && trackClick('applied filter', event.target.dataset.eventLabel);
  const trackSort = event => event && event.target && trackClick('applied sort', event.target.textContent);

  filters.forEach((filter) => {
    filter.removeEventListener('click', trackFilter); // Avoiding extra track on content refresh.
    filter.addEventListener('click', trackFilter);
  });

  sortItems.forEach((sortItem) => {
    sortItem.removeEventListener('click', trackSort); // Avoiding extra track on content refresh.
    sortItem.addEventListener('click', trackSort);
  });

  const products = Array.from(document.querySelectorAll('.js-product-listing'));

  const trackClickPLI = (product) => {
    const { dataset: productDataset } = product;

    const productLinkElem = product.querySelector('a');

    const productData = {
      name: `${productDataset.brandName} ${productDataset.productName}`,
      id: productDataset.productId,
      quantity: 1,
      price: productDataset.price,
      category: null,
      brand: productDataset.brandName,
      variant: productDataset.sku || productDataset.productId,
    };

    sendProductClickEvent(productData, productLinkElem.href);
  };

  products.forEach((product) => {
    product.removeEventListener('click', trackClickPLI);
    product.addEventListener('click', () => trackClickPLI(product));
  });

  BC.subscribe('ajaxtagreload', () => {
    sendEvent({
      event: 'thirdPartyEvent',
      eventCategory: 'mercent',
      eventAction: 'mercent - plp tracking',
    });
  });
}

function setProductIdEvent({
  action, category, event, topic,
}) {
  BC.subscribe(topic, (productId) => {
    if (productId) {
      sendEvent({
        ...getEventData(event, category, action, productId),
      });
    }
  });
}

function receiptEvents() {
  const order = BC.order.attributes;
  const revenue = `${removeCurrencyFormat(order.orderSubTotal)}`;
  const shipping = order.shipping === 'FREE!' ? 0 : $('.js-order-shipping').text().trim();
  const actionField = {
    id: order.orderId,
    affiliation: BC.site.domain,
    revenue,
    tax: `${removeCurrencyFormat(BC.receipt.taxAmount)}`,
    shipping: `${removeCurrencyFormat(shipping)}`,
  };

  if (has('sendcostinorder')) {
    actionField.value = getOrderMargin(order.commerceItems, order.orderTotal, shipping, BC.receipt.taxAmount);
  }

  const productLabels = order.commerceItems.map(product => product.productId);

  productLabels.push(order.orderSubTotal);

  if (BC.receipt.couponCode) {
    actionField.coupon = BC.receipt.couponCode;
  }
  const checkoutLoyaltySelections = JSON.parse(sessionStorage.getItem('checkoutLoyaltyTracking')) || {};

  const shippingOption = $('#shipping-method').text().trim();

  const orderItemsExtraData = order.commerceItems.reduce(
    (result, item) => {
      return {
        ...result,
        [item.sku]: {
          skuDisplayName: item.skuDisplayName,
          productUrl: item.productUrl,
          image: item.imageUrl,
        },
      };
    },
    {}
  );

  sendEvent({
    event: 'purchase',
    ...checkoutLoyaltySelections,
    klarnaOption: order.paymentMethod === 'klarna' ? order.klarnaPaymentMethod : null,
    shippingOption,
    ecommerce: getEcommerceObject(getProductListFromOrder(BC.order.attributes.commerceItems), 'purchase', actionField),
    custom: {
      shippingAddress: order.shippingAddress,
      billingAddress: order.billingAddress,
      email: $('#shipping-email').text(),
      stateName: $('#shipping-state-name').text(),
      orderItemsExtraData,
      orderTotal: `${removeCurrencyFormat(order.orderTotal)}`,
      promotions: order.promotions,
      isRoktEnabled: has('isroktenabled'),
      orderDiscount: `${removeCurrencyFormat(BC.order.attributes.promotionSavings)}`,
    },
  });

  sessionStorage.removeItem('checkoutLoyaltyTracking');

  $('.change-pass-btn').click(() => {
    const isLoyaltyMember = $('.new-account-member').length > 0;
    const action = isLoyaltyMember ? 'loyalty member' : 'loyalty non member';

    sendEvent({
      ...getEventData('customEvent', 'create password', action, 'receipt page'),
    });
  });
}

export {
  setDataLayer,
  globalEvents,
  checkoutEvents,
  cartEvents,
  fitGuideEvents,
  pdpEvents,
  plpEvents,
  receiptEvents,
};
