import get from 'lodash/get';
import { htmlCharEscape, removeCurrencyFormat } from 'common/util/string-utils';
import { getParameterByName } from 'common/util/bc.url.utils';
import { runOnDOMIntersection } from 'common/util/intersection-observer-helper';

function sendEvent(eventData) {
  if (has('gtm')) {
    dataLayer.push(eventData);
  }
}

function getEventData(type, category, action, label) {
  const event = {
    event: type,
    eventAction: action,
  };

  if (category !== undefined && category !== null) {
    event.eventCategory = category;
  }

  if (label !== undefined && label !== null) {
    event.eventLabel = label;
  }

  return event;
}

function getEcommerceObject(products, type, actionField) {
  const ecommerce = {
    [type]: {
      products,
    },
  };

  if (type === 'add' || type === 'purchase') {
    ecommerce.currencyCode = 'USD';
  }

  if (actionField) {
    ecommerce[type].actionField = actionField;
  }

  return ecommerce;
}

function getProductListFromOrder(items) {
  const productList = [];

  items.forEach((item) => {
    productList.push(buildProductObjectFromOrder(item));
  });

  return productList;
}

function getProductListFromProduct(product, extraData) {
  return [buildProductObjectFromProductPage(product, extraData)];
}

function buildProductObjectFromOrder(item) {
  const price = item.detailedUnitPrice || item.salePrice || item.listPrice || item.totalPrice;

  const product = {
    name: htmlCharEscape(item.itemName),
    id: item.productId,
    quantity: item.quantity,
    price: `${removeCurrencyFormat(price)}`,
    category: htmlCharEscape(item.categoryName),
    brand: htmlCharEscape(item.brand),
    variant: item.sku,
  };

  if (item.promotionId) {
    product.coupon = item.promotionId;
  }

  if (item.isGearheadPick) {
    product.dimension6 = item.isGearheadPick;
  }

  if (item.isExclusive) {
    product.dimension38 = item.isExclusive;
  }

  return product;
}

function buildProductObjectFromProductPage(product, extraData) {
  const data = {
    name: `${product.brand.displayName} ${product.displayName}`,
    id: product.id,
    price: product.lowSalePrice ? `${removeCurrencyFormat(product.lowSalePrice)}` : `${removeCurrencyFormat(product.lowListPrice)}`,
    category: product.category && product.category.displayName,
    brand: product.brand && product.brand.displayName,
    variant: product.sku,
    ...extraData,
  };

  if (product.isGearheadPick) {
    data.dimension6 = product.isGearheadPick;
  }

  if (product.isExclusive) {
    data.dimension38 = product.isExclusive;
  }

  return data;
}

function buildProductObjectFromProduct(product) {
  return {
    name: `${product.brand.name} ${product.title}`,
    id: product.id,
    price: product.selectedSku.salePrice ? `${removeCurrencyFormat(product.selectedSku.salePrice)}` : `${removeCurrencyFormat(product.selectedSku.listPrice)}`,
    brand: product.brand && product.brand.name,
    variant: product.selectedSku.id,
    dimension6: product.isGearHeadPick,
    dimension38: product.isExclusive,
  };
}

function buildProductObjectFromNode(node) {
  const brand = node.querySelector('.js-pl-brand').textContent;
  const title = node.querySelector('.js-pl-title').textContent;
  const price = node.querySelector('.js-pl-price').textContent;
  const id = node.dataset.productId;
  const sku = node.dataset.sku;
  const gearheadpick = node.dataset.gearheadpick;
  const isExclusive = node.dataset.isExclusive;

  const data = {
    name: `${brand} ${title}`,
    id,
    price: `${removeCurrencyFormat(price)}`,
    brand,
  };

  if (sku) {
    data.variant = sku;
  }

  if (gearheadpick) {
    data.dimension6 = gearheadpick === 'true';
  }

  if (isExclusive) {
    data.dimension38 = isExclusive === 'true';
  }

  return data;
}

function getCheckoutAdditionalTrackingParams(step) {
  if (step === 1) {
    const checkoutLoyaltyCouponStep1Tracking = JSON.parse(sessionStorage.getItem('cartLoyaltyCouponTracking')) || {};

    const shippingOption = $('input[name=shippingMethod]:checked').data('display-name');

    return {
      ...checkoutLoyaltyCouponStep1Tracking,
      shippingOption,
    };
  }

  return {};
}

function sendCheckoutStepEvent(actionField) {
  sendEvent({
    event: 'checkout',
    ecommerce: getEcommerceObject(getProductListFromOrder(BC.order.attributes.commerceItems), 'checkout', actionField),
    ...getCheckoutAdditionalTrackingParams(actionField.step),
  });
}

function sendAddToCartEvent(product) {
  sendEvent({
    event: 'addToCart',
    ecommerce: getEcommerceObject(getProductListFromOrder([product]), 'add'),
  });
}

function setValuesPromoEvent(target, idParam, locationParam) {
  const url = target.href || target.querySelector('a') && target.querySelector('a').href;
  const promoData = getPromoDataFromUrl(url, idParam, locationParam);
  let promotion_id = promoData.promotion_id;
  let promotion_name = promoData.promotion_name;
  let creative_name = promoData.creative_name;
  let creative_slot = promoData.creative_slot;

  const searchTitle = BC.page.id === 'search-results' ? document.querySelector('.plp-head__title') : null;
  const elemArr = [
    target.querySelector('.promo-text'),
    target.querySelector('.plp-gridad__cta-title'),
    target.querySelector('.home-carousel-slide-cta__title'),
    target.querySelector('.home-carousel-slide-cta__subtitle'),
    target.querySelector('._coupon-content-module__title'),
    target.querySelector('.targeter__title'),
    target.querySelector('.global-text'),
    target.querySelector('.hero-tile__title'),
    searchTitle,
    target.querySelector('a'),
  ];
  const nameElem = elemArr.find(elem => (![undefined, null].includes(elem) && elem.textContent !== ''));

  promotion_name = nameElem ? nameElem.textContent : null;

  if (!creative_slot) {
    creative_slot = 1;
  }

  if (!creative_name && target.dataset.creative) {
    creative_name = target.dataset.creative;
  }
  const isGlobalBanner = nameElem ? nameElem.classList.contains('global-text') : false;

  if (!promotion_id) {
    const idFromUrl = getParameterByName(idParam, window.location.href);

    promotion_id = idFromUrl;
  }

  if (BC.site.code === 'steepcheap' && isGlobalBanner) {
    const idFromUrl = getParameterByName(locationParam, url);

    promotion_id = idFromUrl;
  }

  return {
    ...(promotion_id && { promotion_id }),
    ...(promotion_name && { promotion_name }),
    ...(creative_name && { creative_name }),
    ...(creative_slot && { creative_slot: `${creative_slot}` }),
  };
}

function sendRemoveFromCartEvent(product) {
  sendEvent({
    event: 'removeFromCart',
    ecommerce: getEcommerceObject(getProductListFromOrder([product]), 'remove'),
  });
}

function setPromotionEvents(idParam, locationParam) {
  // Promo impressions
  const promos = document.querySelectorAll('.js-promo-impression');

  promos.forEach((element) => {
    runOnDOMIntersection({
      element,
      proximity: 0,
    }).then((target) => {
      const promoData = setValuesPromoEvent(target, idParam, locationParam);

      sendPromoEvent('bucket_view_promotion_list', [promoData]);
    });
  });

  const promoWraps = document.querySelectorAll('.js-promo-impression-wrap');

  promoWraps.forEach((element) => {
    runOnDOMIntersection({
      element,
      proximity: 0,
    }).then((target) => {
      const promoNodes = target.querySelectorAll('a');

      promoNodes.forEach((node, index) => {
        let nameParam = idParam;

        if (BC.site.code === 'steepcheap') {
          nameParam = locationParam;
        }
        const url = node.href;
        const title = node.title;
        const nodeId = url && getParameterByName(nameParam, url);
        const creativeName = target.dataset.promoList;

        const promoData = {
          ...(nodeId && { promotion_id: nodeId }),
          ...(title && { promotion_name: title }),
          ...(creativeName && { creative_name: creativeName }),
          creative_slot: `${index + 1}`,
        };

        sendPromoEvent('bucket_view_promotion_list', [promoData]);
        node.addEventListener('click', () => {
          sendPromoEvent('select_promotion', [promoData]);
        });
      });
    });
  });

  // Promo click
  promos.forEach((promoItem) => {
    promoItem.addEventListener('click', (event) => {
      const { target } = event;
      const container = target.closest('.js-promo-impression');

      const promoData = setValuesPromoEvent(container, idParam, locationParam);

      sendPromoEvent('select_promotion', [promoData]);
    }, false);
  });
}

function getPromoDataFromUrl(url, idParam, locationParam) {
  if (!url || !getParameterByName(idParam, url)) return {};

  const id = getParameterByName(idParam, url);
  const location = getParameterByName(locationParam, url);

  return getPromoData(id, location);
}

function getPromoData(promotion_id, location) {
  let creative_name;
  let creative_slot;

  if (location) {
    [creative_name, creative_slot] = getPromoValues(location);
  }

  return {
    ...(promotion_id && { promotion_id }),
    ...(creative_name && { creative_name }),
    ...(creative_slot && { creative_slot: `${creative_slot}` }),
  };
}

function sendPromoEvent(event, promotions) {
  const data = {
    event,
    ecommerce: {
      items: promotions,
    },
  };

  const transformPromotions = promotions => promotions.map(
    ({
      promotion_id: id,
      promotion_name: name,
      creative_slot: position,
      creative_name: creative,
    }) => {
      return {
        ...(id && { id }),
        ...(name && { name }),
        ...(position && { position: `${position}` }),
        ...(creative && { creative }),
      };
    }
  );

  sendEvent(data);

  switch (event) {
    case 'bucket_view_promotion_list':
      sendEvent({
        event: 'promoImpression',
        ecommerce: {
          promoView: {
            promotions: transformPromotions(data.ecommerce.items),
          },
        },
      });
      break;
    case 'select_promotion':
      sendEvent({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: transformPromotions(data.ecommerce.items),
          },
        },
      });
      break;
  }
}

function getPromoValues(value) {
  const location = value.split('_');
  const position = location.splice(location.length - 1)[0];

  return [location.join('_'), position];
}

function sendProductClickEvent(product, productUrl) {
  const trackingParam = getParameterByName('ti', productUrl) || null;

  let list = null;
  let position = 1;

  if (trackingParam) {
    const trackingString = window.atob(trackingParam);

    if (/[A-Z]\:/i.test(trackingString)) {
      [list, position] = getTrackingValuesFromSearchString(trackingString);
    } else if (/[A-Z]\_/i.test(trackingString)) {
      [list, position] = getTrackingValuesFromComponentString(trackingString);
    }
  }

  if (BC.page.type === 'activity') {
    list = 'PLP Activity';
  }

  sendEvent({
    event: 'productClick',
    ecommerce: getEcommerceObject([{ ...product, position }], 'click', { list, action: 'click' }),
  });
}

function getProductListName(code) {
  const productLists = {
    BSWidget: 'Rec Best Sellers',
    SCWidget: 'Rec Same Category',
    NAWidget: 'Rec New Arrivals',
    FP: 'Featured Product',
    OP: 'Other Products',
    PL: 'Product Listing',
    ROOMSLIDER: 'Product Row',
    STL: 'Shop the look',
    'REC-BS': 'Rec Best Sellers',
    'REC-BBS': 'Rec Brand Best Sellers',
    'REC-NA': 'Rec New Arrivals',
    'REC-SLR': 'Recommendations',
    'REC-CN': 'Recommendations',
    'Search Results': 'Search Results',
  };

  return productLists[code] || code;
}

function getTrackingValuesFromComponentString(string) {
  const trackingString = string.split('_');

  return formatProductListValues(trackingString[4], trackingString[6]);
}

function getTrackingValuesFromSearchString(string) {
  const trackingString = string.split(':');

  return formatProductListValues(trackingString[0], trackingString[3]);
}

function formatProductListValues(listValue, positionValue) {
  const list = getProductListName(listValue);
  const position = Number(positionValue) ? Number(positionValue) : 0;

  return [list, position];
}

function sendProductImpression(type, product, url) {
  const trackingParam = getParameterByName('ti', url);
  const trackingString = window.atob(trackingParam);
  let list;
  let position;
  let productData;

  if (type === 'component') {
    productData = buildProductObjectFromProduct(product);
    [list, position] = getTrackingValuesFromComponentString(trackingString);

    if (BC.page.type === 'activity') {
      list = 'PLP Activity';
    }
  } else {
    productData = buildProductObjectFromNode(product);
    [list, position] = getTrackingValuesFromSearchString(trackingString);
  }

  sendEvent({
    event: 'productImpression',
    ecommerce: {
      currencyCode: 'USD',
      impressions: [{ ...productData, list, position }],
    },
  });
}

function sendNullSearchesResult() {
  const term = getParameterByName('q');

  sendEvent({
    ...getEventData('customEvent', 'search', 'null search results', term),
  });
}

function sendLoyaltySignupSuccess() {
  sendEvent({
    ...getEventData('customEvent', 'loyalty program', 'signup success', BC.profile.id),
  });
}

function sendOpenLoyaltyModal() {
  sendEvent({
    ...getEventData('customEvent', 'loyalty program', 'loyalty modal open', BC.user.isLoggedIn ? BC.profile.id : null),
  });
}

function sendLoyaltyModalEvent(eventAction, eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'loyalty20 modal', eventAction, eventLabel),
  });
}

function sendLoyaltyProgramEvent(eventAction, eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'loyalty program', eventAction, eventLabel),
  });
}

function sendNotifyMeWhenIsBackInStockEvent(skuid) {
  sendEvent({
    ...getEventData('customEvent', 'notify me when back in stock', 'submit', skuid),
  });
}

function sendSearchesOrPerformedBrowseWithNumResult(numResult) {
  const term = getParameterByName('q');

  let category = 'browse';
  let action = 'browsed to PLP';

  if (term && BC.page.type === 'search') {
    category = 'search';
    action = 'performed search';
  }

  sendEvent({
    ...getEventData('customEvent', category, action, term),
    numResults: numResult,
  });
}

function sendKlarnaApprovedOrDeclinedEvent(approvedOrDeclined) {
  sendEvent({
    ...getEventData('customEvent', 'klarna', 'approve/decline', approvedOrDeclined),
  });
}

function sendNTFBannerClose(bannerTitle) {
  sendEvent({
    ...getEventData('customEvent', 'ntf modal', 'closed modal', bannerTitle),
  });
}

function sendNTFBannerSubmitEvent(action, label, options = {}) {
  sendEvent({
    ...getEventData('customEvent', 'ntf modal', action, label),
    ...options,
  });
}

function sendOpenFlyoutEvent(title) {
  sendEvent({
    ...getEventData('customEvent', 'header nav', 'hover', title),
  });
}

function sendFitGuideEvent(action, label) {
  sendEvent({
    ...getEventData('customEvent', 'bike fit guide', action, label),
  });
}

function sendWishlistItemClickEvent(sku) {
  sendEvent({
    ...getEventData('customEvent', 'wishlist', 'product click', sku),
  });
}

function sendMyAccountMyProfileEditProfileEvent(eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'account', 'edit profile', eventLabel),
  });
}

function sendMyAccountMyProfileUpdateErrorEvent(error) {
  sendMyAccountMyProfileEditProfileEvent(`error|${error}`);
}

const updateCheckoutLoyaltyTracking = (trackingData) => {
  const currentData = JSON.parse(sessionStorage.getItem('checkoutLoyaltyTracking'));

  const newData = {
    ...currentData,
    ...trackingData,
  };

  sessionStorage.setItem('checkoutLoyaltyTracking', JSON.stringify(newData));
};

function sendMyAccountCommunicationPreferencesEvent(eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'account', 'comm prefs page', eventLabel),
  });
}

function buildCommunicationPreferencesChangeEventLabel(subscriptionChanges) {
  return subscriptionChanges
    .map(({ name, status, changed }) => {
      const preferenceName = get(
        {
          PromotionalEmail: 'emailpromo',
          GhxEmail: 'emailgh',
          PromotionalSms: 'text',
          GhxPhone: 'phone',
          PromotionalDirectMail: 'direct',
        },
        name
      );

      return `${preferenceName}-${changed ? '' : 'nochange-'}${
        status ? 'on' : 'off'
      }`;
    })
    .join('|');
}

function sendMyAccountCommunicationPreferencesChangeSuccessfulEvent(
  subscriptionChanges
) {
  sendMyAccountCommunicationPreferencesEvent(
    buildCommunicationPreferencesChangeEventLabel(subscriptionChanges)
  );
}

function sendMyAccountCommunicationPreferencesErrorEvent(errorDisplayed) {
  sendMyAccountCommunicationPreferencesEvent(`error|${errorDisplayed}`);
}

function sendMyAccountEvent(eventAction, eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'account', eventAction, eventLabel),
  });
}

function sendMyAccountOrderHistoryEvent(eventLabel) {
  sendEvent({
    ...getEventData('customEvent', 'account', 'order history page', eventLabel),
  });
}

function sendMyAccountOrderHistoryErrorEvent(errorDisplayed) {
  sendMyAccountOrderHistoryEvent(`return-error|${errorDisplayed}`);
}

function sendGhxSmsNumberClickEvent() {
  sendEvent({
    ...getEventData(
      'customEvent',
      'sms modal',
      'sms click',
      'contactGearhead.orCallGhPhoneNumber'
    ),
  });
}

function getOrderMargin(commerceItems, orderTotal, shipping, tax) {
  const totalCost = commerceItems.reduce((accumulator, { cost = 0 }) => accumulator + Number(cost), 0);
  const margin = removeCurrencyFormat(orderTotal) - totalCost - removeCurrencyFormat(shipping) - removeCurrencyFormat(tax);

  return Number(margin.toFixed(2));
}

function sendCheckoutAccountCreationEvent(success) {
  const eventAction = success ? 'Account Creation Success' : 'Account Creation Failure';
  const eventLabel = success ? '' : 'server error';

  sendEvent({
    ...getEventData(
      'customEvent',
      'Account',
      eventAction,
      eventLabel,
    ),
  });
}

function sendCheckoutLoyaltySignUpEvent(success) {
  const eventAction = success ? 'Loyalty Account Creation Success' : 'Loyalty Account Creation Failure';
  const eventLabel = success ? '' : 'server error';

  sendEvent({
    ...getEventData(
      'customEvent',
      'Account',
      eventAction,
      eventLabel,
    ),
  });
}

export {
  getEcommerceObject,
  getProductListFromOrder,
  getProductListFromProduct,
  getEventData,
  getPromoData,
  getTrackingValuesFromComponentString,
  getTrackingValuesFromSearchString,
  getOrderMargin,
  sendEvent,
  sendCheckoutStepEvent,
  sendAddToCartEvent,
  sendRemoveFromCartEvent,
  sendProductImpression,
  setPromotionEvents,
  sendPromoEvent,
  sendNullSearchesResult,
  sendLoyaltySignupSuccess,
  sendOpenLoyaltyModal,
  sendLoyaltyProgramEvent,
  sendNotifyMeWhenIsBackInStockEvent,
  sendSearchesOrPerformedBrowseWithNumResult,
  sendKlarnaApprovedOrDeclinedEvent,
  sendNTFBannerClose,
  sendNTFBannerSubmitEvent,
  sendOpenFlyoutEvent,
  sendFitGuideEvent,
  sendProductClickEvent,
  sendWishlistItemClickEvent,
  updateCheckoutLoyaltyTracking,
  sendLoyaltyModalEvent,
  sendMyAccountMyProfileUpdateErrorEvent,
  sendMyAccountMyProfileEditProfileEvent,
  sendMyAccountCommunicationPreferencesEvent,
  sendMyAccountCommunicationPreferencesChangeSuccessfulEvent,
  sendMyAccountCommunicationPreferencesErrorEvent,
  sendMyAccountEvent,
  sendMyAccountOrderHistoryEvent,
  sendMyAccountOrderHistoryErrorEvent,
  sendGhxSmsNumberClickEvent,
  sendCheckoutAccountCreationEvent,
  sendCheckoutLoyaltySignUpEvent,
};
