define(function(require) {
  var { extend } = require('underscore');

  function doJsonRequest(opts, file) {
    var jsonOpts = {
      contentType: 'application/json',
    };

    return doRequest(extend(jsonOpts, opts), file);
  }

  function doRequest(opts, file) {
    var request = $.ajax(opts).fail(function(jqXHR, textStatus, errorThrown) {
      var responseJson = jqXHR.responseJSON || {};

      $.error('textStatus: ' + textStatus + ' errorThrown: ' + errorThrown +
                ' serviceUrl: ' + opts.url + ' readyState: ' + jqXHR.readyState +
                ' status: ' + jqXHR.status + ' responseText: ' + jqXHR.responseText +
                ' responseJSON: ' + JSON.stringify(responseJson), file);
    });

    if (opts.checkForRedirect) {
      request.then(function(jqXHR, textStatus, response) {
        var redirectUrl = response && response.getResponseHeader && response.getResponseHeader('X-Response-Uri');

        if (redirectUrl) {
          window.location.assign(redirectUrl);
        } else {
          return new $.Deferred().resolve(jqXHR, textStatus, response);
        }
      });
    }

    return request;
  }

  return {
    doJsonRequest,
    doRequest,
  };
});
