import { isEmpty, isObject } from 'underscore';

const DEFAULT_USER = 'unknownUser';

function getBikeBuildData(frameId, buildGroupId) {
  const storedProduct = localStorage.getItem(frameId) || '{}';
  const storedBuildGroup = JSON.parse(storedProduct)[buildGroupId];

  return isObject(storedBuildGroup) ? storedBuildGroup : {};
}

function getRmaData(orderId) {
  const rmaData = sessionStorage.getItem(orderId);

  return rmaData ? JSON.parse(rmaData) : {};
}
function getUserKey() {
  return BC.user.isRegistered ? BC.profile && BC.profile.id : DEFAULT_USER;
}

function getUserData(userKey) {
  const userStoredData = localStorage.getItem(userKey || getUserKey());

  return userStoredData ? JSON.parse(userStoredData) : {};
}

function saveBikeBuildData(frameId, buildGroup) {
  if (!isEmpty(buildGroup)) {
    const storedData = localStorage.getItem(frameId);
    const storedBuildData = storedData ? JSON.parse(storedData) : {};

    storedBuildData[buildGroup.buildGroupId] = buildGroup;
    localStorage.setItem(frameId, JSON.stringify(storedBuildData));
  } else {
    $.error('Error saving bike build on local storage. No build data was provided', 'storage-helper.js');
  }
}

function saveUserData(key, userKey, status = true) {
  const userData = getUserData(userKey);

  userData[key] = status;
  localStorage.setItem(userKey || getUserKey(), JSON.stringify(userData));
}

function saveRmaData(orderId, rmaData) {
  if (!isEmpty(rmaData)) {
    sessionStorage.setItem(orderId, JSON.stringify(rmaData));
  } else {
    $.error(`saveRmaData failed, Order ID ${orderId}`, 'storage-helper.js');
  }
}

const getLocalStorageInfo = (storageKey) => {
  const storedData = localStorage.getItem(storageKey);

  if (storedData !== null) {
    try {
      const jsonData = JSON.parse(storedData);
      const expires = jsonData.expires;

      if (expires && expires > new Date().getTime()) {
        return jsonData;
      }
    } catch (exception) {
      $.error(`clearing local storage for key ${storageKey}`);
    }
    localStorage.removeItem(storageKey);

    return null;
  }

  return null;
};

const getCachedInfo = (storageKey, retrieveInfo, defaultValue, useCache = true) => {
  const storedData = useCache ? getLocalStorageInfo(storageKey) : null;

  if (storedData === null) {
    const tomorrow = new Date();

    return retrieveInfo()
      .then((response) => {
        tomorrow.setDate(tomorrow.getDate() + 1);
        response.expires = tomorrow.getTime();
        localStorage.setItem(storageKey, JSON.stringify(response));

        return response;
      })
      .catch(() => {
        tomorrow.setDate(tomorrow.getDate() + 1);
        defaultValue.expires = tomorrow.getTime();
        localStorage.setItem(storageKey, JSON.stringify(defaultValue));

        return defaultValue;
      });
  } else {
    return Promise.resolve(storedData);
  }
};

export {
  getBikeBuildData,
  getRmaData,
  getUserData,
  getUserKey,
  saveBikeBuildData,
  saveUserData,
  saveRmaData,
  getCachedInfo,
};
