define([], function() {
  var noCurrencyRegex = /[^0-9\.]+/g;

  return {
    // this function is only available in browser env's
    htmlCharEscape(unescapedString) {
      const elem = document.createElement('div');

      elem.innerHTML = unescapedString;

      return elem.innerText;
    },
    capitalize(str) {
      str = str == null ? '' : String(str);

      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    slugify(text) {
      return text
        .toLowerCase()
        .replace(/[^\-a-zA-Z0-9,&\s]+/ig, '')
        .replace(/-/gi, '_')
        .replace(/\s/gi, '-');
    },
    removeCurrencyFormat(currency) {
      if (typeof currency !== 'string') {
        return currency;
      }

      return Number(currency.replace(noCurrencyRegex, ''));
    },
    truncateAndSuffix(opts) {
      var overflowSuffix = opts.suffix || '\u2026';
      var trimmedString;

      if (opts.str.length <= opts.maxLength) {
        return opts.str;
      }

      trimmedString = opts.str.substr(0, opts.maxLength);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));

      return trimmedString + overflowSuffix;
    },
  };
});
