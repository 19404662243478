import { isEmpty } from 'underscore';
import { doJsonRequest } from 'common/util/ajax-helper';
import { pageReload, redirect } from 'common/util/bc.url.utils';

const config = {
  method: 'POST',
  url: '/Store/services/third-party-login/facebook',
};
const opts = {
  cartRedirectUrl: '/Store/cart/cart.jsp',
  defaultLoginRedirectUrl: '/Store/account/account.jsp',
  status: {
    CONNECTED: 'connected',
    NOT_AUTHORIZED: 'not_authorized',
    UNKNOWN: 'unknown',
  },
};

function getOnLoadFbLoginStatus(callback) {
  FB.getLoginStatus((response) => {
    if (isEmpty(response)) {
      $.error('[fb-login-helper] response is empty');

      return;
    }

    if (callback) {
      callback(response);

      return;
    }

    const $btnLogin = $('.js-fb-login');

    $btnLogin.removeClass('hidden');
    $btnLogin.on('click', handleSubmit);
  });
}

function handleSubmit() {
  FB.login(function(response) {
    if (response && response.authResponse) {
      handleThirdPartyLogin(response.authResponse.accessToken, response.authResponse.userID)
        .then(response => onSuccessLogin(response));
    }
  }, {
    scope: 'email',
    return_scopes: true,
  });
}

function handleThirdPartyLogin(accessToken, userId) {
  const tokenObj = {
    accessToken,
    userId,
    keepLoggedIn: findKeepLoginValue(),
  };

  return doJsonRequest({
    method: config.method,
    data: JSON.stringify(tokenObj),
    url: config.url,
  }, 'fb-login-helper.js');
}

function findKeepLoginValue() {
  // Need to distinguish between the login modal and form when login.jsp
  const checkBoxSelector = BC.page.id === 'login' && !$('#modal-keep-login').is(':visible') ? '#signin-keep-login' : '#modal-keep-login';

  return !!$(checkBoxSelector).prop('checked');
}

function fbLogout(callback) {
  const logout = (response) => {
    if (response.status === opts.status.CONNECTED && response.authResponse) {
      FB.logout(callback);
    } else {
      callback();
    }
  };

  initFbLogin(logout);
}

function initFbLogin(callback) {
  const alreadyLoaded = document.getElementById('facebook-jssdk');

  const onFbStatusLoaded = () => {
    getOnLoadFbLoginStatus(callback);
  };

  if (alreadyLoaded) {
    onFbStatusLoaded();
  } else {
    loadFbSdk(onFbStatusLoaded);
  }
}

function loadFbSdk(callback) {
  // Run as soon as the SDK has completed loading
  window.fbAsyncInit = function() {
    FB.init({
      appId: BC.site.code === 'bcs' ? '652780828166463' : '2683847018611945',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v12.0',
    });

    callback();
  };

  const js = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];

  js.id = 'facebook-jssdk';
  js.src = '//connect.facebook.net/en_US/sdk.js';
  head.appendChild(js);
}

function onSuccessLogin(response) {
  if (response.success) {
    localStorage.setItem('login_provider', 'facebook');
    const isReloadPage = BC.page.id === 'cart' || BC.page.id === 'checkout';

    isReloadPage ? pageReload() : redirect(opts.defaultLoginRedirectUrl);
  } else {
    BC.publish('show.loginModal.message', response.errors[0].message);
    $.error(`[fb-login-helper] failed login - message: ${response.errors[0].message}`);
  }
}

export { initFbLogin, fbLogout };
