import ajaxHelper from 'common/util/ajax-helper';

// ToDo @jdleiva
// - use api.<site>.com as basePath
// - update Apache config

const config = {
  signUpBasePath: '/api/loyalty/signup',
  memberBasePath: '/api/loyalty/user',
  loyaltyCouponBasePath: '/api/loyalty/coupon',
  couponBasePath: '/api/coupon',
};

const claimCoupon = body => ajaxHelper.doRequest({
  type: 'POST',
  contentType: 'application/json',
  url: `${config.couponBasePath}`,
  data: body,
}, 'common/services/loyalty-membership-service.js');

const claimLoyaltyCoupon = body => ajaxHelper.doRequest({
  type: 'POST',
  contentType: 'application/json',
  url: `${config.loyaltyCouponBasePath}`,
  data: body,
}, 'common/services/loyalty-membership-service.js');

const createLoyaltyUser = body => ajaxHelper.doRequest({
  type: 'POST',
  contentType: 'application/json',
  url: `${config.signUpBasePath}`,
  data: body,
}, 'common/services/loyalty-membership-service.js');

const getLoyaltyUser = (userId, includes) => ajaxHelper.doRequest({
  type: 'GET',
  url: `${config.memberBasePath}/${userId}?includes=${includes}`,
}, 'common/services/loyalty-membership-service.js');

export {
  createLoyaltyUser,
  getLoyaltyUser,
  claimLoyaltyCoupon,
  claimCoupon,
};
