import { useMemo } from 'react';
import { getFeatures, getPLIFeatures, getCartFeatures } from 'common/util/feature-helper';

const predefinedFeatureMap = {
  PLI: getPLIFeatures,
  cart: getCartFeatures,
};

const useFeatures = (features = '') => (
  useMemo(() => {
    let featureList = {};

    if (predefinedFeatureMap[features]) {
      featureList = predefinedFeatureMap[features]();
    } else if (features) {
      featureList = getFeatures(features);
    }

    return featureList;
  }, [features])
);

export default useFeatures;
