import 'jquery-ui/widget';
import { isEmpty } from 'underscore';
import { createAccount } from 'common/util/forms/atgAjaxHelper';
import {
  isValidEmail,
  isValidPassword,
  isValidPasswordConfirmation,
  cleanupFormErrors,
  disableForm,
  populateRecaptchaResponse,
  enableForm,
  handleFormResponse,
  updateUserInformation,
  initRecaptcha,
  showInputError,
  resetForm,
} from 'common/util/auth-modal-helper';
import { createLoyaltyUser } from 'common/services/loyalty-membership-service';
import { updateSubscriptions } from 'common/services/notification-service';
import { sendLoyaltyProgramEvent } from 'common/util/gtm-helper';
import { monthsArray, padStart } from 'common/util/loyalty/loyalty-constants';
import { isEmployeeEmail } from 'common/util/loyalty/sign-up';

$.widget('bc.authModalRegister', {
  options: {
  },
  _create() {
    this.forceRecaptcha = has('forcerecaptchaonregistration');
    this.authSuccessCallback = () => document.location.reload();
    this.recaptchaResponse = null;
    this.recaptchaId = null;
    this.$form = this.element.find('form');
    this.gtmLearnMoreEvent = 'learn more';
    this.gtmJoinNowEvent = 'join now';
    this.gtmEventLabel = 'join loyalty modal';
    this._bindEvents();
    // To reset form in FireFox Browser
    resetForm(this.$form);
  },
  setAuthSuccessCallback(callback) {
    if (callback) {
      this.authSuccessCallback = callback;
    }
  },
  _bindEvents() {
    if (has('loyalty')) {
      this._on(this.element, {
        'change .js-register-birth-month': () => {
          const $birthDayInput = this.$form.find('.js-register-birth-day');
          const $birthMonthInput = this.$form.find('.js-register-birth-month');

          $birthDayInput.empty();
          $birthDayInput.append('<option value="">Day</option>');
          $birthDayInput.value = '';
          if ($birthMonthInput.val()) {
            const birthDaysOptions = monthsArray[parseInt($birthMonthInput.val(), 10) - 1].dates.map(date => `<option value="${padStart(String(date), 2, '0')}">${date}</option>)`);

            birthDaysOptions.forEach(birthDayOption => $birthDayInput.append(birthDayOption));
          }
        },
        'click .js-auth-register-link': (e) => {
          e.preventDefault();
          sendLoyaltyProgramEvent(this.gtmLearnMoreEvent, this.gtmEventLabel);
          window.location.href = e.target.href;
        },
        'input .js-register-user': () => {
          const $emailInput = this.$form.find('.js-register-user');

          if (isEmployeeEmail($emailInput.val())) {
            this.element.find('.alert-box-wrapper').removeClass('hidden');
          } else {
            this.element.find('.alert-box-wrapper').addClass('hidden');
          }
        },
      });
    }
    this._on(this.$form, {
      submit: '_handleSubmit',
    });
    this._on(this.element, {
      'click .js-register-modal-general-error-close': () => {
        this._hideGeneralError();
        BC.publish('bc.authmodal.refocus', '.js-register-first-name');
      },
    });
  },
  _hideGeneralError() {
    if (has('loyalty')) {
      this.element.find('.js-register-modal-invalid-form-error').hide();
    } else {
      this.element.find('.js-register-modal-general-error').hide();
    }
  },
  _handleLoyaltyAccount() {
    const $firstNameInput = this.$form.find('.js-register-first-name');
    const $lastNameInput = this.$form.find('.js-register-last-name');
    const $emailInput = this.$form.find('.js-register-user');
    const $birthMonthInput = this.$form.find('.js-register-birth-month');
    const $birthDayInput = this.$form.find('.js-register-birth-day');
    const $smsSubscription = this.$form.find('.js-auth-agree-sms');
    const birthMonth = $birthMonthInput.val();
    const birthDay = $birthDayInput.val();
    const email = $emailInput.val();

    if (!isEmployeeEmail(email)) {
      // TODO:
      // validate with the Loyalty team what the expected message is if the API is down or
      // return an error during membership creation
      createLoyaltyUser(JSON.stringify({
        id: BC.profile.id,
        email: $emailInput.val(),
        birth_date: birthMonth && birthDay ? `${birthMonth}-${birthDay}` : undefined,
        first_name: $firstNameInput.val(),
        last_name: $lastNameInput.val(),
        enrollment_site: BC.site.code,
        enrollment_source: 'web',
      })).always(() => {
        if ($smsSubscription.prop('checked')) {
          // TODO: GHX API expects a connection with a GH in order to implement the SMS Subscription
          updateSubscriptions([{ subscriptionType: 'GhxPhone', status: true }], undefined).always(this.authSuccessCallback);
        } else {
          this.authSuccessCallback();
        }
      });
    } else {
      this.authSuccessCallback();
    }
  },
  _resetRecaptchaValue() {
    this.recaptchaResponse = '';
  },
  _handleSubmit(event) {
    const $firstNameInput = this.$form.find('.js-register-first-name');
    const $lastNameInput = this.$form.find('.js-register-last-name');
    const $emailInput = this.$form.find('.js-register-user');
    const $passInput = this.$form.find('.js-register-password');
    const $confirmPassInput = this.$form.find('.js-register-password-confirm');
    const $birthMonthInput = this.$form.find('.js-register-birth-month');
    const $birthDayInput = this.$form.find('.js-register-birth-day');

    event.preventDefault();
    this._hideGeneralError();
    this._hideInvalidFormMsj();
    cleanupFormErrors(this.$form);
    const isClientSideValidForm = this._validateForm($firstNameInput, $lastNameInput, $emailInput, $passInput, $confirmPassInput);

    if (isClientSideValidForm) {
      const firstName = has('loyalty') ? $firstNameInput.val().trim() : undefined;
      const lastName = has('loyalty') ? $lastNameInput.val().trim() : undefined;
      const fullName = `${$firstNameInput.val().trim()} ${$lastNameInput.val().trim()}`;
      const birthDate = has('loyalty') && $birthDayInput.val() ? `${$birthMonthInput.val()}-${$birthDayInput.val()}` : undefined;

      if (has('loyalty')) {
        sendLoyaltyProgramEvent(this.gtmJoinNowEvent, this.gtmEventLabel);
      }

      const formInfo = createAccount(
        fullName,
        firstName,
        lastName,
        $emailInput.val(),
        $passInput.val(),
        $confirmPassInput.val(),
        birthDate,
      );

      BC.publish('ScSignIn.sitecatalystutil', 'N');

      cleanupFormErrors(this.$form);
      disableForm(this.$form);
      populateRecaptchaResponse(formInfo, this.recaptchaResponse);

      handleFormResponse(
        formInfo,
        this._handleFormSuccess.bind(this),
        this._handleFormErrors.bind(this),
        () => enableForm(this.$form),
      );
    } else if (has('loyalty')) {
      this._showInvalidFormMsj();
    }
  },
  _validateForm($firstNameInput, $lastNameInput, $emailInput, $passInput, $confirmPassInput) {
    let isFormValid = true;
    const validFirstName = has('loyalty') ? !isEmpty($firstNameInput.val()) : true;
    const validLastName = has('loyalty') ? !isEmpty($lastNameInput.val()) : true;
    const validEmail = isValidEmail($emailInput.val());
    const validPass = isValidPassword($passInput.val());
    const validRetype = isValidPasswordConfirmation($passInput.val(), $confirmPassInput.val());

    if (!validFirstName) {
      showInputError($firstNameInput);
      isFormValid = false;
    }
    if (!validLastName) {
      showInputError($lastNameInput);
      isFormValid = false;
    }
    if (!validEmail) {
      showInputError($emailInput);
      isFormValid = false;
    }

    if (!validPass) {
      showInputError($passInput);
      isFormValid = false;
    }

    if (!validRetype) {
      showInputError($confirmPassInput);
      isFormValid = false;
    }

    BC.publish('bc.authmodal.refocus', '[aria-invalid=true]');

    return isFormValid;
  },
  _showInvalidFormMsj() {
    this.element.find('.js-register-modal-invalid-form-error').show();
    this.element.find('.js-register-modal-invalid-form-error-wrap-text').show();
  },
  _hideInvalidFormMsj() {
    this.element.find('.js-register-modal-invalid-form-error').hide();
    this.element.find('.js-register-modal-invalid-form-error-wrap-text').hide();
  },
  _toggleSubmitBtnLoading() {
    this.element.find('.js-signing-in-msg').toggleClass('hidden');
  },
  _handleFormSuccess(response) {
    this._toggleSubmitBtnLoading();
    updateUserInformation();

    BC.publish('bc.authmodal.close');
    BC.publish('account:just-loggedIn');
    BC.publish('account:created');
    BC.pub('login-modal.close', response.success);
    $(document).trigger('userAuthenticated');

    if (has('loyalty')) {
      this._handleLoyaltyAccount();
    } else {
      this.authSuccessCallback();
    }
  },
  _handleFormErrors(response) {
    this._toggleSubmitBtnLoading();
    BC.publish('ScLoginModalUserError.sitecatalystutil');

    initRecaptcha({
      selector: '#recaptcha-modal-register-wrap',
      forced: this.forceRecaptcha,
      $form: this.$form,
      recaptchaId: this.recaptchaId,
      recaptchaResponse: this.recaptchaResponse,
      recaptchaIdCallback: recaptchaId => this.recaptchaId = recaptchaId,
      recaptchaCallback: response => this.recaptchaResponse = response,
      response,
    });

    this._resetRecaptchaValue();

    this.$form.attr('aria-invalid', true);

    if (response.formExceptions) {
      response.formExceptions.forEach((error) => {
        this._handleGeneralError(error.message);
      });
    }
  },
  _handleGeneralError(customMessage) {
    if (!isEmpty(customMessage)) {
      if (has('loyalty')) {
        this.$form.parent().find('.js-register-modal-invalid-server-error-wrap-text').text(customMessage).show();
        this.$form.parent().find('.js-register-modal-invalid-form-error').show();
        this.$form.attr('aria-describedby', 'register-modal-invalid-form-error');
      } else {
        this.$form.parent().find('.js-register-modal-general-error-text').text(customMessage);
        this.$form.parent().find('.js-register-modal-general-error').show();
        this.$form.attr('aria-describedby', 'register-modal-general-error');
        BC.publish('bc.authmodal.refocus', '.js-register-modal-general-error-close');
      }
    }
  },
  _destroy() {
  },
});
