import Targeter from 'common/models/targeter';
import { getUserData, getUserKey, saveUserData } from 'common/util/storage-helper';
import { inUrl } from 'common/util/bc.url.utils';
import { redeem } from 'common/services/coupon-redemption';
import { getProfile } from 'common/services/user-service';
import {
  claimLoyaltyCoupon as claimLoyaltyCouponService,
  claimCoupon as claimCouponService,
} from 'common/services/loyalty-membership-service';

function loadCouponBanner(couponData) {
  return import(
    /* webpackChunkName: "coupon.banner" */
    'common/util/banner/coupon'
  ).then(({ mount }) => {
    mount(couponData);
  });
}

function loyaltyCouponClaimRequest(email, couponCode) {
  const data = {
    email,
    couponCode,
  };

  return claimLoyaltyCouponService(JSON.stringify(data))
    .then((data) => {
      if (data.success) {
        BC.publish('coupon.code.applied', couponCode);
      }

      return data;
    })
    .fail(() => BC.publish('coupon.code.failed', couponCode));
}

function couponClaimRequest(couponCode) {
  const data = {
    couponCode,
  };

  return claimCouponService(JSON.stringify(data))
    .then((data) => {
      if (data.invalidData) {
        BC.publish('coupon.code.failed', couponCode);
      } else {
        BC.publish('coupon.code.applied', couponCode);
      }

      return data;
    })
    .fail(() => BC.publish('coupon.code.failed', couponCode));
}

function claimCoupon(couponCode, isSingleUseCoupon) {
  return redeem(couponCode)
    .then((data) => {
      if (data.success) {
        BC.publish('coupon.code.applied', couponCode);
        BC.publish('coupon:applied', data.profileId);
        isSingleUseCoupon && saveUserData(couponCode, data.profileId, true);
      }

      return data;
    })
    .fail(() => BC.publish('coupon.code.failed', couponCode));
}

function claimCouponByEmail(couponCode, isSingleUseCoupon) {
  isSingleUseCoupon && saveUserData(couponCode);
}

function rejectCoupon(couponCode, overrideValue = false) {
  if (!getUserData(BC.profile.id)[couponCode]) {
    saveUserData(couponCode, getUserKey(), overrideValue);
  }
}

function startCouponListeners() {
  BC.subscribe('claim-coupon', claimCoupon);
  BC.subscribe('reject-coupon', rejectCoupon);
}

function initCouponTargeter(callback) {
  const couponPromoTargeter = new Targeter({
    targeterName: 'store_global_couponpromotion',
  });

  callback && couponPromoTargeter.on('change', callback);
  couponPromoTargeter.fetch({ cache: true });
}

function initCouponBanner() {
  initCouponTargeter(onBannerDataLoaded);
}

function parseCouponData(model) {
  const couponDataContent = model.get('targeterContent');
  const [targeterFirst = {}] = couponDataContent;
  const { promoJSONContent = {} } = targeterFirst;
  const { couponId, experience, image } = promoJSONContent;
  let couponData = {};

  if (couponId) {
    const isNewUser = !BC.user.isRegistered;
    const isEmailSubs = experience === 'newCustomer';
    const isTransitional = experience === 'transitional';
    const couponBannerParam = inUrl('myCoupon');
    const isCouponApplied = getUserData(BC.profile.id)[couponId];
    const userData = getUserData();
    const isCouponRejected = isNewUser ? _.has(userData, couponId) : _.isBoolean(userData[couponId]) && !userData[couponId];
    const profile = getProfile();

    couponData = {
      claimCoupon,
      claimCouponByEmail,
      couponBannerParam,
      isCouponApplied,
      isCouponRejected,
      isEmailSubs,
      isNewUser,
      isTransitional,
      rejectCoupon,
      image,
      loyaltyCouponClaimRequest,
      couponClaimRequest,
      profile,
      ...promoJSONContent,
    };
  }

  return couponData;
}

function handleOpenBanner(model) {
  const couponData = parseCouponData(model);

  const {
    couponBannerParam,
    couponId,
    isCouponApplied,
    isCouponRejected,
    isEmailSubs,
    isNewUser,
    experience,
  } = couponData;

  const couponBannerHash = window.location.hash === '#myCoupon';
  const forceOpen = couponBannerParam === 'true' || couponBannerHash;

  if (couponId && has('promotion-modal')) {
    BC.subscribe('coupon.banner.open', () => {
      loadCouponBanner(couponData);
    });

    if (forceOpen || experience === 'loyalty' && !isCouponApplied && !isCouponRejected) {
      return loadCouponBanner(couponData);
    }

    if (forceOpen || !(isCouponApplied || isCouponRejected) && ((isEmailSubs && isNewUser) || !isEmailSubs)) {
      return loadCouponBanner(couponData);
    }
  }
}

function onBannerDataLoaded(model) {
  window.addEventListener('hashchange', () => {
    handleOpenBanner(model);
  });

  return handleOpenBanner(model);
}

export {
  claimCoupon,
  claimCouponByEmail,
  initCouponBanner,
  initCouponTargeter,
  loadCouponBanner,
  onBannerDataLoaded,
  parseCouponData,
  rejectCoupon,
  startCouponListeners,
  loyaltyCouponClaimRequest,
  couponClaimRequest,
};
