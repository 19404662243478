define(function(require) {
  var validationHelper = require('common/util/bc.validation.helper');
  var constructorDefaults = require('common/util/constructor-defaults');
  var {
    extend,
    indexOf,
    isArray,
    isUndefined,
    rest,
  } = require('underscore');

  require('backbone.localstorage');
  var Base = Backbone.Model.extend({
    methods: [
      'create',
      'update',
      'delete',
      'read',
    ],
    validations: {},
    constructor(attributes, options) {
      var self = this;

      options || (options = {});
      constructorDefaults(self, 'defaults');
      constructorDefaults(self, 'validations');
      self.options = extend({}, self.options, options);
      constructorDefaults(self, 'options');
      Backbone.Model.call(self, attributes, options);
    },
    parse(resp) {
      return resp.attributes ? resp.attributes : resp;
    },
    // Clears the model and sets all properties to their default values.
    /* TODO: should this really clear the model and just set the defaults,
         * or overwrite defaults and leave any other attribute intact (current behavior)?
         */
    reset(options) {
      return this.set(this.defaults, options);
    },
    sync(method, model, options) {
      // ATG forms that need to be submitted via ajax follow this convention
      var $form = $('#ajaxform-' + method);

      if ($form.length) {
        return BC.sync(method, model, options, $form);
      } else {
        // Fallback to backbone sync. Generally done for `model.fetch()`.
        if (indexOf(this.methods, method) >= 0) {
          return Backbone.sync(method, model, options);
        }

        return false;
      }
    },
    // validate
    // -------------------
    // Validates against an optional `validation` object.
    // validations: { attr: { test: 'RegExp|Function', message: 'i18n.message.key' }}`.
    validate(attributes) {
      var self = this;
      var errors = [];
      var validations;

      _(attributes).each(function(value, attributeName) {
        if (validations = self.validations[attributeName]) {
          isArray(validations) || (validations = [validations]);
          var validationResult = validationHelper.validate(validations, value, self.get('country'));

          if (!isUndefined(validationResult)) {
            errors.push({ name: attributeName, validationTest: validationResult.test, message: $.t(validationResult.message) });
          }
        }
      });

      return errors.length ? errors : null;
    },
    // Wrapper to make calling parent class super methods easier
    // TODO use jQuery UI syntax
    _super(parentClass, funcName) {
      return parentClass.prototype[funcName].apply(this, rest(arguments));
    },
  });

  return Base;
});
