define(function(require) {
  var ajaxHelper = require('common/util/ajax-helper');

  function getEmail() {
    return getProfile().login || '';
  }

  function getId() {
    return getProfile().id;
  }

  function getProfile() {
    return BC.profile || {};
  }

  function getUser() {
    return BC.user || {};
  }

  function isAnonymous() {
    return !getUser().isRegistered;
  }

  function isEmployee() {
    return getEmail().indexOf('@backcountry.com') > -1;
  }

  function isGearhead() {
    return new Promise((resolve) => {
      if (!isAnonymous() && isEmployee()) {
        BC.subscribe('fetch-gearheads-ready', (gearheads) => {
          var gearhead = gearheads.find(gh => gh && gh.userId === getId());

          if (gearhead && gearhead.userId) {
            resolve({ type: 'gearhead' });
          } else {
            resolve({});
          }
        });
      } else {
        resolve({});
      }
    });
  }

  function isLoggedIn() {
    return getUser().isExplicitOrAutoLoggedIn;
  }

  function hasOrderHistory(email, recaptcha) {
    return ajaxHelper.doRequest({
      type: 'POST',
      url: '/Store/services/customer/orders/placed',
      data: {
        email,
        recaptchaResponse: recaptcha,
      },
    }, 'common/services/user-service.js');
  }

  function updateBirthDate({ userId, birthDate }) {
    return ajaxHelper.doRequest({
      type: 'PUT',
      contentType: 'application/json',
      url: `/Store/services/profile/${userId}`,
      data: JSON.stringify({
        birthdate: birthDate,
      }),
    }, 'common/services/user-service.js');
  }

  function registerCustomer(data) {
    return ajaxHelper.doRequest({
      type: 'POST',
      url: '/rest/atg/userprofiling/ProfileActor/create',
      dataType: 'json',
      data,
    }, 'common/services/user-service.js');
  }

  return {
    getEmail,
    getId,
    getProfile,
    getUser,
    hasOrderHistory,
    isAnonymous,
    isEmployee,
    isGearhead,
    isLoggedIn,
    updateBirthDate,
    registerCustomer,
  };
});
