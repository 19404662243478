/*
 * Components are tracked using many values encoded
 * into a single eVar. To keep the tracking code compact, abreviations are used
 * that don't always match the elements of the page.
 *
 * The purpose of this module is to keep the components agnostic of the
 * tracking code encoding and keep the tracking API minimal.
 */

define(function(require, exports, module) {
  const {
    extend,
    each,
    isEmpty,
    reduce,
    identity,
    compact,
  } = require('underscore');
  const urlUtils = require('common/util/bc.url.utils');
  const RECOMMENDATION_TYPES = require('common/services/recommendations').RECOMMENDATION_TYPES;

  const DASH = '-';
  const EMPTY = '';
  const KEYS = [
    'pagePrefix', // there is a default, but a custom parameter is used if available
    'pageName', // BC.page.type is concatenated if available
    'tabId', // tabName is concatenated if available
    'pageSection',
    'code',
    'elementIndex',
    'campaignId',
  ];

  const NA = 'NA';
  const PROMO_PARAM_KEY = 'INT_ID';
  const TI_PARAM_KEY = 'ti';
  const TRACKING_DEFAULTS = {
    pagePrefix: 'AP',
  };

  const SEPARATOR = '_';
  const WHITE_REGEX = /\s/g;
  const SPECIAL_CHARS = /[^a-zA-Z0-9|_-]+/g;

  // TODO move to a single map, no need to declare actions by component
  const ACTION_MAP = {
    banner: {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    'brand-logos': {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    'content-feed': {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    'content-list': {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    explore: {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    'featured-content': {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    gps: {
      // TODO check with product if this should be tracked, not being used right now
      clickImage(opts) {
        opts.elementIndex = opts.productId;

        return opts;
      },
      otherProduct(opts) {
        opts.elementIndex = 'Rec-' + opts.productId;

        return opts;
      },
      pdp(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;

        return opts;
      },
      productSelect(opts) {
        opts.elementIndex = 'G-' + (opts.data.productIndex + 1) + DASH + opts.data.productId;

        return opts;
      },
      shopAll(opts) {
        opts.elementIndex = 'CTA-SA';

        return opts;
      },
      subtabSelect(opts) {
        opts.elementIndex = 'HN-' + (opts.data.subtabIndex + 1);

        return opts;
      },
      tabSelect(opts) {
        opts.elementIndex = 'G-' + opts.data.tabName.substring(0, 1);

        return opts;
      },
    },
    'featured-gearhead': {
      pdp(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;

        return opts;
      },
      talkToGearhead(opts) {
        opts.elementIndex = 'CTA-TT';

        return opts;
      },
    },
    body: {
      cta(opts) {
        opts.elementIndex = 'CTA-FF';

        return opts;
      },
    },
    'featured-product': {
      pdp(opts) {
        opts.elementIndex = 'REC-' + opts.productId;

        return opts;
      },
    },
    'flipping-suggestion': {
      click(opts) {
        opts.elementIndex = `CTA-FLIP-${opts.itemIndex}`;

        return opts;
      },
    },
    'image-board': {
      clickImage(opts) {
        opts.elementIndex = 'IMAGE-CLICK';

        return opts;
      },
    },
    IP: { // Init Explore
      exploreLink(opts) {
        opts.elementIndex = 'CTA-EXP';

        return opts;
      },
    },
    'other-products': {
      pdp(opts) {
        opts.elementIndex = 'REC-' + opts.productId;

        return opts;
      },
      showMore(opts) {
        opts.elementIndex = 'CTA-SM';

        return opts;
      },
    },
    'pack-list': {
      pdp(opts) {
        opts.elementIndex = `SKU-${opts.productId}`;

        return opts;
      },
    },
    'product-listing': {
      filters(opts) {
        const operation = opts.op || 'OPEN';

        opts.elementIndex = `FILTER-${operation}`;

        return opts;
      },
      pag(opts) {
        opts.elementIndex = `PAG-${opts.pageIndex.toUpperCase()}`;

        return opts;
      },
      pdp(opts) {
        opts.elementIndex = `SKU-${opts.productId}`;

        return opts;
      },
      sort(opts) {
        const operation = opts.op || 'OPEN';

        opts.elementIndex = `SORT-${operation}`;

        return opts;
      },
    },
    'product-row': {
      pdp(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;
        opts.code = opts.listType || opts.code;

        return opts;
      },
    },
    recommendations: {
      pdp(opts) {
        opts.elementIndex = 'REC-' + opts.productId;
        opts.code = getRecommendationsComponentName(opts.recommendationsType);

        return opts;
      },
      showAll(opts) {
        opts.elementIndex = 'CTA-SA';
        opts.code = getRecommendationsComponentName(opts.recommendationsType);

        return opts;
      },
      showMore(opts) {
        opts.elementIndex = 'CTA-SM';
        opts.code = getRecommendationsComponentName(opts.recommendationsType);

        return opts;
      },
    },
    'room-slider': {
      pdp(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;
        opts.code = opts.listType || opts.code;

        return opts;
      },
      arrowForward(opts) {
        opts.elementIndex = 'CTA-AF';

        return opts;
      },
      arrowBackward(opts) {
        opts.elementIndex = 'CTA-AB';

        return opts;
      },
      dotForward(opts) {
        opts.elementIndex = 'CTA-DF';

        return opts;
      },
      dotBackward(opts) {
        opts.elementIndex = 'CTA-DB';

        return opts;
      },
    },
    rotator: {
      pdp(opts) {
        opts.elementIndex = `SKU-${opts.productId}`;

        return opts;
      },
      arrowForward(opts) {
        opts.elementIndex = 'CTA-AF';

        return opts;
      },
      arrowBackward(opts) {
        opts.elementIndex = 'CTA-AB';

        return opts;
      },
      carouselClick(opts) {
        opts.elementIndex = 'CTA-CAR';

        return opts;
      },
      moreDetails(opts) {
        opts.elementIndex = 'CTA-MD';

        return opts;
      },
      shopAll(opts) {
        opts.elementIndex = 'CTA-SA';

        return opts;
      },
    },
    stl: {
      pdp(opts) {
        opts.elementIndex = `SKU-${opts.productId}`;

        return opts;
      },
      addToCart(opts) {
        opts.elementIndex = `ADD-TO-CART_SKU-${opts.productId}`;

        return opts;
      },
      cta(opts) {
        opts.elementIndex = 'CTA';

        return opts;
      },
      openModal(opts) {
        opts.elementIndex = 'MODAL-OPEN';

        return opts;
      },
      closeModal(opts) {
        opts.elementIndex = 'MODAL-CLOSE';

        return opts;
      },
      promo(opts) {
        opts.elementIndex = 'PROMO';

        return opts;
      },
      social(opts) {
        opts.elementIndex = 'SOCIAL';

        return opts;
      },
    },
    'social-video': {
      play(opts) {
        opts.elementIndex = 'PLAY';

        return opts;
      },
    },
    'tabs-navigation': {
      showMore(opts) {
        opts.elementIndex = 'CTA-SM';

        return opts;
      },
      toggle(opts) {
        opts.elementIndex = 'TOG';

        return opts;
      },
    },
    'top-ten': {
      subtabSelect(opts) {
        opts.elementIndex = 'G-' + opts.data.subtabIndex;

        return opts;
      },
      tabSelect(opts) {
        opts.elementIndex = 'HN-' + opts.data.tabIndex;

        return opts;
      },
      pdp(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;

        return opts;
      },
      // TODO check with product if this should be tracked, not being used right now
      shopSimilar(opts) {
        opts.elementIndex = 'SS-' + opts.productId;

        return opts;
      },
      next(opts) {
        opts.elementIndex = 'SLIDER-' + opts.data.itemIndex + DASH + opts.data.productId;

        return opts;
      },
      prev(opts) {
        opts.elementIndex = 'SLIDER-' + opts.data.itemIndex + DASH + opts.data.productId;

        return opts;
      },
      shopAll(opts) {
        opts.elementIndex = 'CTA-SS';

        return opts;
      },
    },
    'video-story': {
      play(opts) {
        opts.elementIndex = 'PLAY';

        return opts;
      },
    },
    'story-board': {
      cta(opts) {
        opts.code = `${opts.code}-${opts.itemIndex}`;
        opts.elementIndex = 'CTA-NA';

        return opts;
      },
    },
    'visual-nav': {
      click(opts) {
        opts.elementIndex = `CTA-${opts.itemIndex}`;

        return opts;
      },
    },
    'visual-product': {
      play(opts) {
        opts.elementIndex = 'PLAY';

        return opts;
      },
      details(opts) {
        opts.elementIndex = 'DETAILS';

        return opts;
      },
      techSpecs(opts) {
        opts.elementIndex = 'TECH-SPECS';

        return opts;
      },
      redirect(opts) {
        opts.elementIndex = 'SKU-' + opts.productId;

        return opts;
      },
      moreDetails(opts) {
        opts.elementIndex = 'CTA-MD';

        return opts;
      },
    },
  };

  const trackMapping = {
    BLOGO: {
      pub: 'ScBrandLogosLoaded',
    },
    BBL: {
      pub: 'ScTrackBikeBuilderLandingSteps',
    },
    MAIN_NAV: {
      internalBanner: {
        pub: 'ScTrackFlyoutInternalBannerImpressions',
      },
    },
  };

  /*
   * Takes a jQuery collection of anchor tags and appends the tracking code to
   * the href attribute.
   */
  function appendTrackingId(elements, opts) {
    const baseOpts = extend({}, baseOpts, opts);

    each(elements, function(el) {
      const $el = $(el);
      const newUrl = buildFullUrl($el.attr('href'), extend({ productId: $el.data('productid') }, baseOpts));

      $el.attr('href', newUrl);
    });
  }

  function trackEvent(opts) {
    const eventString = getEventString({ ...this, ...opts });

    BC.publish('ScComponentEvent.sitecatalystutil', {
      code: eventString,
      linkNameComponent: opts.linkNameComponent || EMPTY,
    });
  }

  /*
   * Returns a function meant to be called from events like onClick/onLoad etc...
   */
  function buildEventTracker(baseOptions) {
    return trackEvent.bind(baseOptions);
  }

  /*
   * Returns url adding TI and promotion parameters
   */
  function buildFullUrl(url, opts) {
    const eventString = getEventString({ ...this, ...opts });

    if (eventString) {
      return buildTiUrl(buildPromotionUrl(url, opts, eventString), opts, eventString);
    } else {
      return url;
    }
  }

  /*
   * Extend default, base and specific options and transform based on the action map
   */
  function buildPayload(opts) {
    return mapAction(extend({}, TRACKING_DEFAULTS, opts));
  }

  /*
   * Returns url adding promotion parameters
   */
  function buildPromotionUrl(url, opts, event) {
    const eventString = event || getEventString({ ...this, ...opts });

    if (eventString) {
      return urlUtils.addParam(url, PROMO_PARAM_KEY, eventString, true);
    } else {
      return url;
    }
  }

  /*
   * Returns url adding ti parameters
   */
  function buildTiUrl(url, opts, event) {
    const eventString = event || getEventString({ ...this, ...opts });

    if (eventString) {
      return urlUtils.addParam(url, TI_PARAM_KEY, btoa(eventString), true);
    } else {
      return url;
    }
  }

  function getImpressionCodes(opts) {
    const componentCode = this.code || '';

    switch (componentCode) {
      case 'BLOGO':
        return compact(opts.brandsData.map(brand => brand.tracking)).join(',');
      case 'BBL':
        return opts.step;
    }

    return '';
  }

  function trackImpression(data) {
    const componentCode = this.code || '';
    const componentConfig = trackMapping[componentCode];

    if (!isEmpty(componentConfig)) {
      let pubEvent;

      if (data.action) {
        pubEvent = componentConfig[data.action].pub;
      } else {
        pubEvent = componentConfig.pub;
      }

      BC.publish(`${pubEvent}.sitecatalystutil`, data);
    }
  }

  function getRecommendationsComponentName(type) {
    switch (type) {
      case RECOMMENDATION_TYPES.BEST_SELLERS:
        return 'REC-BS';
      case RECOMMENDATION_TYPES.BRAND_BEST_SELLERS:
        return 'REC-BBS';
      case RECOMMENDATION_TYPES.NEW_ARRIVALS:
        return 'REC-NA';
      case RECOMMENDATION_TYPES.SOLR:
        return 'REC-SLR';
      case RECOMMENDATION_TYPES.CONSTRUCTOR:
        return 'REC-CN';
    }
  }

  function _formatDate() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return [
      date.getFullYear(),
      leadingZero(month),
      leadingZero(day),
    ].join(EMPTY);
  }

  function getEventString(opts) {
    let eventString;

    if (isEmpty(opts) || !opts.componentName || !ACTION_MAP[opts.componentName] || !opts.pageName) {
      eventString = EMPTY;
    } else {
      opts = buildPayload(opts);

      if (opts.tabId && opts.tabName) {
        opts.tabId += DASH + opts.tabName;
      } else {
        opts.tabId = opts.tabId || NA;
      }

      opts.pageName = (BC && BC.page && BC.page.type ? BC.page.type : NA) + '|' + opts.pageName;

      eventString = reduce(
        KEYS,
        function(acc, key) {
          let val = opts[key] || NA;

          val = ''.replace.call(val, WHITE_REGEX, DASH);
          acc.push(val);

          return acc;
        }, [],
      ).join(SEPARATOR);

      eventString += SEPARATOR + _formatDate();
    }

    return eventString.replace(SPECIAL_CHARS, '').replace(`${DASH}${DASH}`, DASH);
  }

  function leadingZero(num) {
    return num < 10 ? '0' + num : num;
  }

  function mapAction(opts) {
    const transform = ACTION_MAP[opts.componentName][opts.action] || identity;

    return extend({}, transform(opts));
  }

  module.exports = {
    appendTrackingId,
    buildEventTracker,
    buildFullUrl,
    buildPromotionUrl,
    buildTiUrl,
    trackEvent,
    trackImpression,
    getImpressionCodes,
  };
});
