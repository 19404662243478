import { useEffect, useState } from 'react';
import { isBusinessHour } from 'common/services/proactive-chat-service';
import useFeatures from 'common/components/hooks/use-features';

const POLL_INTERVAL_IN_MINUTES = 5;

const useCallCenterOpenPoll = (shouldPoll) => {
  const {
    next_chat_ghBusinessHoursOnly: chatGhBusinessHoursOnly,
  } = useFeatures(['next_chat_ghBusinessHoursOnly']);

  const [isCallCenterOpen, setIsCallCenterOpen] = useState(
    !chatGhBusinessHoursOnly
  );

  useEffect(() => {
    let ghoBusinessHoursPoolId;
    let ghoBusinessHoursEventListener;

    if (chatGhBusinessHoursOnly && shouldPoll) {
      ghoBusinessHoursEventListener = (event) => {
        const {
          detail: { businessHours },
        } = event;
        const pollCallback = () => {
          const businessHour = isBusinessHour(businessHours);

          setIsCallCenterOpen(businessHour);
        };

        pollCallback();
        ghoBusinessHoursPoolId = setInterval(
          pollCallback,
          1000 * 60 * POLL_INTERVAL_IN_MINUTES
        );
      };

      document.addEventListener(
        'bc_chat::gho_business_hours',
        ghoBusinessHoursEventListener
      );

      return () => {
        document.removeEventListener(
          'bc_chat::gho_business_hours',
          ghoBusinessHoursEventListener
        );
        clearInterval(ghoBusinessHoursPoolId);
      };
    }

    return undefined;
  }, [chatGhBusinessHoursOnly, shouldPoll]);

  return isCallCenterOpen;
};

export default useCallCenterOpenPoll;
