import ajaxHelper from 'common/util/ajax-helper';

const getOmsUserData = email => ajaxHelper.doRequest({
  cache: true,
  type: 'GET',
  url: `/api/oms/customer?email=${encodeURI(email)}`,
}, 'common/services/oms-service.js');

// eslint-disable-next-line import/prefer-default-export
export { getOmsUserData };
