'use strict';

const getDateTimeFromStringTime = (stringTime, timezoneOffset = 0) => {
  const time = new Date();
  const clientTimezoneOffset = time.getTimezoneOffset() + timezoneOffset;
  const [hours, minutes] = stringTime.split(':').map(Number);

  time.setHours(hours);
  time.setMinutes(minutes - clientTimezoneOffset);

  return time;
};

const dateHelper = {
  getDateTimeFromStringTime,
};

module.exports = dateHelper;
