import ATGFormHandler from 'common/atg-form-handler';
import BaseModel from 'common/models/base';
import { isLoggedInWithFacebook } from 'common/util/account/login-provider';
import { fbLogout } from 'common/util/fb-login-helper';

const logout = (opts) => {
  const callback = () => {
    localStorage.removeItem('login_provider');
    logoutAux(opts);
  };

  if (has('fb_login') && isLoggedInWithFacebook()) {
    fbLogout(callback);
  } else {
    callback();
  }
};

function logoutAux(opts) {
  opts = opts || {};

  const _logoutCallback = function(callback) {
    if (callback) {
      return callback();
    }

    document.location.reload();
  };

  ATGFormHandler('logout', new BaseModel(), {
    success() {
      _logoutCallback(opts.onSuccess);
    },
    error() {
      _logoutCallback(opts.onError);
    },
  }, $('#ajaxform-logout'));
}

export default logout;
