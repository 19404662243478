// bc.default.email.subscriptions
// ===================
// Class to create the default email subscriptions
define(function(require, exports, module) {
  var translate = require('common/localization').translate;
  var { extend, clone, each } = require('underscore');

  function makeBaseSubscriptionsObject() {
    return {
      subscriptions: [
        {
          site: 'bcs',
          emailType: 'promotional',
          title: translate('label.sales.discounts.offers.bcs'),
          state: false,
        },
        {
          site: 'competitivecyclist',
          emailType: 'promotional',
          title: translate('label.sales.discounts.offers.cc'),
          state: false,
        },
        {
          site: 'steepcheap',
          emailType: 'promotional',
          title: translate('label.sales.discounts.offers.sac'),
          state: false,
        },
      ],
    };
  }

  function generateEmailSubscriptions(opts) {
    var baseSubscriptionsObject = makeBaseSubscriptionsObject();

    extend(baseSubscriptionsObject, { source: '', campaign: '' }, opts);

    baseSubscriptionsObject.subscriptions = baseSubscriptionsObject.subscriptions.filter(
      function filterSiteSubscription(sub) {
        return sub.site === BC.site.code;
      },
    );

    // The _.clone ensures a new instance of the baseSubscriptionsObject is being returned
    // on every call, before this the modified value persists when was modified outside this
    // module, causing unwanted behavior on some scenarios.
    // TODO remove the extra level on the object (defaultSubscriptions)
    return { defaultSubscriptions: clone(baseSubscriptionsObject) };
  }

  module.exports = {
    /**
         * gets default email subscriptions object with source and campaign specified via opts
         * @returns {defaultSubscriptions: {subscriptions: [], source: opts.source, campaign: opts.campaign}}
         */
    getDefaultSubscriptions: generateEmailSubscriptions,

    getDefaultSubscribeAll(opts) {
      var tmpSubsObject = generateEmailSubscriptions(opts);

      tmpSubsObject.defaultSubscriptions.subscriptions.push({ emailType: 'collections' }, { emailType: 'newsletter' });
      each(tmpSubsObject.defaultSubscriptions.subscriptions, function(sub) { sub.state = true; });

      return tmpSubsObject;
    },
  };
});
