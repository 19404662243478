import { mapObject, isUndefined, findIndex } from 'underscore';

const BREAKPOINTS = {
  base: 'screen and (min-width: 20em)', // 320px
  small: 'screen and (min-width: 37.5em)', // 600px
  medium: 'screen and (min-width: 56.25em)', // 900px
  large: 'screen and (min-width: 68.75em)', // 1100px
  xlarge: 'screen and (min-width: 87.5em)', // 1400px
};

const ORDERED_BREAKPOINTS = [
  'base',
  'small',
  'medium',
  'large',
  'xlarge',
];

function getCurrentBreakpoint() {
  const currentBreakpointIndex = findIndex(ORDERED_BREAKPOINTS, breakpoint => isQueryMatch(breakpoint) === false);

  return currentBreakpointIndex === -1 ? 'xlarge' : ORDERED_BREAKPOINTS[currentBreakpointIndex];
}

// returns each breakpoint and if it matches the current screen size
function getBreakpointMap() {
  return mapObject(BREAKPOINTS, breakpointQuery => window.matchMedia(breakpointQuery).matches);
}

function respondToQueryMatch(breakpoint, queryMatchCallback) {
  const mediaQuery = BREAKPOINTS[breakpoint];

  if (isUndefined(mediaQuery)) {
    return;
  }

  const mediaQueryList = window.matchMedia(mediaQuery);

  mediaQueryList.addListener(queryMatchCallback);

  return mediaQueryList; // return query to we can do removeListener() if needed
}

function isQueryMatch(breakpoint) {
  const mediaQuery = BREAKPOINTS[breakpoint];

  return window.matchMedia(mediaQuery).matches;
}

export {
  getBreakpointMap,
  respondToQueryMatch,
  isQueryMatch,
  getCurrentBreakpoint,
};
