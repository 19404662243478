define(function(require, exports, module) {
  function redeem(code) {
    return $.post(
      '/rest/backcountry/commerce/order/RedeemActor/redeem',
      { code, type: 'coupon' }
    );
  }

  function remove(promotionId) {
    return $.post(`/rest/backcountry/commerce/order/RedeemActor/remove?redeemedItemId=${promotionId}`).fail(function(jqXHR) {
      $.error(
        `Error removing promotion: ${promotionId} Response Status: ${jqXHR.status} Response Text: ${jqXHR.responseText}`,
        '/common/services/coupon-redemption.js'
      );
    });
  }

  module.exports = {
    redeem,
    remove,
  };
});
