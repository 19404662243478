function il8nLoader() {
  if (!BC.i18n_promise) {
    let i18nPromiseResolve;

    BC.i18n_promise = new Promise(resolve => i18nPromiseResolve = resolve);
    BC.subscribe('window.fetch.ready', () => {
      fetch('/Store/common/ui.msgs.json.jsp')
        .then((response) => {
          performance.mark('resolve fetch: ui.msgs.json.jsp');
          i18nPromiseResolve(response.json());
        });
    });
  }

  return BC.i18n_promise
    .then((translations) => {
      BC.i18n.translations = translations;
    })
    .catch((error) => {
      $.error(`[il8n-loader] Error fetching message keys: ${error}`, 'bc.il8n-loader');
    });
}

export default il8nLoader;
