const replaceDynamicFlyoutContent = (flyout, posts) => {
  flyout.find('.flyout-panel__image-wrapper').each((index, element) => {
    if (posts[index]) {
      const $element = $(element);
      const $elementImage = $element.find('.flyout-panel__image-element');

      $element.attr('href', posts[index].url);

      if ($elementImage.attr('src') !== undefined) {
        $elementImage.attr('src', posts[index].img);
      } else {
        $elementImage.attr('data-src', posts[index].img);
      }

      $element.find('.flyout-panel__image-text').text(posts[index].name);
    }
  });
};

export default replaceDynamicFlyoutContent;
