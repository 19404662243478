define(function() {
  function makeUrl(endpoint) {
    return `/community${endpoint}`;
  }

  function makeRequestOptions(options) {
    return {
      dataType: 'json',
      timeout: 5000,
      cache: true,
      ...options,
    };
  }

  function doRequest(options) {
    const requestOpts = makeRequestOptions(options);

    return $.ajax(requestOpts)
      .fail(function(jqXHR, textStatus, errorThrown) {
        $.error(`textStatus: ${textStatus}
                errorThrown: ${errorThrown}
                serviceUrl: ${requestOpts.url}
                readyState: ${jqXHR.readyState}
                status: ${jqXHR.status}
                responseText: ${jqXHR.responseText}
                responseJSON: ${jqXHR.responseJSON}`, 'common/services/community-service');
      });
  }

  function fetchUserMiniProfile(userId) {
    return doRequest({
      url: makeUrl(`/users/${userId}/miniprofile?hasIcEmail=true&siteId=${BC.site.id}`),
      method: 'GET',
    });
  }

  function findGearhead(gearheads = [], userId) {
    return gearheads.find(gearhead => gearhead && gearhead.userId === userId);
  }

  function fetchUserByEmail(email) {
    return doRequest({
      url: makeUrl(`/users/customer/oms?email=${email}`),
      method: 'GET',
    });
  }

  function fetchConnectedGearhead() {
    if (BC.profile.id && BC.user.isRegistered) {
      return doRequest({
        method: 'GET',
        url: makeUrl(`/users/${BC.profile.id}/connects`),
      });
    } else {
      return $.Deferred().resolve({}).promise();
    }
  }

  function fetchGearhead(userId) {
    return fetchGearheads()
      .then(data => findGearhead(data, userId));
  }

  function fetchGearheads() {
    if (BC.gearheads) {
      return $.Deferred().resolve(BC.gearheads).promise();
    }

    return doRequest({
      method: 'GET',
      url: makeUrl(`/gearheads/${BC.site.id}/all`),
    })
      .then((gearheads) => {
        BC.gearheads = gearheads;

        return gearheads;
      });
  }

  function fetchProductFitData(productId) {
    return doRequest({
      url: makeUrl(`/community/product/summaryBox/${productId}?siteId=${BC.site.id}`),
      method: 'GET',
    });
  }

  function fetchProductWall(productId, pageSize, pageNumber, contentType, mediaType, sizeFit, forcedSiteId) {
    const siteId = forcedSiteId || BC.site.id;

    return doRequest({
      url: makeUrl(`/community/product/${productId}/wall?siteId=${siteId}&pageSize=${pageSize}&pageNumber=${pageNumber}${contentType ? `&contentType=${contentType}` : ''}${mediaType ? `&mediaType=${mediaType}` : ''}${sizeFit ? `&sizeFit=${sizeFit}` : ''}`),
      method: 'GET',
    });
  }

  function fetchUserWall(profileId, pageSize, pageNumber, contentType, mediaType, sizeFit, forcedSiteId) {
    const siteId = forcedSiteId || BC.site.id;

    return doRequest({
      url: makeUrl(`/users/${profileId}/wall?siteId=${siteId}&pageSize=${pageSize}&pageNumber=${pageNumber}${contentType ? `&contentType=${contentType}` : ''}${mediaType ? `&mediaType=${mediaType}` : ''}${sizeFit ? `&sizeFit=${sizeFit}` : ''}`),
      method: 'GET',
    });
  }

  return {
    fetchProductFitData,
    fetchProductWall,
    findGearhead,
    fetchConnectedGearhead,
    fetchGearhead,
    fetchGearheads,
    fetchUserMiniProfile,
    fetchUserByEmail,
    fetchUserWall,
  };
});
