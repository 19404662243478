import 'jquery-ui/core';
import 'jquery-ui/widget';
import { isEmpty } from 'underscore';
import { translate } from 'common/localization';

$.widget('bc.a11ymodal', {
  options: {
    autoOpen: false,
    bodyElement: '.js-body-wrap',
    closeOnEscape: true,
    closeOnOverlayClick: true,
    customContentClass: '',
    customModalClass: '',
    customOverlayClass: '',
    isRemote: false,
    modalContentClass: 'ui-modal-content',
    onClose: $.noop,
    onOpen: $.noop,
    onOpenFocusClass: '',
    remoteContentUrl: '',
    title: 'ui-modal-title',
    useDefaultStyles: false,
    withCloseBtn: true,
    ariaLabelledby: '',
    ariaDescribedby: '',
  },
  _create() {
    this._checkRemoteConfig();
    this._createOverlay();
    this._createModalWrap();
    this._getModalContent();
    this._bindEvents();
  },
  _checkRemoteConfig() {
    const {
      customContentClass,
      customModalClass,
      customOverlayClass,
      isRemote,
    } = this.options;

    if (isRemote) {
      const extraCustomModalClass = this.element.attr('remoteModalClass') ? this.element.attr('remoteModalClass') : '';
      const extraCustomContentClass = this.element.attr('remoteModalContentClass') ? this.element.attr('remoteModalContentClass') : '';
      const extraCustomOverlayClass = this.element.attr('remoteModalOverlayClass') ? this.element.attr('remoteModalOverlayClass') : '';

      this.option('customModalClass', `${extraCustomModalClass} ${customModalClass}`);
      this.option('customContentClass', `${extraCustomContentClass} ${customContentClass}`);
      this.option('customOverlayClass', `${extraCustomOverlayClass} ${customOverlayClass}`);
    }
  },
  _createOverlay() {
    this.$overlay = $('<div/>')
      .addClass(`ui-modal-overlay ${this.options.customOverlayClass}`)
      .hide()
      .appendTo($('body'));
  },
  _createModalWrap() {
    const {
      customModalClass,
      title,
      ariaLabelledby,
      ariaDescribedby,
      withCloseBtn,
      useDefaultStyles,
    } = this.options;

    const customClass = useDefaultStyles ? `${customModalClass} ui-modal-base-layout` : customModalClass;

    this.$modalWrap = $('<div/>')
      .addClass(`ui-modal-wrap ${customClass}`)
      .attr({
        role: 'dialog',
        'aria-modal': 'true',
        'aria-label': title,
        'aria-describedby': ariaDescribedby,
        'aria-labelledby': ariaLabelledby,
      })
      .appendTo(this.$overlay);

    if (withCloseBtn) {
      this.$closeBtn = $('<button/>')
        .addClass('btn-reset ui-modal-close-btn')
        .attr({
          role: 'button',
          'aria-label': translate('label.close'),
          'data-id': 'close-modal',
        })
        .appendTo(this.$modalWrap);
    }
  },
  _getModalContent() {
    const {
      isRemote,
      customContentClass,
      remoteContentUrl,
      modalContentClass,
    } = this.options;

    if (isRemote) {
      this.contentUrl = isEmpty(remoteContentUrl) ? this.element.attr('remoteContentUrl') : remoteContentUrl;
      if (isEmpty(this.contentUrl)) {
        $.error('[bc.modal] no configured remoteUrl');

        return;
      }
    } else {
      this.originalPosition = {
        parent: this.element.parent(),
        index: this.element.parent().children().index(this.element),
      };
      this.element
        .show()
        .addClass(`${modalContentClass} ${customContentClass}`)
        .appendTo(this.$modalWrap);
      this._getFallbackTitle();
    }
  },
  _getRemoteModalContent() {
    const {
      customContentClass,
      modalContentClass,
    } = this.options;

    const successCb = (remoteContent) => {
      this.$content = $('<div/>').html($(remoteContent));

      this.$content
        .addClass(`${modalContentClass} ${customContentClass}`)
        .appendTo(this.$modalWrap);

      this._getFallbackTitle();
      this.displayModal();
    };

    $.get(
      this.contentUrl,
      {},
      successCb,
      'html',
    );
  },
  _getFallbackTitle() {
    // search for fallback title inside the content
    // class comes from legacy modal/dialog
    if (isEmpty(this.options.title)) {
      const fallbackTitle = this.$modalWrap.find('.dialog-title').text() || this.$modalWrap.find('.js-dialog-title').text();

      if (isEmpty(fallbackTitle)) {
        const traceInfo = this.options.isRemote ? this.options.remoteContentUrl : this.element.attr('class');

        $.error(`[bc.modal] - No modal title is an accessibility error. Please add a valid modal description on element ${traceInfo}`);
      } else {
        this.$modalWrap.attr('aria-label', fallbackTitle);
      }
    }
  },
  _bindEvents() {
    const {
      closeOnOverlayClick,
      withCloseBtn,
      closeOnEscape,
      isRemote,
    } = this.options;

    if (closeOnOverlayClick) {
      this._on(this.$overlay, {
        mousedown: (event) => {
          if (this.$modalWrap.get(0).contains(event.target) || event.target === this.$modalWrap.get(0)) {
            return;
          } else {
            event.preventDefault();
            this.close();
          }
        },
      });
    }

    if (withCloseBtn) {
      this._on(this.$closeBtn, {
        click: 'close',
      });
    }

    if (isRemote) {
      this._on(this.element, {
        click: (event) => {
          event.preventDefault();
          this.open();
        },
      });
    }

    this._on(this.$modalWrap, {
      keydown: (event) => {
        if (!event.defaultPrevented && (event.key === 'ESC' || event.key === 'Escape') && closeOnEscape) {
          event.preventDefault();
          this.close();
        }

        if (event.key !== 'Tab') {
          return;
        }

        // this section creates a focus trap inside the modal,
        // it disables tabbing outside the modal contents
        const tabbableElems = this.$modalWrap.find(':tabbable');
        const $first = tabbableElems.filter(':first');
        const $last = tabbableElems.filter(':last');

        if ((event.target === $last[0] || event.target === this.$modalWrap[0]) && !event.shiftKey) {
          $first.eq(0).focus();
          event.preventDefault();
        } else if ((event.target === $first[0] || event.target === this.$modalWrap[0]) && event.shiftKey) {
          $last.eq(0).focus();
          event.preventDefault();
        }
      },
    });
  },
  _init() {
    if (this.options.autoOpen) {
      this.open();
    }
  },
  open() {
    const {
      isRemote,
      modalContentClass,
    } = this.options;

    if (isRemote && this.$modalWrap.find(`.${modalContentClass}`).length === 0) {
      this._getRemoteModalContent();
    } else {
      this.displayModal();
    }
  },
  displayModal() {
    this.originalFocusNode = document.activeElement;
    this.$modalWrap.appendTo(this.$overlay);
    $(this.options.bodyElement).attr('aria-hidden', true);
    this.$modalWrap.removeAttr('aria-hidden');
    $('html').addClass('no-scroll');
    $('body').addClass('no-scroll');
    this.$overlay.show();
    this._modalFocus(this.options.onOpenFocusClass);
    this._isOpen = true;
    this.options.onOpen();
  },
  close() {
    $(this.options.bodyElement).removeAttr('aria-hidden');
    this.$modalWrap.attr('aria-hidden', true);
    $('html').removeClass('no-scroll');
    $('body').removeClass('no-scroll');
    this.$overlay.hide();
    // we detach the content in case there's any content playing to stop it
    this.$modalWrap.detach();
    $(this.originalFocusNode).focus();
    this._isOpen = false;
    this.options.onClose();
  },
  setOnCloseCallback(callback = null) {
    if (callback) {
      this.option({
        onClose: callback,
      });
    }
  },
  // use if you change the modal content and loose the focused element
  reloadFocus(selector = '') {
    this._modalFocus(selector);
  },
  _modalFocus(selector) {
    // Set focus to the first match:
    // the first element inside the modal with [autofocus]
    // a provided element selector
    // the first tabbable element inside the modal
    // the close button
    // the first element on the modal (assigned tab-index)
    let $element;

    if (this.options.isRemote) {
      $element = this.$modalWrap;
    } else {
      $element = this.element;
    }

    let hasFocus = $element.find('[autofocus]');

    if (!hasFocus.length && !isEmpty(selector)) {
      hasFocus = $element.find(selector);
    }

    if (!hasFocus.length) {
      hasFocus = $element.find(':tabbable');
    }

    if (!hasFocus.length && this.options.withCloseBtn) {
      hasFocus = this.$closeBtn;
    }

    if (!hasFocus.length) {
      hasFocus = $element.find('*').first().attr('tabIndex', '0');
    }

    hasFocus.eq(0).focus();
  },
  isOpen() {
    return this._isOpen;
  },
  _destroy() {
    if (this.isOpen()) {
      this.close();
    }

    if (!this.options.isRemote) {
      this.element
        .attr('style', '')
        .removeClass(`${this.options.modalContentClass} ${this.options.customContentClass}`)
        .detach();

      this.originalPosition.parent.append(this.element);
    }

    // all created elements are inside this one.
    this.$overlay.remove();
  },
});
