import 'jquery-ui/widget';
import { getCurrentBreakpoint } from 'common/util/matchmedia-helper';

const LAZYLOAD_TYPE_ATTR = 'data-lazyload-type';

// TODO mcambronero
// breakpoint based imgs (bg img)
// when inner, disconnect once all elements lazyloaded

// Lazyloader does not support picture elements since there's a colliding
// issue with the <picture> polyfill. If you still want to use it,
// use it with the 'inner' option set to true
$.widget('bc.lazyload', {
  options: {
    useBreakpointBackgroundImages: false,
    // how close to the viewport
    proximity: 0,
    // check inner images to lazyload
    inner: false,
    // root element for the observer
    context: null,
  },

  _create() {
    if (!('IntersectionObserver' in window)) {
      import(
        /* webpackChunkName: "intersection-observer-polyfill" */
        'intersection-observer'
      ).then(() => this._createObserver());
    } else {
      this._createObserver();
    }
  },

  _destroy() {
    this.observer.disconnect();
  },

  _createObserver() {
    this.observer = new IntersectionObserver(this._evaluateIntersection.bind(this), {
      root: this.options.context,
      rootMargin: `${this.options.proximity}px`,
      threshold: 0,
    });

    this._bindObserver();
  },

  _bindObserver() {
    this._checkElementType(this.element[0]);

    if (this.element[0].hasAttribute(LAZYLOAD_TYPE_ATTR)) {
      this.observer.observe(this.element[0]);
    }

    if (this.options.inner) {
      const $elements = this.element.find('[data-src], [data-background-image], [data-srcset]');

      $elements.each((idx, element) => {
        this._checkElementType(element);
        this.observer.observe(element);
      });
    }
  },

  _evaluateIntersection(entries) {
    const self = this;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        self._onIntersection(entry.target, entry.target.getAttribute(LAZYLOAD_TYPE_ATTR));

        if (this.options.inner) {
          self.observer.unobserve(entry.target);
        } else {
          self.observer.disconnect();
        }
      }
    });
  },

  _checkElementType(element) {
    if (element.hasAttribute('data-src')) {
      element.setAttribute(LAZYLOAD_TYPE_ATTR, 'img');
    }

    if (element.hasAttribute('data-srcset')) {
      element.setAttribute(LAZYLOAD_TYPE_ATTR, 'srcset');
    }

    if (element.hasAttribute('data-src') && element.hasAttribute('data-srcset')) {
      element.setAttribute(LAZYLOAD_TYPE_ATTR, 'src-srcset');
    }

    if (element.hasAttribute('data-background-image')) {
      element.setAttribute(LAZYLOAD_TYPE_ATTR, 'backgroundImg');
    }

    return;
  },

  _onIntersection(targetElement, type) {
    switch (type) {
      case 'img':
        this._loadImg(targetElement);
        break;
      case 'backgroundImg':
        this._loadBackgroundImg(targetElement);
        break;
      case 'srcset':
        this._loadSrcset(targetElement);
        break;
      case 'src-srcset':
        this._loadImgSrcSet(targetElement);
        break;
    }
  },

  _loadImgSrcSet(element) {
    const imgSrc = element.getAttribute('data-src');
    const srcset = element.getAttribute('data-srcset');
    const isLoaded = this._verifyLoaded(element);

    if (isLoaded || (element.hasAttribute('data-src') === false && element.hasAttribute('data-srcset') === false)) {
      return;
    } else {
      element.setAttribute('src', imgSrc);
      element.setAttribute('srcset', srcset);
      element.removeAttribute('data-src');
      element.removeAttribute('data-srcset');
    }

    this._setLoaded(element);
  },

  _loadImg(element) {
    const imgSrc = element.getAttribute('data-src');
    const isLoaded = this._verifyLoaded(element);

    if (isLoaded || element.hasAttribute('data-src') === false) {
      return;
    } else {
      element.setAttribute('src', imgSrc);
      element.removeAttribute('data-src');
    }

    this._setLoaded(element);
  },

  _loadBackgroundImg(element) {
    const isLoaded = this._verifyLoaded(element);
    const useBreakpointImage = this.options.useBreakpointBackgroundImages && element.hasAttribute(`data-background-image-${getCurrentBreakpoint()}`);
    const elementBgImg = useBreakpointImage ? element.getAttribute(`data-background-image-${getCurrentBreakpoint()}`) : element.getAttribute('data-background-image');

    if (isLoaded || !element.hasAttribute('data-background-image')) {
      return;
    }

    element.style.backgroundImage = `url('${elementBgImg}')`;
    element.removeAttribute('data-background-image');

    this._setLoaded(element);
  },

  _loadSrcset(element) {
    const srcset = element.getAttribute('data-srcset');
    const isLoaded = this._verifyLoaded(element);

    if (isLoaded || element.hasAttribute('data-srcset') === false) {
      return;
    } else {
      element.setAttribute('srcset', srcset);
      element.removeAttribute('data-srcset');
    }

    this._setLoaded(element);
  },

  _setLoaded(element) {
    return element.setAttribute('data-loaded', true);
  },

  _verifyLoaded(element) {
    return element.getAttribute('data-loaded');
  },

});
