import ajaxHelper from 'common/util/ajax-helper';

const config = {
  basePath: '/services/content',
};

const getTopPost = postsLimit => ajaxHelper.doRequest({
  cache: true,
  type: 'GET',
  url: `${config.basePath}?limit=${postsLimit}`,
}, 'common/services/group-posts-service.js');

export default getTopPost;
