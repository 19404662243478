/*
 * The version of liveperson we use doesn't seem to have a comprehensive public
 * api, this module will wrap hacks that would ideally be replaced by public api
 * methods.
 */

define(function() {
  return {
    bind(options) {
      options = options || {};
      $(function() {
        function _bind() {
          try {
            window.lpTag.events.bind('LP_OFFERS', 'OFFER_DISPLAY', function() {
              // lp will for some reason trigger OFFER_DISPLAY when the script is initially
              // evaluated, could be when the chat buttons are displayed.
              if ($('#lpChatInvitation:visible').length === 1) {
                options.onOfferDisplayed();
              }
            });
            window.lpTag.events.bind('LP_OFFERS', 'OFFER_DECLINED', options.onOfferDeclined);
          } catch (e) {
            $.error('Could not bind to lpTag event');
          }
        }
        // There's no reliable hook for knowing when lpTag is loaded, resorting to polling
        var pollLivePerson = setInterval(function() {
          if (window.lpTag && window.lpTag.events && typeof window.lpTag.events.bind === 'function') {
            clearInterval(pollLivePerson);
            _bind();
          }
        }, 500);
      });
    },
  };
});
