/* eslint jest/consistent-test-it: off */
// Validates values using defined regular expressions. Regular expressions are defined in 3 files
// one for default validations, one for USA specific validations and other for Canada specific validations
define(function(require) {
  var caModelValidations = require('common/models/base.tests.ca.json');
  var defaultModelValidations = require('common/models/base.tests.json');
  var usModelValidations = require('common/models/base.tests.us.json');
  var validationPatternsByCountry = {
    OT: defaultModelValidations,
    US: usModelValidations,
    CA: caModelValidations,
  };

  function getValidations(country) {
    return _.extend(_.clone(validationPatternsByCountry.OT, true), country ? validationPatternsByCountry[country] : {});
  }

  function isValid(validation, value, country) {
    var passed = false;
    var baseTests = getValidations(country);
    var test = validation.test;
    var type = validation.type;
    var delimiter = validation.delimiter;

    if (test in baseTests) {
      test = baseTests[test];
    }

    if (type === 'list') {
      $.each(value.split(new RegExp(delimiter)), function(index, splitValue) {
        if (splitValue && splitValue.length) {
          passed = isTextValid(splitValue, test, baseTests);

          return passed;
        }
      });
    } else {
      passed = isTextValid(value, test, baseTests);
    }

    return passed;
  }

  function isTextValid(value, test, baseTests) {
    var passed = true;

    if (_.isArray(test)) {
      passed = RegExp.apply(this, test).test(value);
    } else if (_.isFunction(test)) {
      passed = test(value);
    } else if (_.isRegExp(test)) {
      passed = test.test(value);
    } else if (_.isString(test)) {
      var newFunc = new Function(test);

      passed = _.bind(newFunc, this, value, baseTests)();
    }

    return passed;
  }

  function isVariantSku(sku) {
    var skuPattern = /^([\d\w]+)-([\d\w]+)-([\d\w]+)$/;

    if (skuPattern.test(sku)) {
      return sku.match(skuPattern);
    }

    return [];
  }

  return {
    validate(validations, value, country) {
      // Returns first validation that fails, if there are no invalid validations returns undefined
      return _.find(validations, function(validation) {
        return !isValid(validation, value, country);
      });
    },

    getNumberOfPatternsByCountry(country) {
      return _.has(validationPatternsByCountry, country) ? _.size(validationPatternsByCountry[country]) : 0;
    },

    getTotalNumberOfPatterns() {
      var numOfPatterns = 0;

      _.each(validationPatternsByCountry, function(modelValidations) {
        numOfPatterns += _.size(modelValidations);
      });

      return numOfPatterns;
    },

    isVariantSku,
    isValid,
  };
});
