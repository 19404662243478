import { range } from 'underscore';
import { translate } from 'common/localization';

// TODO put this globally
function padStart(string, targetLength, padString) {
  targetLength >>= 0; // floor if number or convert non-number to 0;
  padString = String(typeof padString !== 'undefined' ? padString : ' ');

  if (string.length > targetLength) {
    return String(string);
  } else {
    return padString.slice(0, Math.abs(string.length - targetLength)) + String(string);
  }
}

const months = range(1, 13);
const monthsMaxDays = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

const monthsName = [
  translate('january'),
  translate('february'),
  translate('march'),
  translate('april'),
  translate('may'),
  translate('june'),
  translate('july'),
  translate('august'),
  translate('september'),
  translate('october'),
  translate('november'),
  translate('december'),
];

const monthsArray = months.map((monthNumber, idx) => {
  return {
    name: monthsName[idx],
    value: padStart(String(monthNumber), 2, '0'),
    dates: range(1, (monthsMaxDays[idx] + 1)),
  };
});

export {
  monthsArray,
  padStart,
};
