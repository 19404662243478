import flatMap from 'lodash/flatMap';
import ajaxHelper from 'common/util/ajax-helper';
import baseSubscriptions from 'common/util/bc.default.email.subscriptions';

const config = {
  subscriptionsBasePath: '/Store/services/customer/subscriptions/',
  emailSubscriptionsBasePath: '/Store/services/notification/',
  receiptSubscriptionBasePath: '/Store/services/customer/subscriptions/receipt',
};

const getEmailSubscriptions = email => ajaxHelper.doRequest({
  type: 'GET',
  url: `${config.emailSubscriptionsBasePath}${email}/`,
}, 'common/services/notification-service.js');

const updateEmailSubscriptions = (email, subs, extraParam = '') => ajaxHelper.doJsonRequest({
  type: 'POST',
  url: config.emailSubscriptionsBasePath + extraParam,
  data: JSON.stringify({ ...subs, email }),
}, 'common/services/notification-service.js');

const subscribeToAll = (email, opts) => {
  const subscriptions = baseSubscriptions.getDefaultSubscribeAll(opts).defaultSubscriptions;

  return updateEmailSubscriptions(email, subscriptions);
};

const subscribeToNtf = ({ email, couponId }) => {
  const subscriptions = baseSubscriptions.getDefaultSubscribeAll({ couponId, source: 'ntf' }).defaultSubscriptions;

  return updateEmailSubscriptions(email, subscriptions, 'ntf/');
};

const updateSubscriptions = (subscriptions, birthday, source = '', campaign = '') => ajaxHelper.doJsonRequest({
  type: 'POST',
  url: config.subscriptionsBasePath,
  data: JSON.stringify({
    subscriptions, birthday, source, campaign,
  }),
}, 'common/services/notification-service.js');

const getSubscriptions = () => ajaxHelper.doRequest({
  type: 'GET',
  url: config.subscriptionsBasePath,
}, 'common/services/notification-service.js');

const buildSubscription = (subscriptionType, status) => {
  return { subscriptionType, status };
};

const getSubscriptionsChanges = (
  previousSubscriptions,
  currentSubscriptions
) => {
  const unnestedPreviousSubscriptions = flatMap(
    previousSubscriptions,
    ([, subscription]) => subscription
  );

  return flatMap(currentSubscriptions, ([, subscription]) => subscription).map(
    ({ name, status }) => {
      let changed = false;
      const prevSubscription = unnestedPreviousSubscriptions.find(
        ({ name: prevSubscriptionName }) => name === prevSubscriptionName
      );

      if (prevSubscription) {
        changed = status !== prevSubscription.status;
      }

      return {
        name,
        status,
        changed,
      };
    }
  );
};

export {
  getEmailSubscriptions,
  updateEmailSubscriptions,
  getSubscriptions,
  subscribeToAll,
  subscribeToNtf,
  updateSubscriptions,
  buildSubscription,
  getSubscriptionsChanges,
};
