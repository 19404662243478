import DATES_CONSTANTS from 'common/util/dates-constants';
import { getDateTimeFromStringTime } from 'common/util/date-helper';

const isBusinessHour = ({ timezoneOffset = 0, ...hoursByWeekDay }) => {
  const now = new Date();
  const currentWeekDay = DATES_CONSTANTS.WEEK_DAYS[now.getDay()];
  const weekDayHours = hoursByWeekDay[currentWeekDay];

  return weekDayHours.some(([stringStartTime, stringEndTime]) => {
    const businessDayStart = getDateTimeFromStringTime(
      stringStartTime,
      timezoneOffset
    );
    const businessDayEnd = getDateTimeFromStringTime(
      stringEndTime,
      timezoneOffset
    );
    const nowTime = now.valueOf();

    return (
      businessDayStart.valueOf() <= nowTime &&
      businessDayEnd.valueOf() >= nowTime
    );
  });
};

// eslint-disable-next-line import/prefer-default-export
export { isBusinessHour };
