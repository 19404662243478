import 'jquery-ui/widget';
import 'common/widgets/modal/bc.modal';
import 'common/widgets/authentication-modal/bc.authentication-modal-login';
import 'common/widgets/authentication-modal/bc.authentication-modal-register';
import { translate } from 'common/localization';
import { togglePasswordInput } from 'common/util/auth-modal-helper';

$.widget('bc.authenticationModal', {
  options: {
    authWrap: '#authentication',
    continueGuestBtn: '.js-continue-guest',
    loginContainer: '.js-login-container',
    registerContainer: '.js-register-container',
    showNewAcctBtn: '.js-new-account-btn',
    showSignInBtn: '.js-sign-in-btn',
  },

  _create() {
    this.$authWrap = $(this.options.authWrap);
    this.$loginContainer = this.$authWrap.find(this.options.loginContainer);
    this.$registerContainer = this.$authWrap.find(this.options.registerContainer);

    this.$authWrap.a11ymodal({
      title: translate('authentication.modal.title'),
      customModalClass: 'ui-authentication-wrap',
      customContentClass: 'ui-authentication-content',
      onOpenFocusClass: '#modal-login',
    }, {
      create: () => {
        this._bindEvents();
        this._initWidgets();
      },
    });
  },

  _bindEvents() {
    BC.subscribe('bc.authmodal.open', this._showLoginModal.bind(this));
    BC.subscribe('bc.authmodal.refocus', this._reloadFocus.bind(this));
    BC.subscribe('bc.authmodal.close', this._closeModal.bind(this));

    this._on(this.$authWrap.find('.js-auth-modal-pass-toggle'), {
      click: event => togglePasswordInput(event),
    });

    this._on(this.$authWrap.find(this.options.showNewAcctBtn), {
      click: () => {
        this.$loginContainer.hide();
        this.$registerContainer.fadeIn();
        this.$authWrap.a11ymodal('reloadFocus');
      },
    });

    this._on(this.$authWrap.find(this.options.showSignInBtn), {
      click: () => {
        this.$registerContainer.hide();
        this.$loginContainer.fadeIn();
        this.$authWrap.a11ymodal('reloadFocus');
      },
    });

    this._on(this.$authWrap.find(this.options.continueGuestBtn), {
      click: () => {
        this.$authWrap.a11ymodal('close');
        this.onCloseCallback && this.onCloseCallback();
      },
    });
  },

  _reloadFocus(selector) {
    this.$authWrap.a11ymodal('reloadFocus', selector);
  },

  _closeModal() {
    this.$authWrap.a11ymodal('close');
  },

  _initWidgets() {
    this.$loginContainer.authModalLogin();
    this.$registerContainer.authModalRegister();
  },

  _showLoginModal(options = {}) {
    const {
      authSuccessCallback, // Something to do after successful login, if not present it will just reload the page
      onCloseCallback, // Something to do when the user closes the modal without authenticating
      force, // Force login to recognized users, useful for actions that require elevated permissions
      showModalCallback, // Something to do after the modal is shown, by default nothing.
      sectionToOpen = 'login', // open a specific section (create or login), it defaults to login
    } = options;

    // Only show the modal if the user is anonymous or if we are being forced to. A logged in user should
    // never see the login modal.
    const showModal = !BC.user.isExplicitOrAutoLoggedIn || (!BC.user.isLoggedIn && !!force);

    if (!showModal) {
      // Nothing to do here
      return;
    }

    // This is mostly used for backwards compatibility,
    // subscribe to 'show.loginModal' if you think you need this instead
    showModalCallback && showModalCallback();

    if (authSuccessCallback) {
      this.$loginContainer.authModalLogin('setAuthSuccessCallback', authSuccessCallback);
      this.$registerContainer.authModalRegister('setAuthSuccessCallback', authSuccessCallback);
    }

    if (onCloseCallback) {
      this.onCloseCallback = onCloseCallback;
      this.$authWrap.a11ymodal('setOnCloseCallback', onCloseCallback);
    }

    if (sectionToOpen === 'create') {
      this.$loginContainer.hide();
      this.$registerContainer.fadeIn();
      this.$authWrap.a11ymodal('reloadFocus');
    } else {
      this.$registerContainer.hide();
      this.$loginContainer.fadeIn();
      this.$authWrap.a11ymodal('reloadFocus');
    }

    this.$authWrap.a11ymodal('open');
    BC.publish('ScLoginModalLaunch.sitecatalystutil');
    BC.publish('show.loginModal');

    this._autocompleteFormsOnOpen();
  },

  _autocompleteFormsOnOpen() {
    // autocomplete if user is registered
    if (BC.user.isRegistered && BC.profile.id !== BC.profile.login) {
      this.$loginContainer.find('.js-login-user-input').attr('value', BC.profile.login);
      this.$registerContainer.find('.js-register-name').attr('value', BC.profile.displayName);
      this.$registerContainer.find('.js-register-user').attr('value', BC.profile.login);
    }
  },

  _destroy() {
    this.$loginContainer.authModalLogin('destroy');
    this.$registerContainer.authModalRegister('destroy');
    this.$authWrap.a11ymodal('destroy');

    BC.unsubscribe('bc.authmodal.open');
    BC.unsubscribe('bc.authmodal.refocus');
    BC.unsubscribe('bc.authmodal.close');
  },
});
