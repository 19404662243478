define(function(require) {
  const Backbone = require('backbone');
  const { first, map } = require('underscore');

  const targeter = Backbone.Model.extend({
    defaults: {
      pullFirst: true,
      targeterName: '',
      targeterContent: [
        {
          promoName: '',
          promoContent: '',
          promoJSONContent: '',
          promoLinkUrl: '',
        },
      ],
    },
    initialize() {
      this.on('error', function(model, resp) {
        model.clear();
        $.error('Error ' + resp.status + ' in targeter.js ');
      });
    },
    parse(data) {
      const targeter = this.get('pullFirst') ? first(data) : data;
      let content = [];

      content = map(data, function(item) {
        return {
          promoName: targeter && item.adminDisplayName,
          promoContent: targeter && item.longDescription,
          promoJSONContent: targeter && item.jsonData,
          promoLinkUrl: targeter && item.linkUrl,
        };
      });

      return {
        targeterName: this.get('targeterName'),
        targeterContent: content,
      };
    },
    url() {
      const targeterName = this.get('targeterName');
      const apiEnvironment = document.domain.indexOf('preview') > -1 ? 'staging' : 'production';
      const apiVersion = '1';
      const siteId = BC.site.id;

      return '/v' + apiVersion + '/' + apiEnvironment + '/targeters/' + targeterName + '?siteId=' + siteId;
    },
  });

  return targeter;
});
