define(function(require, exports, module) {
  var { map, extend } = require('underscore');
  var urlUtils = require('common/util/bc.url.utils');
  var ajaxHelper = require('common/util/ajax-helper');

  var config = {
    recommendations: {
      timeout: 3000,
    },
  };

  var RECOMMENDATION_TYPES = {
    BEST_SELLERS: 'bestSellers',
    BRAND_BEST_SELLERS: 'brandBestSellers',
    CONSTRUCTOR: 'constructor',
    NEW_ARRIVALS: 'newArrivals',
    SOLR: 'solr',
  };

  var providerStrategy = {
    bestSellers: getBestSellers,
    brandBestSellers: getBrandBestSellers,
    newArrivals: getNewArrivals,
  };

  function getProductSearchRequestUrl(url, quantity) {
    url = addProductLimitParameter(url, quantity);
    url = addPageIdsParameter(url);

    return url;
  }

  function addProductLimitParameter(url, quantity) {
    if (quantity && quantity > 0) {
      return urlUtils.addParam(url, 'productLimit', quantity, true);
    } else {
      return url;
    }
  }

  function addPageIdsParameter(url) {
    var pageIds = getPageIds();

    map(pageIds, function(v, k) {
      url = urlUtils.addParam(url, k, v, true);
    });

    return url;
  }

  function getBestSellers(quantity) {
    return getProductSearchRecommendations('/services/recommendations/best-sellers', quantity, 'best-sellers');
  }

  function getBrandBestSellers(quantity) {
    return getProductSearchRecommendations('/services/recommendations/brand-best-sellers', quantity, 'best-sellers');
  }

  function getNewArrivals(quantity) {
    return getProductSearchRecommendations('/services/recommendations/new-arrivals', quantity, 'new-arrivals');
  }

  function getPageIds() {
    return extend({}, getBrandId(), getCategoryId());
  }

  function getBrandId() {
    var brandId = BC && BC.brand && BC.brand.id;

    if (brandId) {
      return { brandId };
    } else {
      return {};
    }
  }

  function getCategoryId() {
    var categoryId = BC && BC.category && BC.category.id;

    if (categoryId) {
      return { categoryId };
    } else {
      return {};
    }
  }

  function getPageInfo(showMoreUrlSuffix) {
    var brand = BC && BC.brand;
    var category = BC && BC.category;
    var pageInfo;

    if (brand && category) {
      pageInfo = {
        pageName: brand.displayName,
        showAllUrl: brand.url + '?show=all',
        showMoreUrl: brand.url + '-' + showMoreUrlSuffix,
        url: brand.url,
      };
    } else if (brand) {
      pageInfo = {
        pageName: brand.displayName,
        showAllUrl: brand.url + '?show=all',
        showMoreUrl: brand.url + '-' + showMoreUrlSuffix,
        url: brand.url,
      };
    } else if (category) {
      pageInfo = {
        pageName: category.name,
        showAllUrl: category.url + '?show=all',
        showMoreUrl: category.url + '-' + showMoreUrlSuffix,
        url: category.url,
      };
    } else {
      pageInfo = {};
    }

    return pageInfo;
  }

  function getProductSearchRecommendations(url, quantity, showMoreUrlSuffix) {
    var response = ajaxHelper.doRequest(extend({ url: getProductSearchRequestUrl(url, quantity) }, config.recommendations), 'common/services/recommendations')
      .then(function(data) {
        if (data.searchResults && data.searchResults.products && data.searchResults.metadata) {
          return extend({ pageInfo: getPageInfo(showMoreUrlSuffix) }, data);
        } else {
          $.error('[common/services/recommendations] Error getting recommendations for ' + url);

          return new $.Deferred().reject({});
        }
      });

    return response;
  }

  function getRecommendations(type, quantity, strategy) {
    strategy = strategy || providerStrategy;

    if (type && strategy[type]) {
      return strategy[type](quantity);
    } else {
      return {};
    }
  }

  module.exports = {
    getRecommendations,
    RECOMMENDATION_TYPES,
  };
});
