define(function(require) {
  var localization = require('common/localization');

  $.l = function(scope, value) {
    switch (scope) {
      case 'currency':
        return $.l.toCurrency(value);
      case 'percentage':
        return $.l.toPercentage(value);
      default:
        return $.l.toNumber(value);
    }
  };

  $.l.toCurrency = localization.toCurrency;
  $.l.toNumber = localization.toNumber;
  $.l.toPercentage = localization.toPercentage;
});
