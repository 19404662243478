define(function(require, exports, module) {
  var instances = {};
  var options = {
    config: {
      global: 'grecaptcha',
      url: '//www.google.com/recaptcha/api.js?onload=onGrecaptchaLoaded&render=explicit',
    },
    recaptchaCallback: $.noop,
    siteKeys: {
      bcs: '6LeCkTwUAAAAAAJhuPL2BKSxKuUxeH3q-XWscTSh',
      competitivecyclist: '6Lc0oDwUAAAAAHCjokKVM3he-zHsbgYLWyDqTRL8',
      steepcheap: '6LcSoTwUAAAAAKaMIwbbPjgP_BKNtmlIFS_62Ksu',
    },
  };

  // Requires an element ID or DOM element where the recaptcha is going to be initiated
  function init(element, opts) {
    var elementId = element.id || element;

    instances[elementId] = {
      id: -1,
      promise: $.Deferred(),
    };

    _.extend(options, opts);
    handleLoadedRecaptcha();
    fetch();

    return instances[elementId].promise;
  }

  function execute(recaptchaId) {
    if (_.isNumber(recaptchaId)) {
      grecaptcha.execute(recaptchaId);
    }
  }

  function fetch() {
    var retry = 4;

    var downloadCaptcha = function(failCallback) {
      $.getScript(options.config.url)
        .fail(function(jqxhr, settings, exception) {
          failCallback(jqxhr, settings, exception);
        });
    };

    var failDownloadCallback = function(jqxhr, settings, exception) {
      if (retry > 0) {
        retry--;
        downloadCaptcha(failDownloadCallback);
      } else {
        $.error('Error downloading recaptcha js from: ' + options.config.url + '. Ready state: ' + jqxhr.readyState + ', status: ' + jqxhr.status + ', statusText: ' + jqxhr.statusText + ', settings: ' + settings, 'recaptcha', '', '', exception);
      }
    };

    downloadCaptcha(failDownloadCallback);
  }

  function getInstanceId(element) {
    var elementId = element.id || element;

    return instances[elementId] && instances[elementId].id;
  }

  function getResponse(recaptchaId) {
    if (_.isNumber(recaptchaId)) {
      try {
        return grecaptcha.getResponse(recaptchaId);
      } catch (err) {
        $.error('Error getting captcha response', err);

        return '';
      }
    }
  }

  function handleLoadedRecaptcha() {
    window.onGrecaptchaLoaded = function() {
      _.chain(instances).keys()
        .each(function(elementId) {
          instances[elementId].promise.resolve(render(elementId));
        });
    };
  }

  function render(element) {
    var recaptchaId = instances[element].id;

    if (recaptchaId < 0) {
      recaptchaId = grecaptcha.render(element, {
        sitekey: options.siteKeys[BC.site.code],
        callback: options.recaptchaCallback,
      });
      instances[element].id = recaptchaId;
    }

    return recaptchaId;
  }

  function reset(recaptchaId) {
    if (_.isNumber(recaptchaId)) {
      grecaptcha.reset(recaptchaId);
    }
  }

  module.exports = {
    execute,
    getInstanceId,
    getResponse,
    init,
    reset,
  };
});
