define(function(require, exports, module) {
  const React = require('react');
  const ReactDOM = require('react-dom');
  const { TrackingProvider } = require('common/components/tracking-context');

  function renderIntoElement(Component, element, props) {
    ReactDOM.render(
      <Component {...props} />,
      element,
    );
  }

  function renderWithTrackingContext(Component, element, props, tracking) {
    ReactDOM.render(
      <TrackingProvider value={tracking}>
        <Component {...props} />
      </TrackingProvider>,
      element,
    );
  }

  function unmountElementsAt($element, container) {
    $element.find(container).each(function() {
      ReactDOM.unmountComponentAtNode(this);
    });
  }

  module.exports = {
    renderIntoElement,
    renderWithTrackingContext,
    unmountElementsAt,
  };
});
