// bc.url.utils
// ===================
// Class to handle uri logic, like extracting querystring parameters

define(function(require, exports, module) {
  var {
    chain,
    has,
    each,
    reduce,
    isEmpty,
  } = require('underscore');
  var squareBracketsExp = /[\[\]]/g;
  var blankSpaceExp = /\+/g;

  module.exports = {
    /**
     * Adds a url parameter, optionally replacing the existing
     * param value.
     */
    addParam(url, key, value, shouldReplace) {
      var newUrl = '';

      url = url || '';

      if (shouldReplace && url.indexOf(key + '=') > -1) {
        newUrl = url.replace(new RegExp('([\\&\\?]' + key + '=).+?(&|$)'), '$1' + value + '$2');
      } else {
        newUrl = url + (url.indexOf('?') > -1 ? '&' : '?') + key + '=' + value;
      }

      return newUrl;
    },

    addSectionId(url, section) {
      return `${url}#${section}`;
    },

    /**
     * Gets the URI component from the URL (i.e. http://my.domain.com/my/path?with=some&params=yes would return my/path
     */
    getUri() {
      return location.pathname.substr(1);
    },

    /**
     * Checks that a url parameter has the current hostname.
     * @param href {string} A string of a url that wants to be compared.
     * @returns boolean True if hostname matches, false otherwise.
     */
    isCurrentHostname(href) {
      const [, , hostname] = href.split('/');

      return hostname && hostname.includes(BC.site.domain);
    },

    /**
     * Checks if both href parameters have the same path.
     * @param href1 {string} A url string that wants to be compared.
     * @param href2 {Element} A url string that wants to be compared.
     * @returns boolean True if path matches, false otherwise.
     */
    isSameUrl(...hrefs) {
      const [href1, href2] = hrefs.map(href => href.split('#')[0].split('?')[0]);

      return href1 === href2;
    },

    /**
     * Scroll page to top
     * Helper function to easily unit testing
     */

    scrollTo(xCoor, yCoor) {
      window.scrollTo(xCoor, yCoor);
    },

    /**
     * reloads page
     * helper function to easily allow unit testing
     */
    pageReload() {
      window.location.reload();
    },

    /**
     * gets the queryString from the window.location
     * helper function to easily allow unit testing
     * @returns {String}
     */
    getQueryString() {
      return window.location.search;
    },

    inUrl(singleParam) {
      var paramMap = chain(location.search.slice(1).split('&'))
        .map(function(currentParam) {
          var keyValArr = currentParam.split('=');

          return [
            keyValArr[0],
            {
              decodedValue: decodeURIComponent(keyValArr[1]).replace(blankSpaceExp, ' '),
              rawValue: keyValArr[1],
            },
          ];
        })
        .object()
        .value();

      if (arguments.length === 0) {
        return paramMap;
      }
      if (has(paramMap, singleParam)) {
        return paramMap[singleParam].decodedValue;
      }

      return false;
    },

    redirect(page) {
      window.location = page;
    },

    /**
     * takes an optional string representing the querystring (example: ?profileId=333&q=some)
     * and return an object that contains the parameters (example: {profileId: '333', q: 'some'})
     * @param queryString optional string, if it not provided the window.location.search will be used instead
     * @returns {{}} object that contains each parameter in the querystring
     */
    mapQueryStringToObject(queryString) {
      var queryStringFromUri = queryString || window.location.search;
      var queryParams = {};

      queryStringFromUri && each(queryStringFromUri.substring(1).split('&'), function(item) {
        var keyValue = item.split('=');

        queryParams[keyValue[0]] = keyValue[1];
      });

      return queryParams;
    },

    queryObjectToString(queryObject) {
      return reduce(queryObject, function(result, value, key) {
        return value ? (result += `&${key}=${value}`) : result;
      }, '');
    },

    /**
     * extracts a parameter value from a string representing an uri, for example, it extracts
     * the value of «v» from «https://www.youtube.com/watch?v=HxQjKz2_eS4»
     * @param name the name of the argument.
     * @param url a string that represents the url
     * @returns the value of the parameter in the querystring
     */
    getParameterByName(name, url = window.location.href) {
      name = name.replace(squareBracketsExp, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);

      if (!results) {
        return null;
      }
      if (!results[2]) {
        return null;
      }

      return decodeURIComponent(results[2].replace(blankSpaceExp, ' '));
    },

    addHash(type, value) {
      var HASH_SEPARATOR = '&';
      var otherHash = '';

      if (!isEmpty(window.location.hash)) {
        var hashList = window.location.hash.split(HASH_SEPARATOR);

        each(hashList, function(hash) {
          if (hash.indexOf(type) < 0) {
            otherHash += hash + HASH_SEPARATOR;
          }
        });
      }

      window.location.hash = otherHash + type + '=' + value;
    },

    hasHost(url) {
      const hostStartRegex = new RegExp('^(https?:)?\/{2}');

      return hostStartRegex.test(url);
    },
  };
});
