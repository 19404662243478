define(function(require) {
  require('common/main');
  require('common/widgets/authentication-modal/bc.authentication-modal');

  const { claimCoupon, initCouponBanner, startCouponListeners } = require('common/util/coupon-helper');
  const LivepersonEventHelper = require('common/liveperson-event-helper');
  const { default: il8nLoader } = require('common/util/bc.il8n-loader');
  const urlUtils = require('common/util/bc.url.utils');
  const communityService = require('common/services/community-service');
  const { default: getTopPost } = require('common/services/group-posts-service');
  const { runOnDOMIntersection } = require('common/util/intersection-observer-helper');
  const { default: replaceDynamicFlyoutContent } = require('common/util/header/header-flyout-helper');
  const { setCheckboxEventListener } = require('common/util/accessibility-helper');
  const { default: logout } = require('common/util/account/logout');
  const { initAuthenticationModal } = require('common/util/auth-modal-helper.js');
  const { getUserKey, getUserData, saveUserData } = require('common/util/storage-helper');
  const { globalEvents } = require('bcs/util/gtm-events');
  const {
    mountProactiveChatDesktopComponent,
  } = require('common/util/proactive-chat-helper');

  function init() {
    $.subscribe('header.domReady', function() {
      import(
        /* webpackChunkName: "bc.header" */
        'bcs/widgets/header/bc.header'
      ).then(function() {
        $('.page').header({
          sticky: BC.page.id !== 'community-explore-post' && BC.page.id !== 'gear-guide',
        });

        $.publish('header.loaded');
      });

      communityService
        .fetchConnectedGearhead()
        .then((data) => {
        // TODO jcordero remove when legacy code for follow is gone
          BC.gearheadConnection = data;
          // TODO jcordero remove when legacy code for follow is gone
          $.publish('gearhead-connect-ready', data);
          $.publish('gearhead-connection.fetched', data);
        });

      // coupon banner promotion
      !_.contains([
        'home-lite',
        'checkout',
        'receipt',
      ], BC.page.id) && initCouponBanner();

      _initAccountSummary();
      initAuthenticationModal();
      _initPerksSignUp();
      _initCustomerRequestServiceModal();

      _initGlobalBanner();
    });

    if (has('loyalty')) { // loyalty FS
      _initLoyaltySignupModal();
    }

    if (has('ada_main_nav')) {
      BC.subscribe('main-nav.domReady', (mainNavData) => {
        import(
          /* webpackChunkName: "bcs.main.nav.renderer" */
          'common/renderers/main-nav.js'
        ).then(({ default: initMainNav }) => {
          initMainNav(mainNavData);
        });
      });
    }

    window.addEventListener('load', () => {
      // ToDo @jdleiva remove when legacy main nav is deleted - US78390
      $('.js-flyout[data-posts]').each((index, element) => {
        const $element = $(element);

        if (!has('ada_main_nav')) {
          getTopPost(3)
            .then(posts => replaceDynamicFlyoutContent($element, posts));
        }
      });
      // Handle enter event for checkboxes with the same js class
      setCheckboxEventListener('.js-enter-checkbox');

      if (has('gtm')) {
        globalEvents();
      }

      mountProactiveChatDesktopComponent();
    });

    if (has('fb_login')) {
      $.subscribe('show.loginModal', function() {
        import(
          /* webpackChunkName: "fb-login-helper" */
          'common/util/fb-login-helper'
        ).then(({ initFbLogin }) => {
          initFbLogin();
        });
      });
    }

    if (has('apple_login')) {
      BC.subscribe('show.loginModal', () => (
        import(
          /* webpackChunkName: "apple-login-helper" */
          'common/util/apple-login-helper'
        ).then(({ default: initAppleSignIn }) => {
          initAppleSignIn();
        })
      ));
    }

    initContactUsModal();

    // Check if third-party login expired
    const loginProvider = localStorage.getItem('login_provider');

    if (loginProvider && !$.cookie('BC_USR')) {
      localStorage.removeItem('login_provider');
    }

    // Track PWA events
    if (has('pwaprompt')) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.userChoice.then((result) => {
          BC.publish('PwaPrompted.sitecatalystutil', result.outcome);
        });
      });

      window.addEventListener('appinstalled', () => {
        BC.publish('PwaInstalled.sitecatalystutil');
      });

      if (/pwa/.test(window.location.search)) {
        BC.publish('PwaOpenedHomescreen.sitecatalystutil');
      }
    }

    // Change logo anchors for pwa and native app
    if (has('is-native-app')) {
      $('.header-logo__link').attr('href', '/my-backcountry');
    }

    function handleTabOnce(event) {
      if ((event.key && event.key === 'Tab')) {
        document.documentElement.classList.add('is-tabbing');

        window.removeEventListener('keydown', handleTabOnce);
        window.addEventListener('mousedown', handleMouseDownOnce);
      }
    }

    function handleMouseDownOnce() {
      document.documentElement.classList.remove('is-tabbing');

      window.removeEventListener('mousedown', handleMouseDownOnce);
      window.addEventListener('keydown', handleTabOnce);
    }

    window.addEventListener('keydown', handleTabOnce);

    LivepersonEventHelper.bind({
      onOfferDisplayed() {
        // TODO adrodriguez remove ths publish once DTM gets cleaned
        BC.publish('ScLiveChatOffered.sitecatalystutil');
        BC.publish('gtm.track.live.chat.impression');
      },
      onOfferDeclined() {
        // TODO adrodriguez remove ths publish once DTM gets cleaned
        BC.publish('ScLiveChatDeclined.sitecatalystutil');
        BC.publish('gtm.track.live.chat.dismissed');
      },
    });

    $(document).ready(function() {
      // Customize ATG forms so they can be submit with "submit()" instead of "click()"
      $.each($('form.atg-form'), function() {
        var $atgForm = $(this);
        var $submitButton = $atgForm.find('input:submit').first();
        var $hiddenInput = $('<input>').attr('type', 'hidden')
          .attr('name', $submitButton.attr('name'))
          .attr('value', $submitButton.attr('value'));

        $submitButton.remove();
        $atgForm.append($hiddenInput);
      });

      communityService.fetchGearheads().then((gearheads) => {
        BC.publish('fetch-gearheads-ready', gearheads);
      });

      $('.js-nav-back-btn').on('click', () => window.history.back());

      runOnDOMIntersection({
        element: document.querySelector('.footer-suscription'),
        proximity: 0,
      }).then(() => import(
        /* webpackChunkName: "bcs.footer.suscription" */
        'common/widgets/bc.emailsubscription'
      )).then(() => {
        $('#email-footer-form').emailsubscription();
      }).catch(() => {
      });

      const allBrandsSelect = document.querySelector('.all-brands-select, #bcc-footer-selectmenu');

      runOnDOMIntersection({
        element: allBrandsSelect,
        proximity: 0,
      }).then(() => import(
        /* webpackChunkName: "bcs.footer.linkselect" */
        'common/widgets/bc.linkselect'
      )).then(() => {
        // Common footer dropdown for BCC controlled links
        $(allBrandsSelect).linkselect();
      }).catch(() => {
      });

      const popularSelectMenu = document.querySelector('.js-popular-links, .popup');

      runOnDOMIntersection({
        element: popularSelectMenu,
        proximity: 0,
      }).then(() => import(
        /* webpackChunkName: "bcs.footer.selectmenu" */
        'common/widgets/bc.selectmenu'
      )).then(() => {
        $('.js-popular-links, .popup').selectmenu({ // there could be multiple instances, we need to capture all
          positionOptions: {
            at: 'left bottom',
            my: 'left top',
            offset: '0 -26px',
          },
        });
      }).catch(() => {
      });

      import(
        /* webpackChunkName: "bcs.search.suggestions.renderer" */
        'common/renderers/search-suggestions.js'
      ).then(({ default: initSearchSuggestions }) => initSearchSuggestions());

      $('.js-service-request-modal').click(() => {
        BC.publish('requestService:open');
      });
    });

    $.cookie('BC_USR', '', {
      path: '/',
      domain: '.backcountry.com',
      expires: 0,
    });

    BC.subscribe('receipt.pageReady', function() {
      // Using this event due to timing issues when making a cleanup vs pushing this fix, need to use a generic one on the future
      BC.publish('cart.pageReady');
    });

    BC.subscribe('submit.logout', (opts) => {
      logout(opts);
    });

    if (urlUtils.inUrl('offline_imp')) {
      BC.publish('OfflinePageImpression.sitecatalystutil');
      BC.publish('OfflineBackOnline.sitecatalystutil');

      try {
        const newUrl = new URL(window.location.href);

        newUrl.search = newUrl.search.replace('?offline_imp=true', '?').replace('&offline_imp=true', '');
        history.replaceState(null, null, newUrl.href);
      } catch (error) {
        $.error('[offline] Issue cleaning up url parameter: ', error);
      }
    }

    var dfClosureParam = urlUtils.inUrl('dfc');

    if (dfClosureParam && dfClosureParam === 'true') {
      if (typeof require.ensure !== 'function') {
        require('common/util/ensure-shim')(require);
      }
      require.ensure(['common/widgets/closuremodal/bc.closuremodal'], function(require) {
        require('common/widgets/closuremodal/bc.closuremodal');
        $('body').closuremodal({
          dialogClass: 'ui-dfc',
          contentId: 'df-closure-modal-content',
          suscriptionSourceType: 'df_closure',
        });
      }, 'closuremodal');
    }

    const couponCode = urlUtils.inUrl('COUP');

    if (has('ajaxcouponredemption') && couponCode) {
      claimCoupon(couponCode);
    }

    initContingencyModal();
    startCouponListeners();
  }

  function initContactUsModal() {
    $(() => {
      $('.js-contact-us').on('click', () => {
        import(
          /* webpackChunkName: "bcs.contact-form" */
          'common/renderers/contact-form'
        ).then(({ default: renderContactUs }) => {
          renderContactUs($('#contact-us-footer').get(0), {
            autoOpenForm: true,
            label: $.t('label.contact.us'),
          });
        });
      });
    });
  }

  function initContingencyModal() {
    if (has('contingencymodal')) {
      BC.subscribe('remoteDialog.init', () => {
        const userData = getUserData(getUserKey());

        if (!userData.contingencyInfo) {
          saveUserData('contingencyInfo', getUserKey(), true);
          $('.js-contingency-link').click();
        }
      });
    }
  }

  function _initPerksSignUp() {
    $('body').ready(function() {
      import(
        /* webpackChunkName: "reactive.loyalty-perks-sign-up" */
        'common/renderers/loyalty-perks-sign-up'
      ).then(({ default: renderPerksSignUp }) => {
        const props = {};

        const container = document.createElement('div');

        document.body.appendChild(container);

        renderPerksSignUp(container, props);
      });
    });
  }

  function _initAccountSummary() {
    $('body').ready(function() {
      import(
        /* webpackChunkName: "reactive.account-summary" */
        'common/renderers/account-summary-modal'
      ).then(({ default: renderAccountSummaryModal }) => {
        const props = {
          openEventName: 'bc.account-summary-modal.open',
          profile: BC.profile,
          handleSignOut: () => {
            logout();
          },
        };

        const container = document.createElement('div');

        document.body.appendChild(container);

        renderAccountSummaryModal(container, props);
      });
    });
  }

  function _initCustomerRequestServiceModal() {
    $('body').ready(function() {
      import(
        /* webpackChunkName: "reactive.customer-service-request-modal" */
        'common/renderers/customer-service-request-modal'
      ).then(({ default: renderCustomerServiceRequestModal }) => {
        const container = document.createElement('div');

        document.body.appendChild(container);

        renderCustomerServiceRequestModal(container);
      });
    });
  }

  function _initLoyaltySignupModal() {
    $('body').ready(function() {
      import(
        /* webpackChunkName: "reactive.loyalty.signup.modal" */
        'common/renderers/loyalty-signup-modal'
      ).then(({ default: renderLoyaltySignupModal }) => {
        const container = document.createElement('div');

        document.body.appendChild(container);

        renderLoyaltySignupModal(container);
      });
    });
  }

  function _initGlobalBanner() {
    BC.subscribe('globalText.ready', function(items) {
      import(
        /* webpackChunkName: "global.banner" */
        'bcs/widgets/global-banner/index'
      ).then(({ mountGlobalBannerComponent }) => {
        mountGlobalBannerComponent(items);
      });
    });
  }

  return il8nLoader().then(init);
});
