define(function(require, exports, module) {
  var PDP_PAGE_NAME = 'pdp';

  function isPdpPage() {
    return getPageType() === PDP_PAGE_NAME;
  }

  function getParentCategories() {
    const product = BC.product || {};

    return product.parentCategories || [];
  }

  function getCategory() {
    return BC.category || {};
  }

  function getPageType() {
    return BC.page && BC.page.type || '';
  }

  function getPageName() {
    switch (getPageType()) {
      case 'brand-home':
        return BC.brand && BC.brand.displayName;
      case 'activity':
      case 'category':
        return BC.category && BC.category.name;
      case 'pdp':
        return BC.product && BC.product.displayName;
      case 'static-content':
        return BC.staticContent && BC.staticContent.displayName;
      case 'dynamic-content':
      case 'destination':
      case 'trip':
        return BC.dynamicContent && BC.dynamicContent.displayName;
      default:
        return BC.page.id || '';
    }
  }

  module.exports = {
    getCategory,
    getPageName,
    getPageType,
    getParentCategories,
    isPdpPage,
  };
});
