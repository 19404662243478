// TODO migrate everything that write to has (at least from main) into this module
define(function(require) {
  var {
    isArray,
    isUndefined,
    chain,
    each,
  } = require('underscore');

  function getFeatures(featureList) {
    featureList = isArray(featureList) ? featureList : Array.prototype.slice.call(arguments);

    return chain(featureList)
      .map(function(feature) {
        var featureExist = has(feature);
        var featureValue = isUndefined(featureExist) ? has(feature.toLowerCase()) : featureExist;

        return [feature, !!featureValue];
      })
      .object()
      .value();
  }

  function initializeFeatures($element) {
    var ua = navigator.userAgent;
    var ipad = /iPad/i.test(ua);
    var iphone = /iPhone/i.test(ua);
    var ipod = /iPod/i.test(ua);
    var ios = iphone || ipad || ipod;
    var android = /Android/i.test(ua);
    var mobileSafari = ios && !/CriOS/i.test(ua);

    has.add('isNativeApp', !!BC.isNativeApp);
    has.add('iphone', iphone);
    has.add('ipad', ipad);
    has.add('ios', ios);
    has.add('android', android);
    has.add('mobileSafari', mobileSafari);

    // Expose enabled features via `has`.
    var disabledRegExp = /^no-/;

    each($element.prop('className').split(/\s+/), function(feature) {
      has.add(feature.replace(disabledRegExp, ''), !disabledRegExp.test(feature));
    });
  }

  function getPLIFeatures() {
    return getFeatures(
      'backorder',
      'mobile',
      'giftwithpurchase',
      'storecredits',
      'touch',
      'medium',
      'x-large',
    );
  }

  function getCartFeatures() {
    return getFeatures(
      'bikeBuildMsg',
      'cartCheckoutV3',
      'cartContinueShoppingButton',
      'cartItemSavings',
      'cartPagePromoEntryBottom',
      'erp_shippingMethodsMessaging',
      'hideAllLiveperson',
      'hideChatLink',
      'hidePhoneLink',
      'jacadaLiveChat',
      'persist_chat',
      'livePersonSolicit',
      'holidayDeliveryPromise',
      'dynamicShipping',
      'isNativeApp',
      'mobile',
      'packageCart',
      'quantityDiscount',
      'sacRRCart',
      'showWishlist',
      'storeCredits',
      'estimateDeliveryDate',
    );
  }

  return {
    getFeatures,
    getPLIFeatures,
    getCartFeatures,
    initializeFeatures,
  };
});
