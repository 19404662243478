import React from 'react';
import { getPageType } from 'common/bc.core';
import {
  buildFullUrl,
  buildPromotionUrl,
  buildTiUrl,
  trackEvent,
  trackImpression,
  getImpressionCodes,
} from 'common/util/component-tracking-helper';

const NOT_AVAILABLE = 'NA';

const TrackingContext = React.createContext({
  buildFullUrl,
  buildPromotionUrl,
  buildTiUrl,
  trackEvent,
  trackImpression,
  getImpressionCodes,
  campaignId: NOT_AVAILABLE,
  componentName: NOT_AVAILABLE,
  linkNameComponent: NOT_AVAILABLE,
  pageName: NOT_AVAILABLE,
  pageSection: '',
  pageType: getPageType(),
  tabId: NOT_AVAILABLE,
  tabName: NOT_AVAILABLE,
});

const TrackingConsumer = TrackingContext.Consumer;

function removeUndefined(obj) {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
}

function TrackingProvider({ value, children }) {
  removeUndefined(value);

  return (
    <TrackingConsumer>
      {tracking => (
        <TrackingContext.Provider value={{ ...tracking, ...value }}>
          {children}
        </TrackingContext.Provider>
      )}
    </TrackingConsumer>
  );
}

export {
  TrackingProvider,
  TrackingConsumer,
};

export default TrackingContext;
