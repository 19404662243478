/**
 * This module detects the support for CSS object-fit
 * and downloads on-demand an auto-run polifyll
 *
 * TODO jcordero remove this code when IE (as usual)
 * dies or decides to support object-fit
 * */

function fixObjectFit(selector) {
  // Detecting support for CSS object-fit
  if (!('object-fit' in new Image().style)) {
    return import(
      /* webpackChunkName: "object-fit-images" */
      'object-fit-images'
    ).then(({ default: objectFitImages }) => {
      objectFitImages && objectFitImages(selector, { watchMQ: true });
    });
  }
}

export default fixObjectFit;
